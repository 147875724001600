var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-list-template" },
    [
      _c("breadcrumbs", {
        staticClass: "mb-0",
        attrs: { items: _vm.breadcrumbs }
      }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "pa-0", staticStyle: { position: "relative" } },
        [
          _vm.isLoading
            ? _c(
                "v-overlay",
                {
                  staticClass: "overlay-group-tab",
                  attrs: { absolute: "", value: _vm.isLoading, color: "white" }
                },
                [
                  _c("v-progress-circular", {
                    staticClass: "user-loading-progress",
                    attrs: { indeterminate: "", color: "green" }
                  })
                ],
                1
              )
            : _c(
                "v-overlay",
                {
                  staticClass: "overlay-group-tab",
                  attrs: {
                    absolute: "",
                    value: _vm.dataGroup.loading,
                    color: "white"
                  }
                },
                [
                  _c("v-progress-circular", {
                    staticClass: "user-loading-progress",
                    attrs: { indeterminate: "", color: "green" }
                  })
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-group" },
            [
              _c("v-container", [
                _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "text-subtitle-2 border-left-blue font-weight-bold"
                          },
                          [
                            _vm._v(
                              "\n            通行権限グループ情報\n          "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-card", { staticClass: "pa-6 pr-10 " }, [
                      _c("div", { staticClass: "info-item font-default" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col col-6" }, [
                            _c("div", { staticClass: "title-display-block" }, [
                              _vm._v("通行権限グループ名 ")
                            ]),
                            _vm._v(" "),
                            _vm.groupList.length > 0
                              ? _c("div", { staticClass: "weight-bold" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.groupList[0].name) + " "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col col-6" }, [
                            _c("div", { staticClass: "title-display-block" }, [
                              _vm._v("ステータス ")
                            ]),
                            _vm._v(" "),
                            _vm.groupList.length > 0
                              ? _c("div", { staticClass: "weight-bold" }, [
                                  _vm._v(
                                    "  " +
                                      _vm._s(
                                        _vm.groupList[0].isValid
                                          ? "有効"
                                          : "無効"
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-container", [
                _c(
                  "div",
                  { staticClass: "round-tab round-group-tab" },
                  [
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { transition: false },
                          on: {
                            change: function($event) {
                              return _vm.clickTab(_vm.tab, _vm.groupItem)
                            }
                          },
                          model: {
                            value: _vm.tab,
                            callback: function($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab"
                          }
                        },
                        _vm._l(_vm.actionItems, function(item) {
                          return _c(
                            "v-tab",
                            { key: item.tabname, staticClass: "log-tab" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.title) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    _vm._v(" "),
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.tab,
                          callback: function($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab"
                        }
                      },
                      _vm._l(_vm.actionItems, function(item) {
                        return _c(
                          "v-tab-item",
                          {
                            key: item.tabname,
                            attrs: {
                              transition: false,
                              "reverse-transition": false
                            }
                          },
                          [
                            item.tabname === "tab1"
                              ? _c(
                                  "v-card",
                                  [
                                    _vm.groupItem === ""
                                      ? _c(
                                          "v-container",
                                          { staticClass: "pa-0" },
                                          [
                                            _c("gate-list-table", {
                                              attrs: {
                                                gateExpanedUser: new Map(),
                                                gateExpanedGroup: new Map(),
                                                errorMessageImport: [],
                                                authority: _vm.authority,
                                                gateTotalCount: 0,
                                                dataGates: [],
                                                page: 1,
                                                perPage: 25,
                                                isDisable: true
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.groupItem !== ""
                                      ? _c("gate-list-page", {
                                          attrs: {
                                            isAdminOfRemoteUnlock:
                                              _vm.dataGroup
                                                .isAdminOfRemoteUnlock,
                                            "tab:": _vm.tab,
                                            groupId: _vm.groupItem
                                          },
                                          on: {
                                            "close-modal-control": function(
                                              $event
                                            ) {
                                              return _vm.loadGroupId(
                                                _vm.groupItem
                                              )
                                            },
                                            "loading-from-child": function(
                                              $event
                                            ) {
                                              return _vm.$emit(
                                                "loading-from-child",
                                                $event
                                              )
                                            },
                                            loading: function($event) {
                                              return _vm.loadingFromChild(
                                                $event
                                              )
                                            },
                                            clickGroupDetail: function($event) {
                                              return _vm.clickGroupDetail(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.tabname === "tab2"
                              ? _c(
                                  "v-card",
                                  [
                                    _c("AffiliationAllowedTable", {
                                      attrs: {
                                        isPage: "group",
                                        isAdminOfGroupPassages:
                                          _vm.dataGroup.isAdminOfGroupPassages,
                                        authority: _vm.authority,
                                        optionDepartments:
                                          _vm.optionDepartments,
                                        dataList:
                                          _vm.dataGroup.department !== undefined
                                            ? _vm.dataGroup.department.data
                                            : [],
                                        loading:
                                          _vm.dataGroup.department.loading,
                                        loadingAff: _vm.loadingAff,
                                        departments: _vm.departments,
                                        dataRelationDepartmentGroup:
                                          _vm.dataRelationDepartmentGroup
                                      },
                                      on: {
                                        "load-list-aff": function($event) {
                                          return _vm.$emit("load-list-aff", 1)
                                        },
                                        "close-modal": function($event) {
                                          _vm.$emit("load-list-aff", 1),
                                            _vm.$emit(
                                              "getDepartmentGroup",
                                              _vm.groupItem
                                            )
                                        },
                                        "load-aff-again": function($event) {
                                          return _vm.$emit(
                                            "getDepartmentGroup",
                                            _vm.groupItem
                                          )
                                        },
                                        "change-page-aff": function($event) {
                                          return _vm.$emit(
                                            "change-page-aff",
                                            $event
                                          )
                                        },
                                        "add-aff-to-group": function($event) {
                                          return _vm.$emit("add-aff-to-group", {
                                            groupId: _vm.groupItem,
                                            aff: $event
                                          })
                                        },
                                        "remove-aff-to-group": function(
                                          $event
                                        ) {
                                          return _vm.$emit(
                                            "remove-aff-to-group",
                                            {
                                              groupId: _vm.groupItem,
                                              aff: $event
                                            }
                                          )
                                        },
                                        "loading-from-child": function($event) {
                                          return _vm.$emit(
                                            "loading-from-child",
                                            $event
                                          )
                                        },
                                        "back-group-detail": function($event) {
                                          return _vm.clickGroupDetail($event)
                                        },
                                        "close-modal-add": function($event) {
                                          return _vm.$emit(
                                            "close-modal-add-aff",
                                            _vm.groupItem
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("UsersAllowedTable", {
                                      attrs: {
                                        isAdminOfGroupPassages:
                                          _vm.dataGroup.isAdminOfGroupPassages,
                                        groupId: _vm.groupItem,
                                        authority: _vm.authority,
                                        dataList:
                                          _vm.dataGroup.groupPassages !==
                                          undefined
                                            ? _vm.dataGroup.groupPassages.data
                                            : [],
                                        dataGroupPassages:
                                          _vm.dataGroup.groupPassages !==
                                          undefined
                                            ? _vm.dataGroup.groupPassages
                                            : [],
                                        loading:
                                          _vm.dataGroup.groupPassages !==
                                          undefined
                                            ? _vm.dataGroup.groupPassages
                                                .loading
                                            : false,
                                        totalCount:
                                          _vm.dataGroup.groupPassages.total !==
                                          undefined
                                            ? _vm.dataGroup.groupPassages.total
                                            : 0,
                                        perPage:
                                          _vm.dataGroup.groupPassages
                                            .perPage !== undefined
                                            ? _vm.dataGroup.groupPassages
                                                .perPage
                                            : 25,
                                        page:
                                          _vm.dataGroup.groupPassages.page !==
                                          undefined
                                            ? _vm.dataGroup.groupPassages.page
                                            : 1,
                                        isPage: "group",
                                        dataRelationUser:
                                          _vm.dataRelationUserGroup
                                      },
                                      on: {
                                        "load-users": function($event) {
                                          return _vm.$emit(
                                            "load-users",
                                            _vm.groupItem
                                          )
                                        },
                                        "remove-user-to-group": function(
                                          $event
                                        ) {
                                          return _vm.$emit(
                                            "remove-user-to-group",
                                            {
                                              groupId: _vm.groupItem,
                                              users: $event
                                            }
                                          )
                                        },
                                        "close-modal-add": function($event) {
                                          return _vm.closeModalAdd($event)
                                        },
                                        "change-page": function($event) {
                                          return _vm.$emit(
                                            "change-page-users",
                                            {
                                              groupId: _vm.groupItem,
                                              $event: $event
                                            }
                                          )
                                        },
                                        "search-user": function($event) {
                                          return _vm.$emit(
                                            "search-user-group-passage",
                                            {
                                              groupId: _vm.groupItem,
                                              data: $event
                                            }
                                          )
                                        },
                                        getDepartmentUserValid: function(
                                          $event
                                        ) {
                                          return _vm.$emit(
                                            "getDepartmentUserValid",
                                            {
                                              groupId: _vm.groupItem,
                                              $event: $event
                                            }
                                          )
                                        },
                                        "loading-from-child": function($event) {
                                          return _vm.$emit(
                                            "loading-from-child",
                                            $event
                                          )
                                        },
                                        "load-group": function($event) {
                                          return _vm.loadGroupId($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.tabname === "tab3"
                              ? _c(
                                  "v-card",
                                  [
                                    _c("TimePeriodAllowed", {
                                      attrs: {
                                        authority: _vm.authority,
                                        dataList:
                                          _vm.dataGroup.passableTimeSetting !==
                                          undefined
                                            ? _vm.dataGroup.passableTimeSetting
                                            : [],
                                        passableName:
                                          _vm.dataGroup.passableName !==
                                          undefined
                                            ? _vm.dataGroup.passableName
                                            : "",
                                        loading: _vm.dataGroup.loading,
                                        groupId: _vm.groupItem
                                      },
                                      on: {
                                        loadingCircle: function($event) {
                                          return _vm.$emit(
                                            "loadingCircle",
                                            $event
                                          )
                                        },
                                        textCircle: function($event) {
                                          return _vm.$emit("textCircle", $event)
                                        },
                                        "click-EditGate": function($event) {
                                          return _vm.$emit(
                                            "click-EditGate",
                                            $event
                                          )
                                        },
                                        "close-modal": function($event) {
                                          return _vm.$emit(
                                            "load-passable-time-setting",
                                            _vm.groupItem
                                          )
                                        },
                                        "loading-from-child": function($event) {
                                          return _vm.$emit(
                                            "loading-from-child",
                                            $event
                                          )
                                        },
                                        "go-time-settings": _vm.goTimeSettings
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.tabname === "tab4"
                              ? _c(
                                  "v-card",
                                  [
                                    _vm.tab === 3
                                      ? _c("TemporaryAccessAuthority", {
                                          attrs: {
                                            authority: _vm.authority,
                                            isAdminOfGatePassages:
                                              _vm.dataGroup
                                                .isAdminOfGatePassages,
                                            gateList: _vm.gateList,
                                            userList: _vm.userList,
                                            errorMessageLogs:
                                              _vm.errorMessageLogs,
                                            dataRealtionsGateList:
                                              _vm.dataGroup.realtionsGateList,
                                            dataGatePass: _vm.dataGatePass,
                                            loading:
                                              _vm.dataGroup
                                                .realtionsGateList !== undefined
                                                ? _vm.dataGroup
                                                    .realtionsGateList.loading
                                                : false
                                          },
                                          on: {
                                            "add-gate": function($event) {
                                              return _vm.$emit(
                                                "add-gate",
                                                $event
                                              )
                                            },
                                            "load-gates": function($event) {
                                              return _vm.$emit("load-gates", 1)
                                            },
                                            "remove-gate-pass": function(
                                              $event
                                            ) {
                                              return _vm.$emit(
                                                "remove-gate-pass",
                                                $event
                                              )
                                            },
                                            "change-page": function($event) {
                                              return _vm.$emit(
                                                "change-page-temporary",
                                                $event
                                              )
                                            },
                                            "remove-gate-pass-item": function(
                                              $event
                                            ) {
                                              return _vm.$emit(
                                                "remove-gate-pass",
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "全ての通行権限グループ一覧",
          "no-footer": "",
          smallScreen: "800px",
          className: "common-padding"
        },
        on: {
          close: function($event) {
            return _vm.closePageModal(_vm.isLoadData)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm.isShow
                  ? _c("GroupListModalPage", {
                      on: {
                        loadingCircle: function($event) {
                          return _vm.$emit("loadingCircle", $event)
                        },
                        textCircle: function($event) {
                          return _vm.$emit("textCircle", $event)
                        },
                        isLoad: function($event) {
                          return _vm.getIsLoad($event)
                        },
                        "go-time-settings": _vm.goTimeSettings,
                        "click-group-detail": function($event) {
                          return _vm.clickGroupDetail($event)
                        },
                        "close-page-modal": function($event) {
                          return _vm.closePageModal($event)
                        },
                        loading: function($event) {
                          return _vm.$emit("loading-from-child", $event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isShow,
          callback: function($$v) {
            _vm.isShow = $$v
          },
          expression: "isShow"
        }
      }),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "全てのゲート一覧",
          "no-footer": "",
          smallScreen: "800px",
          className: "common-padding"
        },
        on: {
          close: function($event) {
            _vm.isShowGate = false
            _vm.closePageModal(_vm.isLoading)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm.isShowGate
                  ? _c("gate-list-page", {
                      attrs: { inPage: "modal-group", groupId: "no-id" },
                      on: {
                        "close-modal-page-from-add": function($event) {
                          _vm.isShowGate = false
                          _vm.closePageModal(true)
                        },
                        "close-modal-control": function($event) {
                          return _vm.getIsLoad($event)
                        },
                        loading: function($event) {
                          return _vm.$emit("loading-from-child", $event)
                        },
                        clickGroupDetail: function($event) {
                          return _vm.clickGroupDetail($event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isShowGate,
          callback: function($$v) {
            _vm.isShowGate = $$v
          },
          expression: "isShowGate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }