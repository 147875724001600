



























































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import UserListTable from '@/components/organisms/UsersAllowedTable/UsersListTable.vue';
import { DataTableHeader } from 'vuetify';
import { CardDetail } from '@/types/alligate';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import UserAddPage from '@/components/pages/user/UserAddPage.vue';
import UserDetailPage from '@/components/pages/user/UserDetailPage.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import ModalPageUser from '@/components/pages/common-modal/ModalPage.vue';
import moment from 'moment';
import Modal from '@/components/molecules/Modal/Modal.vue';


export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  idSelected: any;
  showOption: boolean;
  isVaildOnly: boolean;
  isShow: boolean;
  isShowUserDetail: boolean;
  isShowUser: boolean;
  showProgress: boolean;
  type: string;
  userId: string;
  searchTargetItems: SearchTargetItem[];
  // checkedColumnNames: any;
  isConfirm: boolean;
  isShowValidUser: boolean;
  isReloadData: boolean;
}

export default Vue.extend({
  name: 'UsersAllowedTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
    UserAddPage,
    UserDetailPage,
    UserListTable,
    ModalPageUser,
    Modal,
  },
  watch: {
    idSelected(val: any) {
    if (this.isPage === 'affiliation-add') {
      this.$emit('select-Ids', val.map((item: any) => item));
    } else {
      this.$emit('select-Ids', val.map((item: any) => item.userId));
    }
    },
    isFinishProcessEnable(value) {
      if (value) {
        this.idSelected = [];
      }
    },
    loading(val: any) {
      if (val === false && this.isShowValidUser === false) {
        this.isShowValidUser = localStorage.isShowValidUser === 'true' ? true : false;
      }
      if (val === false && this.isPage === 'affiliation-add') {
        this.selectedDisplayOption = [
          'userId',
          'userName',
          'company',
        ];
      }
    },
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    authority: {
      type: Object,
      default: ({}),
    },
    isPage: {
      type: String,
      default: '',
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataList: {
      default: () => [],
    },
    dataGroupPassages: {
      default: () => [],
    },
    users: {
      type: Object,
      default: () => ({}),
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    dataRelationUser: {
      type: Object,
      default: () => ({}),
    },
    itemDepartment: {
      type: Object,
      default: () => ({}),
    },
    groupId: {
      type: String,
      default: '',
    },
    departmentId: {
      type: String,
      default: '',
    },
    inputSearch: {
      type: String,
      default: '',
    },
    isActionPage: {
      type: String,
      default: '',
    },
    isAdminToAddUser: {
      type: Boolean,
      default: false,
    },
    isAdminOfGroupPassages: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const ret: DataTableHeader[] = [];
        ret.push({
          text: 'ユーザー名',
          align: 'start',
          sortable: true,
          value: 'userName',
          width: '33%',
        });
        if (this.selectedDisplayOption.includes('userId')) {
          ret.push({
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'userId',
          });
        }
        if (this.selectedDisplayOption.includes('company')) {
          ret.push({
            text: '所属名',
            align: 'start',
            sortable: true,
            value: 'company',
          });
        }
        if (this.selectedDisplayOption.includes('email')) {
          ret.push({
            text: 'メールアドレス',
            align: 'start',
            sortable: true,
            value: 'user.email',
          });
        }
        if (this.selectedDisplayOption.includes('furigana')) {
          ret.push({
            text: 'ユーザー名ふりがな',
            align: 'start',
            sortable: true,
            value: 'user.furigana',
          });
        }
        if (this.selectedDisplayOption.includes('isAdmin')) {
          ret.push({
            text: '組織管理権限',
            align: 'start',
            sortable: true,
            value: 'user.isAdmin',
          });
        }
        if (this.selectedDisplayOption.includes('isPhoneAdmin')) {
          ret.push({
            text: 'ゲート設定権限',
            align: 'start',
            sortable: true,
            value: 'user.isPhoneAdmin',
          });
        }
        if (this.selectedDisplayOption.includes('shareKeyBeValid')) {
          ret.push({
            text: '鍵共有権限',
            align: 'start',
            sortable: true,
            value: 'user.shareKeyBeValid',
          });
        }
        if (this.selectedDisplayOption.includes('isValid')) {
          ret.push({
            text: 'ステータス',
            align: 'start',
            sortable: true,
            value: 'user.isValid',
          });
        }
        if (this.selectedDisplayOption.includes('option1')) {
          ret.push({
            text: '備考1',
            align: 'start',
            sortable: true,
            value: 'user.option1',
          });
        }
        if (this.selectedDisplayOption.includes('option2')) {
          ret.push({
            text: '備考2',
            align: 'start',
            sortable: true,
            value: 'user.option2',
          });
        }
        if (this.selectedDisplayOption.includes('registered')) {
          ret.push({
            text: '登録日',
            align: 'start',
            sortable: true,
            value: 'user.registered',
          });
        }
        if (this.selectedDisplayOption.includes('updated')) {
          ret.push({
            text: '更新日',
            align: 'start',
            sortable: true,
            value: 'user.updated',
          });
        }
        if (this.selectedDisplayOption.includes('isMfaSms')) {
          ret.push({
            text: 'SMS認証',
            align: 'start',
            sortable: true,
            value: 'user.isMfaSms',
          });
        }
        if (this.selectedDisplayOption.includes('passCode')) {
          ret.push({
            text: '二段階認証パスワード',
            align: 'start',
            sortable: true,
            value: 'user.passCode',
          });
        }
        return ret;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', {page: val, perPage: this.perPage});
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', {perPage: val, page: 1});
        return;
      },
    },
    showUserValid: {
      get(): boolean {
        return this.isShowValidUser;
      },
      set(val: boolean): void {
        if (this.isPage !== 'affiliation-add-new') {
          this.$emit('getDepartmentUserValid', {departmentId: this.departmentId, value: val});
        }
        return;
      },
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: 'ユーザーID', value: 'userId' },
      { label: 'ユーザー名', value: 'name' },
    ],
    type: 'add',
    isShowUser: false,
    isShow: false,
    isShowUserDetail: false,
    selectedDisplayOption: [
      'userId',
      'userName',
    ],
    displayOptionItems: [
      { label: 'ユーザー名', value: 'userName', isDisabled: true},
      { label: 'ユーザーID', value: 'userId', isDisabled: true },
      // { label: 'メールアドレス', value: 'email' },
      // { label: 'ユーザー名ふりがな', value: 'furigana' },
      // { label: '組織管理権限', value: 'isAdmin' },
      // { label: 'ゲート設定権限', value: 'isPhoneAdmin' },
      // { label: '鍵共有権限', value: 'shareKeyBeValid' },
      { label: 'ステータス', value: 'isValid' },
      // { label: '備考1', value: 'option1' },
      // { label: '備考2', value: 'option2' },
      // { label: '登録日', value: 'registered' },
      // { label: '更新日', value: 'updated' },
      // { label: 'SMS認証', value: 'isMfaSms' },
      // { label: '二段階認証パスワード', value: 'passCode' },
    ],
    idSelected: [],
    showOption: false,
    showProgress: false,
    isVaildOnly: localStorage.isVaildDataCard === 'true' ? true : false,
    userId: '',
    isConfirm: false,
    isShowValidUser: localStorage.isShowValidUser === 'true' ? true : false,
    isReloadData: false,
  }),
  methods: {
    Change() {
      if (this.isPage !== 'affiliation-add-new') {
        this.$emit('getDepartmentUserValid', {departmentId: this.departmentId, value: this.isShowValidUser});
      }
    },
    showUserDetail(val: any) {
      this.userId = val;
      this.isShowUserDetail = true;
    },
    closeModalUser(val: any) {
      this.showProgress = false;
      this.idSelected = [];
      if (val) {
        this.$emit('load-users', 1);
      }
    },
    removeUser(data: any) {
      this.type = 'del';
      if (this.isPage === 'affiliation-add-new') {
        this.showProgress = false;
        this.isConfirm = false;
        this.$emit('affiliation-remove-item-direct', data);
        this.idSelected = [];
      } else {
      this.showProgress = true;
      this.isConfirm = false;
      this.$emit('remove-user-to-group', data);
      }
    },
    loadShowUser() {
      this.isShowUser = true;
      this.isReloadData = false;
    },
    CheckColumn(data: any) {
      localStorage.setItem('displayOptionPCode', JSON.stringify(data));
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
    converDateShort(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'userName') {
            if (!isDescending[0]) {
              return (a.user.name < b.user.name) ? -1 : 1;
            } else {
              return (b.user.name < a.user.name) ? -1 : 1;
            }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    closeModalDetail(isReloadData: any) {
      if (this.isPage === 'group') {
        this.isShowUserDetail = false;
        if (isReloadData) {
          this.$emit('close-modal');
        }
      } else {
        this.isShowUserDetail =  false;
      }
    },
    getReload(val: any) {
      this.isReloadData = true;
      this.$emit('getReload', val);
    },
    closeModal(val: any) {
      if (val) {
        this.$emit('close-modal');
      }
    },
  },
});
