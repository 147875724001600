































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import DateTimePicker from '@/components/molecules/DateTimePicker/DateTimePicker.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import moment from 'moment';
import * as Config from '@/config';
export interface DataType {

  // userInfo: User;
  realtionsGateList: any;
  timeStartGate: string;
  timeEndGate: string;
  dateStartGate: string;
  dateEndGate: string;
  itemGate: any;
  rulesValidate: any;
  authorityItems: any;
  type: any;
  addItem: any;
  errorAddListGate: any;
  errorGate: any;
  errorTime: any;
  selectedItem: any;
  itemUser: any;
  showProgress: any;
  isConfirm: boolean;
  itemDel: any;
}



export default Vue.extend({
  name: 'UserAddForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    DateTimePicker,
    ShowPerPage,
    ModalTitle,
    Modal,
    Pagination,
  },
  props: {
    dataList: {
      default: () => [],
    },
    dataGatePass: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    gates: {
      type: Array,
      default: () => [],
    },
    listGatePassages: {
      default: () => [],
    },
    userList: {
      type: Array,
      default: () => [],
    },
    gateList: {
      type: Array,
      default: () => [],
    },
    isDisabledGateOption: {
      type: Boolean,
      default: true,
    },
    isDisabledPCodeOptions: {
      type: Boolean,
      default: true,
    },
    isDisabledGroupOptions: {
      type: Boolean,
      default: true,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    gateGroupOptions: {
      type: Array,
      default: () => [],
    },
    pCodeOptions: {
      type: Array,
      default: () => [],
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    phoneItems: {
      type: Array,
      default: () => [],
    },
    groupsAddFail: {
      type: Array,
      default: () => [],
    },
    pCodeAddFail: {
      type: Array,
      default: () => [],
    },
    gateAddFail: {
      type: Array,
      default: () => [],
    },
    authority: {
      type: Object,
      default: ({}),
    },
    dataRealtionsGateList: {
      type: Object,
      default: ({}),
    },
    isAdminOfGatePassages: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$emit('getListGate');
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [];
        headers.push({
          text: 'ゲート名',
          align: 'start',
          sortable: true,
          width: '12%',
          value: 'gate.name',
        });
        headers.push({
          text: '時間',
          align: 'start',

          value: 'time',
        });
        headers.push({
          text: 'ユーザーID',
          align: 'start',
          sortable: false,
          width: '10%',
          value: 'userId',
        });
        headers.push({
          text: 'ユーザー名',
          align: 'start',
          sortable: false,
          width: '10%',
          value: 'userName',
        });
        headers.push({
          text: '',
          align: 'center',
          sortable: false,
          width: '50px',
          value: 'icon',
        });
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.dataRealtionsGateList.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    showProgress: false,
    selectedItem: [],
    errorAddListGate: '',
    errorGate: '',
    errorTime: '',
      addItem: {
        index: 0,
        item: '',
      },
    type: '0',
    realtionsGateList: [],
    timeStartGate: '',
    timeEndGate: '',
    dateStartGate: '',
    dateEndGate: '',
    itemGate: [],
    itemUser: [],
    rulesValidate: {
      requiredUserId: (value: string) => !!value || 'ユーザーIDは必須です。',
      validateUserId: (userId: string) => {
        const pattern = /^[a-zA-Z0-9@.!#$&*+/=?^_`{|}~-]{4,96}$/;
        return pattern.test(userId) || 'ユーザーIDは半角英数字で4文字以上96文字以内で設定してください。';
      },
      validateFurigana: (furigana: string) => {
      if (furigana !== null && furigana !== undefined && furigana !== '') {
          return furigana.length <= 64 || 'ふりがなは64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateOption1: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateOption2: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateEmail: (email: string) => {
        if (email !== null && email !== undefined && email !== '') {
          const pattern = email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/);
          if (pattern) {
            return true;
          } else {
            return '正しいメールアドレスを入力してください。';
          }
        } else {
          return true;
        }
      },
      // requiredPassword: (pass: string) => !!pass || 'パスワードは必須です。',
      validatePassword: (pass: string) => {
        const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
        return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
      },
      validateNewPassword: (pass: string) => {
        if (pass !== null && pass !== undefined && pass !== '') {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
          return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
        } else {
          return true;
        }
      },
      requiredName: (name: string) => !!name || 'ユーザー名は必須です。',
      validateName: (name: string) => {
        if (name !== null && name !== undefined && name !== '') {
          return name.length < 64 || 'ユーザー名は64文字以内で入力してください。';
        } else {
          return true;
        }

      },
      validatePhone: (phone: string, checkSMS: boolean) => {
        if (checkSMS) {
          const pattern = /^[0][0-9]{10}$/;
          return pattern.test(phone) || '0から始まる-(ハイフン)無しの携帯電話番号を半角数字で11桁入力してください。';
        } else {
          return true;
        }
      },
      validateConfirmPass(confirm: any, pass: any) {
        if (confirm !== pass) {
          return '「新しいパスワード」と一致しません。';
        } else {
          return true;
        }
      },
      validatePassCode(val: any) {
        if (val !== '' && val !== null) {
          const pattern = val.match(/^\d{4}$/);
          if (pattern) {
            return true;
          } else {
            return '解錠時の二段階認証パスワードは半角数字で4文字入力してください。';
          }
        } else {
          return true;
        }
      },
    },
    authorityItems: [
      { text: '一般ユーザー', value: false },
      { text: '管理者', value: true },
    ],
    isConfirm: false,
    itemDel: [],
  }),
  watch: {
    loading(val) {
      //
      if (val) {
        this.errorAddListGate = '';
        this.errorGate = '';
        this.errorTime = '';
      } else {
        if (this.errorMessageLogs === '') {
          this.dateStartGate = '';
          this.dateEndGate = '';
          this.timeStartGate = '';
          this.timeEndGate = '';
          this.itemGate = [];
          this.itemUser = [];
        }
      }
    },
    selectedItem(val) {
      // console.log('val', val);
    },
    successMessageLogs(val: any) {
        this.dateStartGate = '';
        this.dateEndGate = '';
        this.timeStartGate = '';
        this.timeEndGate = '';
        this.itemGate = [];
    },
  },
  methods: {
    closeModalGate() {
      this.showProgress = false;
      this.selectedItem = [];
      this.$emit('load-gates', 1);
    },
    deleteTemporaryPermission() {
      console.log('this.itemDel', this.itemDel);
      this.showProgress = true;
      this.isConfirm = false;
      if (this.selectedItem.length > 0) {
        this.$emit('remove-gate-pass', this.selectedItem);
      } else {
        const data = [];
        data.push(this.itemDel);
        this.$emit('remove-gate-pass-item', data);
      }
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      console.log(items);
      items.sort((a: any, b: any) => {
        if (index[0] === 'gateName') {
          if (!isDescending[0]) {
              return (a.gate.text < b.gate.text) ? -1 : 1;
          } else {
              return (b.gate.text < a.gate.text) ? -1 : 1;
          }
        } else if (index[0] === 'time') {
          if (!isDescending[0]) {
              return (a.notAfter < b.notAfter) ? -1 : 1;
          } else {
              return (b.notAfter < a.notAfter) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    isObject(val: any) {
      return val instanceof Object;
    },
    getNewTimeStart(time: string) {
      this.timeStartGate = time;
    },
    getNewTimeEnd(time: string) {
      this.timeEndGate = time;
    },
    getNewDateStart(date: string) {
      this.dateStartGate = date;
    },
  getNewDateEnd(date: string) {
      this.dateEndGate = date;
    },
    formatDateTime(date: Date): string {
      // return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}
      // ${date.getHours()}:${date.getMinutes()}`;
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const min = date.getMinutes().toString().padStart(2, '0');
      return year + '/' + month + '/' + day + ' ' + hour + ':' + min;

    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    deleteGateConfirm(item: any) {
      this.itemDel = item;
      this.isConfirm = true;
      this.selectedItem = [];
    },
    deleteGate(item: any) {
      // this.realtionsGateList.splice(parseInt(index), 1);
      console.log('item', item);
      this.showProgress = true;
      const data = [];
      data.push(item);
      this.$emit('remove-gate-pass-item', data);
    },
    addGate() {
      let count = 0;
      this.errorAddListGate = '';
      this.errorGate = '';
      this.errorTime = '';
      const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
      const hoursStartError =  pattern.test(this.timeStartGate);
      const hoursEndError =  pattern.test(this.timeEndGate);
      const timeStart = this.dateStartGate + ' ' + this.timeStartGate;
      const timeEnd = this.dateEndGate + ' ' + this.timeEndGate;
      for (const item of this.realtionsGateList) {
        const oldGate = item.gate.value + '-' + item.notBefore + '-' + item.notAfter;
        const newGate = this.itemGate.value + '-' + this.formatTimestamp(this.dateStartGate + ' ' + this.timeStartGate)
        + '-' + this.formatTimestamp(this.dateEndGate + ' ' + this.timeEndGate);
        if (oldGate === newGate) {
          this.errorAddListGate = 'すでに追加されている時間帯になります。追加する場合は、一度削除してから実施してください。';
          this.errorGate = '';
          this.errorTime = '';
          break ;
        } else {
          count++;
        }
      }
      if (count === this.realtionsGateList.length && this.dateStartGate !== ''
      && this.timeStartGate !== '' && this.dateEndGate !== ' ' && this.timeEndGate !== '' &&
      hoursStartError === true && hoursEndError === true && timeEnd > timeStart) {
        // this.realtionsGateList.push({
        //   key: this.realtionsGateList.length + 1,
        //   gate: this.itemGate,
        //   userId: this.itemUser.text,
        //   userName: this.itemUser.value,
        //   notBefore: this.formatTimestamp(this.dateStartGate + ' ' + this.timeStartGate),
        //   notAfter: this.formatTimestamp(this.dateEndGate + ' ' + this.timeEndGate),
        // });
        const data = {
          key: this.realtionsGateList.length + 1,
          gate: this.itemGate,
          userId: this.itemUser.value,
          userName: this.itemUser.text,
          notBefore: this.formatTimestamp(this.dateStartGate + ' ' + this.timeStartGate),
          notAfter: this.formatTimestamp(this.dateEndGate + ' ' + this.timeEndGate),
        };
        this.$emit('add-gate', data);
      } else {
        if (this.itemGate.length === 0 || this.itemGate.value === '') {
          this.errorGate = 'ゲートを指定してください。';
          this.errorAddListGate = '';
        }
        if (timeEnd <= timeStart && this.errorAddListGate === '') {
          this.errorTime = Config.ERROR_END_TIME;
        }
        if ((hoursStartError === false || hoursEndError === false) && this.errorAddListGate === '') {
          this.errorTime = '時間を指定してください。';
        }
      }
    },
  },
});
