




















































































































































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateList from '@/components/organisms/GateList/GateList.vue';
import * as Config from '@/config';
import { Group, Gate, RelationGateGroup } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import auth from '@/auth';
export interface DataType {
  selectedItem: any;
  loadGroups: any;
  showConfirm: boolean;
  groupPassage: any;
  groupItem: any;
}

export default Vue.extend({
  name: 'GroupGateTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
    GateList,
  },
  props: {
    isClear: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
    pageGroup: {
      type: Number,
      default: 0,
    },
    userId: {
      type: String,
      default: '',
    },
    ExpandedGroup: {
      type: Array,
      default: () => [],
    },
    isPage: {
      type: String,
      default: '',
    },
    messageSetting: {
      default: () => ({
        error: '',
        success: '',
      }),
    },
    authority: {
        type: Object,
        default: ({}),
      },
      errorName: {
        type: String,
        default: '',
      },
  },
  data: (): DataType => ({
    groupPassage: [],
    selectedItem: [],
    loadGroups: [],
    showConfirm: false,
    groupItem: '',
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ゲート名',
            align: 'start',
            sortable: false,
            width: '33.3%',
            value: 'gateName',
          },
          {
            text: '適用されている通行権限グループ',
            align: 'start',
            sortable: false,
            width: '33.3%',
            value: 'groupName',
          },
          {
            text: '通行可能時間帯',
            align: 'start',
            sortable: false,
            width: '33.3%',
            value: 'passableTimeSettingName',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.pageGroup;
      },
      set(val: number): void {
        this.$emit('change-page-group', {page: val, userId: this.userId, change: 'group' });
        return;
      },
    },
  },
  watch: {
    selectedItem(val: []) {
      if (this.isPage === 'add-user-group-gate') {
        this.$emit('select-Ids', val.map((item: any) => item.groupId));
      } else if (this.isPage === 'edit-user-group-gate') {
        this.$emit('select-Ids', val.map((item: any) => item.group));
      } else {
        this.$emit('select-Ids', val.map((item: any) => item.group));
      }
    },
    isClear(val: any) {
      if (val === false) {
        this.selectedItem = [];
      }
    },
  },
  methods: {
    getItem(item: any, val: any) {
      if (val) {
        const data = this.ExpandedGroup.filter((element: any) =>  element.group.groupId === item.group.groupId);
        data.forEach((el: any) => {
          const i = this.selectedItem.map((e: any) => e.sub).indexOf(el.sub);
          if (i < 0) {
            this.selectedItem.push(el);
          }
        });
      } else {
        const data = this.selectedItem.filter((element: any) =>  element.group.groupId !== item.group.groupId);
        this.selectedItem = data.length > 0 ? data : [];
      }
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    clickDetail(ID: any) {
      console.log('this.$route.path', this.$route.path);
      if (this.$route.path === '/groups') {
        this.$router.push({ path: Config.GROUP, query: {groupId: ID}});
        this.$emit('close-popup', ID);
      } else {
        this.$router.push({ path: Config.GROUP, query: {groupId: ID}});
      }
    },
    addGroupPass(item: any) {
      this.showConfirm = true;
      this.groupPassage.push({
        text: item.group.name,
        value: item.group.groupId,
      });
    },
    checkPassableTimeSetting(item: any) {
      if (item.passableTimeSetting !== undefined && item.passableTimeSetting.timeSettings !== undefined
      && item.passableTimeSetting.timeSettings !== null && item.passableTimeSetting.timeSettings.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    showTime(data: any) {
      return auth.showTime(data);
    },
  },
});
