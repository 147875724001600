
























































































































































































































































































import Vue, { PropType } from 'vue';
import GroupListModalTable from '@/components/organisms/ModalTable/GroupDropdownList.vue';
import GroupGateTable from '@/components/organisms/ModalTable/GroupGateTable.vue';
import UserListModalTable from '@/components/organisms/ModalTable/UserListTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, { SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import ModalLoading from '@/components/molecules/ModalTitle/ModalLoading.vue';
import * as Config from '@/config';

export interface DataType {
  isAuthority: string;
  showProgress: boolean;
  selectedGroupIds: any;
  selectedGroupAddIds: any;
  isShowGroup: any;
  isConfirm: any;
  dataGroupAdd: any;
  type: any;
  isClear: any;
  showProgressDirect: any;
  dataProgressDirect: any;
}
export default Vue.extend({
  name: 'GroupListModalTemplate',
  components: {
    Searchbox,
    Breadcrumbs,
    ActionButton,
    GroupListModalTable,
    GroupGateTable,
    UserListModalTable,
    ModalTitle,
    SuccessButton,
    CancelButton,
    ModalLoading,
  },
  props: {
    loadingParent: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: String,
      default: '',
    },
    isPage: {
      type: String,
      default: '',
    },
    dataModal: {
      type: Object,
      default: ({}),
    },
    dataProgress: {
      type: Object,
      default: ({}),
    },
    authority: {
      type: Object,
      default: ({}),
    },
    userId: {
      type: String,
      default: '',
    },
    departmentId: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    groupPassages: {
      type: Array,
      default: [],
    },
    dataAdded: {
      type: Array,
      default: [],
    },
    errorName: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    isConfirm: false,
    isShowGroup: false,
    showProgress: false,
    isAuthority: localStorage.authority,
    selectedGroupIds: [],
    type: 'add',
    dataGroupAdd: [],
    isClear: false,
    selectedGroupAddIds: [],
    showProgressDirect: false,
    dataProgressDirect: {
      total: 0,
      progressLoad: 0,
      successItem: 0,
    },
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: '全ての通行権限グループ一覧', disabled: true },
        ];
      },
    },
  },
  methods: {
    //
    selectIds(data: any) {
      this.selectedGroupIds = data;
      this.$emit('select-Ids', data);
    },
    closeModalGroup() {
      this.showProgress = false;
      if (this.isPage !== 'add-user-group-gate') {
        this.selectedGroupAddIds = [];
      }
      this.selectedGroupIds = [];
      this.$emit('close-modal-group');
      this.isClear = true;
      setTimeout(() => {
        this.isClear = false;
      }, 100);
    },
    closeAdd() {
      if (this.isPage !== 'add-user-group-gate') {
        this.selectedGroupAddIds = [];
      }
    },
    addGroupInEdit(event: any) {
      this.type = 'add-in-edit';
      const dataSelect  = Array.of(...event);
      this.dataGroupAdd = dataSelect;
      this.showProgressDirect = true;
      this.dataProgressDirect.total = dataSelect.length;
      this.dataProgressDirect.progressLoad = 100;
      this.dataProgressDirect.successItem = dataSelect.length;
    },
    addGroup(event: any) {
      this.type = 'add';
      this.showProgress = true;
      this.$emit('add-group-user', event);
    },
    addGroupDirect(event: any) {
      this.showProgressDirect = true;
      this.type = 'add';
      const dataSelect  = Array.of(...event);
      const data = dataSelect.concat(this.dataModal.groupPass.data);
      data.map((v: any) => Object.assign(v, {group:
        {
          passableTimeSetting: v.passableTimeSetting,
          name: v.name,
          groupId: v.groupId,
        },
      }));
      this.dataGroupAdd = data;
      this.dataProgressDirect.total = dataSelect.length;
      this.dataProgressDirect.progressLoad = 100;
      this.dataProgressDirect.successItem = dataSelect.length;
      // this.$emit('select-data-add', this.dataGroupAdd);
      // this.$emit('get-data-add', this.dataGroupAdd);
    },
    closeProgressDirect() {
      this.showProgressDirect = false;
      if (this.type === 'add') {
        this.$emit('select-data-add', this.dataGroupAdd);
        this.$emit('get-data-add', this.dataGroupAdd);
      } else if (this.type === 'del-in-edit') {
        //
        this.isClear = true;
        this.$emit('remove-group-in-edit', this.selectedGroupIds);
        setTimeout(() => {
        this.isClear = false;
      }, 100);
      } else if (this.type === 'add-in-edit') {
        //
        this.isClear = true;
        setTimeout(() => {
        this.isClear = false;
        this.$emit('add-group-in-edit', this.dataGroupAdd);
      }, 100);
      } else {
        this.isClear = true;
        this.selectedGroupIds.forEach((item: any) => {
          const i = this.dataGroupAdd.map((e: any) => e.groupId).indexOf(item);
          if (i >= 0) {
            this.dataGroupAdd.splice(parseInt(i), 1);
          }
        });
        const Ids: any[] = [];
        this.dataGroupAdd.forEach((item: any) => {
          Ids.push(item.groupId);
        });
        this.$emit('select-Ids', Ids);
        this.$emit('select-data-add', this.dataGroupAdd);
        this.$emit('get-data-add', this.dataGroupAdd);
        setTimeout(() => {
        this.isClear = false;
      }, 100);
      }
    },
    removeGroupDirect() {
      this.showProgressDirect = true;
      this.type = 'del';
      this.isConfirm = false;
      this.dataProgressDirect.total = this.selectedGroupIds.length;
      this.dataProgressDirect.progressLoad = 100;
      this.dataProgressDirect.successItem = this.selectedGroupIds.length;
    },
    removeGroupInEdit() {
      this.showProgressDirect = true;
      this.type = 'del-in-edit';
      this.isConfirm = false;
      this.dataProgressDirect.total = this.selectedGroupIds.length;
      this.dataProgressDirect.progressLoad = 100;
      this.dataProgressDirect.successItem = this.selectedGroupIds.length;
    },
    removeGroup() {
      this.type = 'del';
      this.isConfirm = false;
      this.showProgress = true;
      this.$emit('remove-group-user', this.selectedGroupIds);
    },
    showGroup() {
      this.selectedGroupAddIds = [];
      this.isShowGroup = true;
      this.$emit('get-all-group');
    },
    addUser(event: any) {
      if (this.isPage === 'affiliation-add-new') {
        this.$emit('affiliation-add-item', event);
      } else {
        this.type = 'add';
        this.showProgress = true;
        if (this.isAction === 'add-user-to-aff') {
          this.$emit('add-user-to-aff', event);
        } else {
          this.$emit('add-user-to-group', event);
        }
      }
    },
  },
});
