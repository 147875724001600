var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-users-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "container-user container-common" },
        [
          _c(
            "div",
            [
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "btn-div mr-3", attrs: { justify: "end" } },
                    [
                      _vm.authority.isAdmin === true ||
                      _vm.authority.isAdminToAddUser === true
                        ? _c(
                            "div",
                            { staticClass: "text-center mr-1" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "open-on-hover": "",
                                    "offset-y": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "btn-primary"
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                    ユーザー登録\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2227491411
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("clickAddUser")
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("ユーザー登録")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.authority.isAdmin === true
                                        ? _c(
                                            "v-list-item",
                                            {
                                              attrs: { link: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "showDialogImport",
                                                    { exportType: 0, type: "" }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("一括登録")
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.authority.isAdmin === true
                                        ? _c(
                                            "v-list-item",
                                            {
                                              attrs: { link: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "showDialogImport",
                                                    { exportType: 1, type: "" }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  "期限付き解錠可能ゲート登録"
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.authority.isAdmin === true ||
                          _vm.authority.isAdminToEditUser === true
                            ? _c("action-button", {
                                attrs: {
                                  text: "アクション ▼",
                                  "action-items": _vm.actionItems,
                                  disabled:
                                    _vm.selectedUserIds.length === 0 ||
                                    _vm.isGetDataList === true
                                },
                                on: {
                                  "click-enable": function($event) {
                                    return _vm.$emit("confirmAction", {
                                      action: 0
                                    })
                                  },
                                  "click-disable": function($event) {
                                    return _vm.$emit("confirmAction", {
                                      action: 1
                                    })
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "btn-div area-search-box ",
                      attrs: { justify: "end" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "input-search-user",
                          attrs: { sm: "12", xl: "8", lg: "7" }
                        },
                        [
                          _c("searchbox", {
                            staticClass: "input-search  btn-user-search",
                            attrs: {
                              title: "検索",
                              searchTargetItems: _vm.searchTargetItems,
                              disabled:
                                _vm.isGetDataList ||
                                (_vm.groupIdSearch !== "" &&
                                  _vm.groupIdSearch !== null &&
                                  _vm.groupIdSearch !== undefined)
                            },
                            on: {
                              search: function($event) {
                                return _vm.$emit("searchUser", $event)
                              }
                            },
                            model: {
                              value: _vm.searchItem,
                              callback: function($$v) {
                                _vm.searchItem = $$v
                              },
                              expression: "searchItem"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { sm: "9", xl: "3", lg: "3" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "input-search",
                            attrs: {
                              clearable: "",
                              items: _vm.departments,
                              label: "所属で絞り込み",
                              solo: "",
                              "hide-details": "",
                              "no-data-text": "",
                              disabled: _vm.isGetDataList || _vm.keyword !== "",
                              "menu-props": { contentClass: "v-select-item" },
                              attach: true
                            },
                            on: {
                              change: function($event) {
                                return _vm.$emit(
                                  "search-deparment-group",
                                  $event
                                )
                              }
                            },
                            model: {
                              value: _vm.groupIdSearch,
                              callback: function($$v) {
                                _vm.groupIdSearch = $$v
                              },
                              expression: "groupIdSearch"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { sm: "3", xl: "1", lg: "2" } },
                        [
                          _c("text-link", {
                            staticClass:
                              "item-user-name decoration-link inline-block",
                            attrs: { text: "所属一覧を見る" },
                            on: {
                              click: function($event) {
                                _vm.isShow = true
                                _vm.isReload = false
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "btn-div area-search-box pl-0",
                  attrs: { justify: "end" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "12" } },
                    [
                      _c("user-table", {
                        attrs: {
                          users: _vm.users,
                          listGroupPassages: _vm.listGroupPassages,
                          gateList: _vm.gateList,
                          isGetDataList: _vm.isGetDataList,
                          ExpandedGroup: _vm.ExpandedGroup,
                          listphones: _vm.listphones,
                          listGatePassages: _vm.listGatePassages,
                          tempGroupPassages: _vm.tempGroupPassages,
                          cardItems: _vm.cardItems,
                          phoneItems: _vm.phoneItems,
                          successMessageLogs: _vm.showModalImport
                            ? ""
                            : _vm.successMessageLogs,
                          errorMessageLogs: _vm.showModalImport
                            ? ""
                            : _vm.errorMessageLogs,
                          page: _vm.page,
                          perPage: _vm.perPage,
                          pageGroup: _vm.pageGroup,
                          groupDetail: _vm.groupDetail,
                          "show-select":
                            _vm.authority.isAdmin === true ||
                            _vm.authority.isAdminToEditUser === true
                              ? true
                              : false,
                          loadingGroup: _vm.loadingGroup,
                          pageGate: _vm.pageGate,
                          isExport: _vm.isExport,
                          groupId: _vm.groupId,
                          loadingGate: _vm.loadingGate,
                          dataAllow: _vm.dataAllow,
                          maxRecord: _vm.maxRecord,
                          groupToSearch: _vm.groupToSearch,
                          ExpandedData: _vm.ExpandedData,
                          authority: _vm.authority
                        },
                        on: {
                          clickEditUser: function($event) {
                            return _vm.$emit("clickEditUser", $event)
                          },
                          "change-per-page": function($event) {
                            return _vm.$emit("changePerPage", $event)
                          },
                          "change-page": function($event) {
                            return _vm.$emit("change-page", $event)
                          },
                          "change-page-group-gate": function($event) {
                            return _vm.$emit("change-page", $event)
                          },
                          "change-expanded-group": function($event) {
                            return _vm.$emit("change-expanded", $event)
                          },
                          "change-expanded-user": function($event) {
                            return _vm.$emit("change-expanded", $event)
                          },
                          "select-users": function(userIds) {
                            _vm.selectedUserIds = userIds
                          },
                          "click-leave-group": function($event) {
                            return _vm.$emit("click-leave-group", $event)
                          },
                          "confirm-action": function($event) {
                            return _vm.$emit("confirmAction", $event)
                          },
                          "show-only-isVaild": function($event) {
                            return _vm.$emit("show-only-isVaild", $event)
                          },
                          "load-total": function($event) {
                            return _vm.$emit("load-total", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showModalImport
            ? _c("user-import", {
                attrs: {
                  showModalImport: _vm.showModalImport,
                  showModalProcess: _vm.showModalProcess,
                  progress: _vm.progress,
                  success: _vm.success,
                  totalDataRowCSV: _vm.totalDataRowCSV,
                  successItemCSV: _vm.successItemCSV,
                  errorMessage: _vm.errorMessage,
                  errorMessageLogs: _vm.errorMessageLogs,
                  successMessageLogs: _vm.successMessageLogs,
                  showProcessImportExcel: _vm.showProcessImportExcel,
                  successItem: _vm.successItem,
                  totalDataRow: _vm.totalDataRow,
                  isExport: _vm.isExport,
                  errorMessageExcel: _vm.errorMessageExcel,
                  isFinishImportExcel: _vm.isFinishImportExcel,
                  sheetUserName: _vm.sheetUserName,
                  exportType: _vm.exportType,
                  isType: _vm.isType,
                  showModalConfirmEmail: _vm.showModalConfirmEmail,
                  sheet1Name: _vm.sheet1Name,
                  sheet2Name: _vm.sheet2Name,
                  progressSheet1: _vm.progressSheet1,
                  successItemSheet1: _vm.successItemSheet1,
                  totalDataRowSheet1: _vm.totalDataRowSheet1,
                  progressSheet2: _vm.progressSheet2,
                  successItemSheet2: _vm.successItemSheet2,
                  totalDataRowSheet2: _vm.totalDataRowSheet2,
                  typeConfirm: _vm.typeConfirm,
                  isHasLowerCaseCardNumberExcel:
                    _vm.isHasLowerCaseCardNumberExcel
                },
                on: {
                  clickExportCSV: function($event) {
                    return _vm.$emit("clickExportCSV", $event)
                  },
                  clickExportCSVTimeUnlockGate: function($event) {
                    return _vm.$emit("clickExportCSVTimeUnlockGate")
                  },
                  clickConfirmEmail: function($event) {
                    return _vm.$emit("clickConfirmEmail", $event)
                  },
                  closeModalProcessCSV: function($event) {
                    return _vm.$emit("closeModalProcessCSV", $event)
                  },
                  CancelProcessCSV: function($event) {
                    return _vm.$emit("CancelProcessCSV", $event)
                  },
                  closeImportCSV: function($event) {
                    return _vm.$emit("closeImportCSV", $event)
                  },
                  clickImportCSV: function($event) {
                    return _vm.$emit("clickImportCSV", $event)
                  },
                  clickImportCSVTimeUnlockGate: function($event) {
                    return _vm.$emit("clickImportCSVTimeUnlockGate", $event)
                  },
                  clickExportExcel: function($event) {
                    return _vm.$emit("clickExportExcel", $event)
                  },
                  clickImportExcel: function($event) {
                    return _vm.$emit("clickImportExcel", $event)
                  },
                  closeModalProcessImportExcel: function($event) {
                    return _vm.$emit("closeModalProcessImportExcel", $event)
                  },
                  CancelProcessImportExcel: function($event) {
                    return _vm.$emit("CancelProcessImportExcel", $event)
                  },
                  "close-dialog-check-card-excel": function($event) {
                    return _vm.$emit("close-dialog-check-card-excel", $event)
                  },
                  "accept-dialog-check-card-excel": function($event) {
                    return _vm.$emit("accept-dialog-check-card-excel", $event)
                  },
                  clickCheckBoxExportUserInfoCSV: function($event) {
                    return _vm.$emit("clickCheckBoxExportUserInfoCSV", $event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("Modal", {
            attrs: {
              title: _vm.isTitle(),
              text: _vm.isText(),
              smallScreen: "600",
              className: "common-modal"
            },
            on: {
              close: function($event) {
                return _vm.$emit("closeModalConfirm")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "modal-footer",
                fn: function() {
                  return [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c("success-button", {
                      attrs: { text: "OK" },
                      on: {
                        click: function($event) {
                          return _vm.clickAction()
                        }
                      }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.isShowConfirm,
              callback: function($$v) {
                _vm.isShowConfirm = $$v
              },
              expression: "isShowConfirm"
            }
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "600", persistent: "" },
              model: {
                value: _vm.showProgress,
                callback: function($$v) {
                  _vm.showProgress = $$v
                },
                expression: "showProgress"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.action === 0 || _vm.action === 2
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("ユーザーを有効化")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 1 || _vm.action === 3
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("ユーザーを無効化")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 4
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("関連付け削除")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 5
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("関連付け削除")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-row", {
                    staticStyle: { height: "5px" },
                    attrs: { "no-gutters": "" }
                  }),
                  _vm._v(" "),
                  _vm.totalDataRowProcess > 0
                    ? _c(
                        "v-card-text",
                        { staticClass: "pa-4" },
                        [
                          _c(
                            "v-progress-linear",
                            {
                              staticStyle: { "pointer-events": "none" },
                              attrs: {
                                height: "25",
                                color: "green darken-1",
                                striped: ""
                              },
                              model: {
                                value: _vm.progress,
                                callback: function($$v) {
                                  _vm.progress = $$v
                                },
                                expression: "progress"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.successItemProcess > 0 &&
                          (_vm.action === 0 || _vm.action === 2)
                            ? _c("span", { staticClass: "success-label" }, [
                                _vm._v(
                                  _vm._s(_vm.totalDataRowProcess) +
                                    "件中" +
                                    _vm._s(_vm.successItemProcess) +
                                    "件を有効化しました。"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successItemProcess > 0 &&
                          (_vm.action === 1 || _vm.action === 3)
                            ? _c("span", { staticClass: "success-label" }, [
                                _vm._v(
                                  _vm._s(_vm.totalDataRowProcess) +
                                    "件中" +
                                    _vm._s(_vm.successItemProcess) +
                                    "件を無効化しました。"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successItemProcess > 0 &&
                          (_vm.action === 4 || _vm.action === 5)
                            ? _c("span", { staticClass: "success-label" }, [
                                _vm._v(
                                  _vm._s(_vm.totalDataRowProcess) +
                                    "件中" +
                                    _vm._s(_vm.successItemProcess) +
                                    "件を削除化しました。"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageProcess.length > 0
                            ? _c("div", [
                                _vm.action === 0 || _vm.action === 2
                                  ? _c(
                                      "span",
                                      { staticClass: "error-box-title" },
                                      [_vm._v("有効化失敗")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.action === 1 || _vm.action === 3
                                  ? _c(
                                      "span",
                                      { staticClass: "error-box-title" },
                                      [_vm._v("無効化失敗")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "error-box" },
                                  [
                                    _vm._l(_vm.errorMessageProcess, function(
                                      item
                                    ) {
                                      return [
                                        _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFinishProcess
                    ? _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-2", attrs: { justify: "end" } },
                            [
                              _c("success-button", {
                                attrs: { text: "OK" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("closeModalProgress", {
                                      action: _vm.action
                                    })
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "所属一覧",
          "no-footer": "",
          smallScreen: "800px",
          className: "common-padding"
        },
        on: {
          close: function($event) {
            _vm.isShow = false
            _vm.closeModal(_vm.isReload)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm.isShow
                  ? _c("AffiliationListModalPage", {
                      attrs: { isPage: "user", department: {} },
                      on: {
                        loading: function($event) {
                          return _vm.$emit("loading-from-child", $event)
                        },
                        "get-list-user": function($event) {
                          return _vm.$emit("get-list-user")
                        },
                        getReload: function($event) {
                          return _vm.getReload($event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isShow,
          callback: function($$v) {
            _vm.isShow = $$v
          },
          expression: "isShow"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }