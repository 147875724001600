var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    attrs: {
      className: "round-button-footer",
      value: _vm.isVisible,
      title:
        _vm.noticeSettingType.name +
        " : " +
        (_vm.mode === "edit" ? "編集" : "新規追加")
    },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "modal-body",
        fn: function() {
          return [
            _vm.isLoadBlockingNoticeSetting
              ? _c("loading", {
                  attrs: { text: _vm.loadBlockingTextNoticeSetting }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("alert", {
              attrs: { text: _vm.errorMessageLogsModal, type: "error" },
              model: {
                value: _vm.isDisplayErrorMessage,
                callback: function($$v) {
                  _vm.isDisplayErrorMessage = $$v
                },
                expression: "isDisplayErrorMessage"
              }
            }),
            _vm._v(" "),
            _c("alert", {
              attrs: { text: _vm.successMessageLogsModal, type: "success" },
              model: {
                value: _vm.isDisplaySuccessMessage,
                callback: function($$v) {
                  _vm.isDisplaySuccessMessage = $$v
                },
                expression: "isDisplaySuccessMessage"
              }
            }),
            _vm._v(" "),
            _c(
              "v-form",
              {
                ref: "formNoticeSetting",
                staticClass: "modal-body-top formNoticeSetting",
                attrs: { "lazy-validation": "" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { md: 12 } },
                      [
                        _c("input-form", {
                          attrs: {
                            title: "設定名称",
                            inputType: _vm.text,
                            rules: [_vm.innerEditNoticeSettingName.length !== 0]
                          },
                          model: {
                            value: _vm.innerEditNoticeSettingName,
                            callback: function($$v) {
                              _vm.innerEditNoticeSettingName = $$v
                            },
                            expression: "innerEditNoticeSettingName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { md: 12 } },
                      [
                        _c("input-form", {
                          attrs: {
                            title: "備考欄",
                            inputType: _vm.text,
                            rules: []
                          },
                          model: {
                            value: _vm.innerEditNoticeSettingOption,
                            callback: function($$v) {
                              _vm.innerEditNoticeSettingOption = $$v
                            },
                            expression: "innerEditNoticeSettingOption"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.innerEditNoticeSettingEmails, function(
                  email,
                  index
                ) {
                  return [
                    _c(
                      "v-row",
                      {
                        key:
                          "" + _vm.innerEditNoticeSettingEmails.length + index
                      },
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              md:
                                _vm.innerEditNoticeSettingEmails.length > 1
                                  ? 11
                                  : 12
                            }
                          },
                          [
                            _c("input-form", {
                              attrs: {
                                title: "通知先メールアドレス" + (index + 1),
                                inputType: _vm.text,
                                helpText:
                                  ",区切りでまとめてメールアドレス登録できます",
                                rules: [_vm.isValidateEmail(email)],
                                value: _vm.innerEditNoticeSettingEmails[index]
                              },
                              on: {
                                change: function($event) {
                                  return _vm.splitEmail($event, index)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.innerEditNoticeSettingEmails.length > 1
                          ? _c(
                              "v-col",
                              { attrs: { md: 1 } },
                              [
                                _c("icon-button", {
                                  attrs: {
                                    text: "メール削除",
                                    icon: "mdi-minus mdi-18px"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteEmail(index)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "btn-add-notice-setting",
                        attrs: { "offset-md": 2 }
                      },
                      [
                        _c("icon-button", {
                          attrs: {
                            text: "メール追加",
                            icon: "mdi-plus mdi-18px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.addNewEmail()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.noticeSettingType.targets, function(target) {
                  return [
                    target.targetColumn === "userId"
                      ? _c(
                          "v-row",
                          { key: target.targetColumn + target.targetTable },
                          [
                            _c(
                              "v-col",
                              { attrs: { md: 12 } },
                              [
                                _c("input-form", {
                                  attrs: {
                                    title: "監視対象ユーザー",
                                    helpText:
                                      "" +
                                      (target.isRequired ? "指定必須。" : "") +
                                      "複数ユーザー指定した場合はいずれかのユーザー通過及びアクションを起こした時通知します。",
                                    inputType: "multi-select",
                                    rules: [
                                      target.isRequired
                                        ? _vm.isValidateLengthOneMore(
                                            _vm.userIds
                                          )
                                        : true
                                    ],
                                    selectItems: _vm.configurableUsers,
                                    classMore: "common-class"
                                  },
                                  model: {
                                    value: _vm.userIds,
                                    callback: function($$v) {
                                      _vm.userIds = $$v
                                    },
                                    expression: "userIds"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    target.targetColumn === "deviceId"
                      ? _c(
                          "v-row",
                          { key: target.targetColumn + target.targetTable },
                          [
                            _c(
                              "v-col",
                              { attrs: { md: 12 } },
                              [
                                _c("input-form", {
                                  attrs: {
                                    title: "監視対象個人コード",
                                    helpText:
                                      "" +
                                      (target.isRequired ? "指定必須。" : "") +
                                      "複数個人コード指定した場合はいずれかの個人コードによる通過した時通知します。",
                                    inputType: "multi-select",
                                    rules: [
                                      target.isRequired
                                        ? _vm.isValidateLengthOneMore(
                                            _vm.pCodeIds
                                          )
                                        : true
                                    ],
                                    selectItems: _vm.configurablePcodes,
                                    classMore: "common-class"
                                  },
                                  model: {
                                    value: _vm.pCodeIds,
                                    callback: function($$v) {
                                      _vm.pCodeIds = $$v
                                    },
                                    expression: "pCodeIds"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    target.targetColumn === "gateId"
                      ? _c(
                          "v-row",
                          { key: target.targetColumn + target.targetTable },
                          [
                            _c(
                              "v-col",
                              { attrs: { md: 12 } },
                              [
                                _c("input-form", {
                                  attrs: {
                                    title: "監視対象ゲート",
                                    helpText:
                                      "" +
                                      (target.isRequired ? "指定必須。" : "") +
                                      "複数ゲートを指定した場合は、いずれかのゲートでログが発生したときに通知します。",
                                    inputType: "multi-select",
                                    rules: [
                                      target.isRequired
                                        ? _vm.isValidateLengthOneMore(
                                            _vm.gateIds
                                          )
                                        : true
                                    ],
                                    selectItems: _vm.configurableGates,
                                    classMore: "common-class"
                                  },
                                  model: {
                                    value: _vm.gateIds,
                                    callback: function($$v) {
                                      _vm.gateIds = $$v
                                    },
                                    expression: "gateIds"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    target.targetColumn === "logId"
                      ? _c(
                          "v-row",
                          { key: target.targetColumn + target.targetTable },
                          [
                            _c(
                              "v-col",
                              { attrs: { md: 12 } },
                              [
                                _c("input-form", {
                                  attrs: {
                                    title: "監視対象ログ",
                                    helpText:
                                      "" +
                                      (target.isRequired ? "指定必須。" : "") +
                                      "複数ログ指定した場合はいずれかのログが発生した時通知します。",
                                    inputType: "multi-select",
                                    rules: [
                                      target.isRequired
                                        ? _vm.isValidateLengthOneMore(
                                            _vm.logIds
                                          )
                                        : true
                                    ],
                                    selectItems: _vm.configurableLogs,
                                    classMore: "common-class"
                                  },
                                  model: {
                                    value: _vm.logIds,
                                    callback: function($$v) {
                                      _vm.logIds = $$v
                                    },
                                    expression: "logIds"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _vm.mode === "edit"
              ? _c("success-button", {
                  attrs: { text: "決定" },
                  on: {
                    click: function($event) {
                      return _vm.clickEditPutNoticeSetting()
                    }
                  }
                })
              : _c("success-button", {
                  attrs: { text: "登録" },
                  on: {
                    click: function($event) {
                      return _vm.clickAddPostNoticeSetting()
                    }
                  }
                }),
            _vm._v(" "),
            _c("cancel-button", {
              attrs: { text: "閉じる" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }