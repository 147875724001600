var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("group-list-template", {
    attrs: {
      dataGroups: _vm.dataGroups,
      groupTotalCount: _vm.groupTotalCount,
      page: _vm.currPage,
      perPage: _vm.perPage,
      loading: _vm.loading,
      loadingAff: _vm.loadingAff,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      typeAction: _vm.typeAction,
      showProgress: _vm.showProgress,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      progress: _vm.progress,
      isFinishProcess: _vm.isFinishProcess,
      showConfirm: _vm.showConfirm,
      isRemoveGroupSelected: _vm.isRemoveGroupSelected,
      isGetDataList: _vm.isGetDataList,
      groupExpanedGate: _vm.groupExpanedGate,
      isReRenderGate: _vm.isReRenderGate,
      isRemoveGateSelected: _vm.isRemoveGateSelected,
      groupExpanedUser: _vm.groupExpanedUser,
      isReRenderUser: _vm.isReRenderUser,
      isRemoveUserSelected: _vm.isRemoveUserSelected,
      dataGroup: _vm.dataGroup,
      groupList: _vm.groupList,
      departments: _vm.departments,
      users: _vm.users,
      userList: _vm.userList,
      gateList: _vm.gateList,
      dataRelationDepartmentGroup: _vm.dataRelationDepartmentGroup,
      dataRelationUserGroup: _vm.dataRelationUserGroup,
      dataGatePass: _vm.dataGatePass,
      authority: _vm.authority,
      optionDepartments: _vm.optionDepartments,
      tabClicked: _vm.tabClicked
    },
    on: {
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      clickAddGroup: function($event) {
        return _vm.clickAddGroup()
      },
      clickEditGroup: function($event) {
        return _vm.clickEditGroup($event)
      },
      changePage: function($event) {
        return _vm.changePage($event)
      },
      "search-group": function($event) {
        return _vm.searchGroup($event)
      },
      clickGroupDetail: function($event) {
        return _vm.clickGroupDetail($event)
      },
      "click-groups-action": function($event) {
        return _vm.clickGroupsAction()
      },
      closeModalProgress: function($event) {
        return _vm.closeModalProgress()
      },
      "show-dialog-confirm": function($event) {
        return _vm.showDialogConfirm($event)
      },
      "close-dialog-confirm": function($event) {
        return _vm.closeDialogConfirm()
      },
      "change-expanded-group": function($event) {
        return _vm.changeExpandedGroup($event)
      },
      "change-page-gate": function($event) {
        return _vm.changePageGate($event)
      },
      "change-page-user": function($event) {
        return _vm.changePageUser($event)
      },
      "show-only-isVaild": function($event) {
        return _vm.ShowOnlyIsVaild($event)
      },
      changeTab: function($event) {
        return _vm.changeTab($event)
      },
      clickTab: function($event) {
        return _vm.clickTab($event)
      },
      "load-list-aff": function($event) {
        return _vm.loadListAff($event)
      },
      "change-page-aff": function($event) {
        return _vm.loadListAff($event)
      },
      "add-aff-to-group": function($event) {
        return _vm.addAffToGroup($event)
      },
      "remove-aff-to-group": function($event) {
        return _vm.removeAffToGroup($event)
      },
      "load-users": function($event) {
        return _vm.getUserAgain($event)
      },
      "add-gate": function($event) {
        return _vm.addGate($event)
      },
      "remove-gate-pass": function($event) {
        return _vm.removeGatePass($event)
      },
      "load-gates": function($event) {
        return _vm.loadGatePass($event)
      },
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      },
      "click-EditGate": function($event) {
        return _vm.clickEditGate($event)
      },
      "remove-user-to-group": function($event) {
        return _vm.removeUserToGroup($event)
      },
      "change-groupId": function($event) {
        return _vm.changeGroupId($event)
      },
      "load-passable-time-setting": function($event) {
        return _vm.loadPassableTime($event)
      },
      "load-groupId": function($event) {
        return _vm.loadGroupId($event)
      },
      "search-user-group-passage": function($event) {
        return _vm.searchUserGroupPassage($event)
      },
      getDepartmentGroup: function($event) {
        return _vm.getDepartmentGroup($event)
      },
      "close-modal-add": function($event) {
        return _vm.closeModalAdd($event)
      },
      "close-modal-add-aff": function($event) {
        return _vm.closeModalAddAff($event)
      },
      "change-page-users": function($event) {
        return _vm.changePageUsers($event)
      },
      getDepartmentUserValid: function($event) {
        return _vm.getDepartmentUserValid($event)
      },
      loadingCircle: function($event) {
        return _vm.$emit("loadingCircle", $event)
      },
      textCircle: function($event) {
        return _vm.$emit("textCircle", $event)
      },
      "change-page-temporary": function($event) {
        return _vm.changePageTemporary($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }