import _Vue from 'vue';
import router from './router';
import store from './store';
// @ts-ignore
import jwtDecode from 'jwt-decode';
import { Auth } from 'aws-amplify';
import CognitoUserSession from 'aws-amplify';
import * as Config from './config';
import main from './main';
import axios from 'axios';

// Auth Plugin
// (see https://vuejs.org/v2/guide/plugins.html for more info on Vue.js plugins)
// Handles login and token authentication using OAuth2.
interface Response {
  status: any;
  body: {
    message: any;
  };
}

export default {

  /**
   * Install the Auth class.
   *
   * Creates a Vue-resource http interceptor to handle automatically adding auth headers
   * and refreshing tokens. Then attaches this object to the global Vue (as Vue.auth).
   *
   * @param {Object} Vue The global Vue.
   * @param {Object} options Any options we want to have in our plugin.
   * @return {void}
   */
  install(vue: any, options: any): void {
    const http = axios.create({});
    http.interceptors.request.use((config: any) => {
      const token = store.state.auth.accessToken;
      if (token && !config.headers.Authorization) {
        this._setAuthHeader(config);
      }
      return config;
    });
    http.interceptors.response.use(undefined, (error) => {
      if (this._isInvalidToken(error.response)) {
        return this._refreshToken()
          .then(() => this._setAuthHeader(error.config))
          .then(() => http.request(error.config));
      } else {
        return Promise.reject(error);
      }
    });
    vue.prototype.$axios = http;

    // 以下、vue-resourceの設定。vue.$httpの使用廃止後、削除する
    // またvue-resourceの設定に携わる他関数も削除する
    vue.http.interceptors.push((request: any, next: any) => {
      const token = store.state.auth.accessToken;
      const hasAuthHeader = request.headers.has('Authorization');
      if (token && !hasAuthHeader) {
        this.setAuthHeader(request);
      }
      next((response: any) => {
        if (this.isInvalidToken(response)) {
          return this.refreshToken(request).then(() => {
            return vue.http(request).then((data: any) => {
              return data;
            });
          });
        }
      });
    });
    vue.prototype.$auth = vue.auth = this;
  },
  // install(vue: any, options: any): void {
  //   vue.http.interceptors.push((request: any, next: any) => {
  //     const token = store.state.auth.accessToken;
  //     const hasAuthHeader = request.headers.has('Authorization');
  //     if (token && !hasAuthHeader) {
  //       this.setAuthHeader(request);
  //     }
  //     next((response: any) => {
  //       if (this.isInvalidToken(response)) {
  //         return this.refreshToken(request).then(() => {
  //           return vue.http(request).then((data: any) => {
  //             return data;
  //           });
  //         });
  //       }
  //     });
  //   });
  //   // vue.http.interceptors.push((request: any, next: any) => {
  //   //   next((response: any) => {
  //   //     console.log('reponse', response);
  //   //     if (response.status === 401 && response.body.message === 'The incoming token has expired') {
  //   //       return this.refreshToken(response).then( () => {
  //   //         return vue.http(request).then((data: any) => {
  //   //           return data;
  //   //         });
  //   //       });
  //   //     }
  //   //   });
  //   // });

  //   vue.prototype.$auth = vue.auth = this;
  // },
  // install(vue: any, options: any) {
  //   const http = axios.create({});
  //   http.interceptors.request.use((config) => {
  //     const token = store.state.auth.accessToken;

  //     if (token && !config.headers.Authorization) {
  //       this.setAuthHeader(config);
  //     }
  //     return config;
  //   });

  //   http.interceptors.response.use(undefined, (error: any) => {
  //     if (this.isInvalidToken(error.response)) {
  //       console.log('error', error);
  //       return this.refreshToken()
  //         .then(() => this.setAuthHeader(error.config))
  //         .then(() => http.request(error.config));
  //     } else {
  //       console.log('AAA');
  //       return Promise.reject(error);
  //     }
  //   });

  //   vue.prototype.$auth = vue.auth = this;
  //   // vue.prototype.$http = http;
  // },
  /**
   * Logout
   *
   * Clear all data in our Vuex store (which resets logged-in status) and redirect back
   * to login form.
   *
   * @return {void}
   */
  logout(): void {
    localStorage.removeItem('userProfileId');
    localStorage.removeItem('ownerId');
    localStorage.removeItem('isAdmin');
    store.commit('CLEAR_ALL_DATA');
    localStorage.clear();
    Auth.signOut().then(() => router.push({ path: Config.LOGIN }));
  },
  /**
   * Set the Authorization header on a Vue-resource Request.
   *
   * @param {Request} request The Vue-Resource Request instance to set the header on.
   * @return {void}
   */
  setAuthHeader(request: any): void {
    // このLineがないとAPIを叩くことができない。
    request.headers.set('Authorization', 'Basic Og==');
    request.headers.set('x-id-token', store.state.auth.accessToken.toString());
  },
  _setAuthHeader(request: any) {
    // このLineがないとAPIを叩くことができない。
    request.headers.Authorization = 'Basic Og==';
    request.headers['x-id-token'] = store.state.auth.accessToken;
  },

  /**
   * Retry the original request.
   *
   * Let's retry the user's original target request that had recieved a invalid token response
   * (which we fixed with a token refresh).
   *
   * @param {Request} request The Vue-resource Request instance to use to repeat an http call.
   * @return {Promise}
   */
  _retry(request: any) {
    this.setAuthHeader(request);
    console.log('request', request);
    // return main.$http(request).then((data: any) => {
    //   return data;
    // }).then(null, (err) => {
    //     console.log('err', err);
    //     return err;
    //   });
    // return this.http(request).then((data) => {
    //   return data;
    // });
    return main.$http(request)
      .then((response) => {
        console.log(response);
        return response;
      })
      .then(null, (err) => {
        console.log('err', err);
        return err;
      });
  },

  /**
   * Refresh the access token
   *
   * Make an ajax call to the OAuth2 server to refresh the access token (using our refresh token).
   *
   * @private
   * @param {Request} request Vue-resource Request instance, the original request that we'll retry.
   * @return {Promise}
   */

  refreshToken(request: any) {
    return new Promise((resolve, reject) => {
      let check = false;
      Auth.currentSession()
        .then((user) => {
          const idToken = user.getIdToken();
          const refreshToken = user.getRefreshToken();
          // token
          console.log('Session_user: ', user);
          console.log('Session_idToken: ', idToken);
          console.log('SessionrefreshToken: ', refreshToken);
          // user info
          const tk = jwtDecode(user.getAccessToken().getJwtToken());
          console.log('Session_token_decode: ', tk);
          console.log('username: ', tk.username);
          localStorage.setItem('logRefreshToken', 'Refresh the access token is success');
          this._storeToken(idToken, refreshToken);
          this.setAuthHeader(request);
          check = true;
          resolve(null);
        })
        .catch((error) => {
          return new Promise((resolves, _) => {
            console.error('Unable to refresh Token ', error);
            localStorage.removeItem('userProfileId');
            localStorage.removeItem('ownerId');
            localStorage.removeItem('isAdmin');
            store.commit('CLEAR_ALL_DATA');
            Auth.signOut().then(() => router.push({ path: Config.LOGIN }));
          }).then(() => {
            location.reload();
          }).then(() => {
            // if (check) {
            //   return this._retry(request);
            // }
          });
          resolve(null);
        });
    });
  },
  _refreshToken() {
    return Auth.currentSession()
      .then((result) => this.__storeToken(result))
      .catch((errorResponse) => {
        this.logout();
      });
  },
//     // var params = { 'refreshToken': store.state.auth.refreshToken }
//     // return Vue.http.post(Config.REFRESH_TOKEN_API, params)
//     //   .then((result) => {
//     //     this._storeToken(result)
//     //     return this._retry(request)
//     //   })
//     //   .catch((errorResponse) => {
//     //     this.logout()
//     //   })
//   },

  /**
   * Store tokens
   *
   * Update the Vuex store with the access/refresh tokens received from the response from
   * the Oauth2 server.
   *
   * @private
   * @param {Response} response Vue-resource Response instance from an OAuth2 server.
   *      that contains our tokens.
   * @return {void}
   */
  // _storeTokenChange(idToken: any, refreshToken: any) {
  //   return new Promise((resolve, reject) => {
  //     const auth = store.state.auth;
  //     const user = store.state.user;
  //     auth.isLoginChallenge = false;
  //     auth.isLoggedIn = true;
  //     auth.accessToken = idToken;
  //     auth.refreshToken = refreshToken;
  //     store.commit('UPDATE_AUTH', auth);
  //     store.commit('UPDATE_USER', user);
  //     resolve('changeTokenSuccess');
  //   });
  // },
  _storeToken(idToken: any, refreshToken: any) {
    const auth = store.state.auth;
    const user = store.state.user;
    auth.isLoginChallenge = false;
    auth.isLoggedIn = true;
    auth.accessToken = idToken.jwtToken;
    auth.refreshToken = refreshToken.token;
    console.log('idToken.jwtToken', idToken.jwtToken);
    store.commit('UPDATE_AUTH', auth);
    store.commit('UPDATE_USER', user);
  },
  __storeToken(response: any) {
    const auth = store.state.auth;
    const user = store.state.user;
    auth.isLoginChallenge = false;
    auth.isLoggedIn = true;
    auth.accessToken = response.idToken.jwtToken;
    auth.refreshToken = response.refreshToken.token;
    store.commit('UPDATE_AUTH', auth);
    store.commit('UPDATE_USER', user);
  },

  _getProfile() {
    main.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(localStorage.userProfileId))
      .then( (response: any) => {
        localStorage.setItem('ownerId', response.body.users[0].owner.ownerId);
        localStorage.setItem('isAdmin', response.body.users[0].isAdmin);
        window.location.href = window.location.origin + '#/dashboard';
        // router.push({ name: 'dashboard' });
      })
      .then( null, (err: any) => console.log('err: ', err));
  },
  /**
   * Check if the Vue-resource Response is an invalid token response.
   *
   * @private
   * @param {Response} response The Vue-resource Response instance received from an http call.
   * @return {boolean}
   */
  isInvalidToken(response: Response) {
    const status = response.status;
    const error = response.body ? response.body.message : 'response body nothing.';
    return (status === 401 && (error === 'The incoming token has expired'));
    // return (status === 401);
  },
  _isInvalidToken(response: any) {
    const status = response.status;
    const error = response.data ? response.data.message : 'response data nothing.';
    return (status === 401 && (error === 'The incoming token has expired'));
  },
  _setAuthorToken(user: any) {
    return new Promise((resolve, reject) => {
      const authStore = store.state.auth;
      const userStore = store.state.user;
      const idToken = user.signInUserSession.idToken.jwtToken;
      authStore.isLoggedIn = true;
      authStore.isAdmin = null;
      authStore.isLoginChallenge = false;
      authStore.accessToken = idToken;
      authStore.refreshToken = user.signInUserSession.refreshToken.token;
      store.commit('UPDATE_AUTH', authStore);
      store.commit('UPDATE_USER', userStore);
      const tk = jwtDecode(idToken);
      localStorage.setItem('userProfileId', tk.alligate_user_id);
      resolve(true);
    });
  },
  saveCurrentAuthenticatedUser() {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (user) {
            return this._setAuthorToken(user)
              .then(() => {
                // this._getProfile();
                window.location.href = window.location.origin + '#/dashboard';
                resolve(true);
              });
          } else {
            this.errorAuthenticatedUser();
            Promise.resolve();
          }
        })
        .catch((error) => {
          this.errorAuthenticatedUser();
          resolve(true);
        });
    });
  },
  errorAuthenticatedUser() {
    localStorage.clear();
    store.commit('CLEAR_ALL_DATA');
    window.location.href = window.location.origin + '#/login?status=false';
  },
  showTime(data: any) {
    let text = data.startTime + '〜' + data.endTime;
    if (data.startTime === '99:99' &&  data.endTime === '99:99') {
      text = '終日不可';
    } else if (data.startTime === '00:00' &&  data.endTime === '00:00') {
      text = '終日可能';
    }
    return text;
  },
};
