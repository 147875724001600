var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-container",
        { staticClass: "pt-0", staticStyle: { position: "relative" } },
        [
          _c("ModalLoading", { attrs: { loading: _vm.loadingChild } }),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.groupsAddFail.length > 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.Message.ERROR_GATE_EDIT.replace(
                          "GROUP_NAME",
                          _vm.groupsAddFail.toString()
                        )
                      )
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.successMessageLogs !== "" &&
          _vm.groupsAddFail.length === 0 &&
          _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("gate-detail-table", {
            attrs: {
              loading: _vm.loading,
              gateDetail: _vm.gateDetail,
              groupList: _vm.groupList,
              groupId: _vm.groupId,
              showConfirm: _vm.showConfirm,
              dataUnlockingTime: _vm.dataUnlockingTime,
              successMessageModal: _vm.successMessageModal,
              errorMessageModal: _vm.errorMessageModal,
              dayTypes: _vm.dayTypes,
              isFinishEditGate: _vm.isFinishEditGate,
              authority: _vm.authority,
              isRemoteUnlockValid: _vm.isRemoteUnlockValid,
              isAdminOfRemoteUnlock: _vm.isAdminOfRemoteUnlock
            },
            on: {
              clickUpdateGate: function($event) {
                return _vm.$emit("clickUpdateGate", $event)
              },
              clickConfirm: function($event) {
                return _vm.$emit("clickConfirm", $event)
              },
              closeModalConfirm: function($event) {
                return _vm.$emit("closeModalConfirm", $event)
              },
              clickRemoteUnlock: function($event) {
                return _vm.$emit("clickRemoteUnlock", $event)
              },
              clickEditGate: function($event) {
                _vm.$emit("clickEditGate", $event), (_vm.isReloadData = false)
              },
              clickGroupDetail: function($event) {
                return _vm.$emit("clickGroupDetail", $event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "ゲート編集",
          "no-footer": "",
          smallScreen: "800px",
          className: "common-padding"
        },
        on: { close: _vm.deleteBridgeProperty },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm.isGateEditModalShow
                  ? _c("GateEditPage", {
                      on: {
                        updateGate: function($event) {
                          return _vm.$emit("updateGate", $event)
                        },
                        getReload: function($event) {
                          return _vm.getReload($event)
                        },
                        deleteBridgeProperty: _vm.deleteBridgeProperty,
                        loading: function($event) {
                          return _vm.$emit("loading-from-child", $event)
                        },
                        "load-gates": function($event) {
                          return _vm.$emit("load-gates", $event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isGateEditModalShow,
          callback: function($$v) {
            _vm.isGateEditModalShow = $$v
          },
          expression: "isGateEditModalShow"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }