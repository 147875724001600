var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { class: _vm.isPage === "group" ? "pb-0" : "" }, [
    _c(
      "div",
      { staticClass: "allig-reflection-card-table" },
      [
        _c(
          "div",
          { staticClass: "round-action" },
          [
            _c(
              "v-row",
              { staticClass: "btn-div", attrs: { justify: "end" } },
              [
                _c("v-col", { attrs: { xl: "3", lg: "3", sm: "3" } }, [
                  _c("h3", { staticClass: "mt-3" }, [_vm._v("通行可能な所属")])
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-row",
                      { staticClass: "btn-div", attrs: { justify: "end" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center mr-10 " },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "btn-add pt-0",
                                attrs: { cols: "12", lg: "6", sm: "6" }
                              },
                              [
                                _vm.authority.isAdmin === true ||
                                _vm.isAdminOfGroupPassages === true
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "btn-second",
                                        attrs: {
                                          outlined: "",
                                          disabled:
                                            _vm.cardSelected.length === 0
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.isConfirm = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    チェックした所属を削除する\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "btn-add pt-0",
                                attrs: { cols: "12", lg: "6", sm: "6" }
                              },
                              [
                                _vm.authority.isAdmin === true ||
                                _vm.isAdminOfGroupPassages === true
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "btn-primary",
                                        attrs: { outlined: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.loadShowAff()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    他の所属を追加する\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "round-data data-aff pt-0",
            class: _vm.isPage === "group" ? "pb-10" : ""
          },
          [
            _c("v-data-table", {
              staticClass: "border-table scroll-table-aff table-no-bg",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                items: _vm.dataList,
                "items-per-page": _vm.perPage,
                "item-key": "relationId",
                "no-data-text": "データーはありません",
                "loading-text": "データを読み込中です",
                loading: _vm.loading,
                "hide-default-footer": "",
                "hide-default-header": "",
                "show-select":
                  _vm.authority.isAdmin === true ||
                  _vm.isAdminOfGroupPassages === true
                    ? true
                    : false,
                "custom-sort": _vm.customSort
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.department !== undefined &&
                        _vm.authority.isAdmin === true
                          ? _c("text-link", {
                              staticClass: "item-user-name",
                              attrs: {
                                text:
                                  item.department.name +
                                  "(" +
                                  (item.department.userCount || 0) +
                                  ")"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.loadDetail(item.department)
                                }
                              }
                            })
                          : item.department
                          ? _c(
                              "span",
                              { staticClass: "item-user-name no-hover" },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.department.name +
                                      "(" +
                                      (item.department.userCount || 0) +
                                      ")"
                                  ) + "\n      "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.cardSelected,
                callback: function($$v) {
                  _vm.cardSelected = $$v
                },
                expression: "cardSelected"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("v-spacer"),
        _vm._v(" "),
        _c("Modal", {
          attrs: {
            title: "他の所属を追加",
            "no-footer": "",
            smallScreen: "800px",
            className: "common-padding"
          },
          on: {
            close: function($event) {
              _vm.isShowAff = false
              _vm.closeModal(
                _vm.dataRelationDepartmentGroup.successItem > 0 ? true : false
              )
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-body",
              fn: function() {
                return [
                  _c("AffiliationListTable", {
                    attrs: {
                      authority: _vm.authority,
                      isPage: "group-aff",
                      perPage: 25,
                      cardTotalCount: _vm.optionDepartments.total,
                      page: _vm.optionDepartments.page,
                      loading: _vm.loading,
                      loadingAff: _vm.loadingAff,
                      relationsDepartmentGroup: _vm.dataList,
                      dataList: _vm.departments
                    },
                    on: {
                      "change-page": function($event) {
                        return _vm.$emit("change-page-aff", $event)
                      },
                      "add-aff-to-group": function($event) {
                        return _vm.addAff($event)
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isShowAff,
            callback: function($$v) {
              _vm.isShowAff = $$v
            },
            expression: "isShowAff"
          }
        }),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "600", persistent: "" },
            model: {
              value: _vm.showProgress,
              callback: function($$v) {
                _vm.showProgress = $$v
              },
              expression: "showProgress"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "background-color-green" }, [
                  _vm._v(
                    _vm._s(
                      _vm.type === "del"
                        ? "チェックした所属を削除"
                        : "他の所属を追加"
                    )
                  )
                ]),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c("v-row", {
                  staticStyle: { height: "5px" },
                  attrs: { "no-gutters": "" }
                }),
                _vm._v(" "),
                _vm.dataRelationDepartmentGroup.total >= 0
                  ? _c(
                      "v-card-text",
                      { staticClass: "pa-4" },
                      [
                        _c(
                          "v-progress-linear",
                          {
                            staticStyle: { "pointer-events": "none" },
                            attrs: {
                              height: "25",
                              color: "green darken-1",
                              striped: ""
                            },
                            model: {
                              value:
                                _vm.dataRelationDepartmentGroup.progressLoad,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.dataRelationDepartmentGroup,
                                  "progressLoad",
                                  $$v
                                )
                              },
                              expression:
                                "dataRelationDepartmentGroup.progressLoad"
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  Math.round(
                                    _vm.dataRelationDepartmentGroup.progressLoad
                                  )
                                ) + "%"
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "success-label" }, [
                          _vm._v(
                            _vm._s(_vm.dataRelationDepartmentGroup.total) +
                              "件中" +
                              _vm._s(
                                _vm.dataRelationDepartmentGroup.successItem
                              )
                          ),
                          _vm.type === "add"
                            ? _c("span", [_vm._v("件を追加しました。")])
                            : _c("span", [_vm._v("件を削除しました。")])
                        ]),
                        _vm._v(" "),
                        _vm.dataRelationDepartmentGroup.errorMessage.length > 0
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "error-box" },
                                [
                                  _vm._l(
                                    _vm.dataRelationDepartmentGroup
                                      .errorMessage,
                                    function(item) {
                                      return [
                                        _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataRelationDepartmentGroup.isFinish
                  ? _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-2", attrs: { justify: "end" } },
                          [
                            _vm.type === "del"
                              ? _c("success-button", {
                                  attrs: { text: "OK" },
                                  on: {
                                    click: function($event) {
                                      ;(_vm.showProgress = false),
                                        (_vm.cardSelected = []),
                                        _vm.closeModal(
                                          _vm.dataRelationDepartmentGroup
                                            .successItem > 0
                                            ? true
                                            : false
                                        )
                                    }
                                  }
                                })
                              : _c("success-button", {
                                  attrs: { text: "OK" },
                                  on: {
                                    click: function($event) {
                                      ;(_vm.showProgress = false),
                                        _vm.closeModalAdd(
                                          _vm.dataRelationDepartmentGroup
                                            .successItem > 0
                                            ? true
                                            : false
                                        )
                                    }
                                  }
                                })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "600", persistent: "" },
            model: {
              value: _vm.isConfirm,
              callback: function($$v) {
                _vm.isConfirm = $$v
              },
              expression: "isConfirm"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("ModalTitle", {
                  attrs: { title: "チェックした所属を削除" },
                  on: {
                    click: function($event) {
                      _vm.isConfirm = false
                    }
                  }
                }),
                _vm._v(" "),
                _c("v-card-text", { staticClass: "pt-0 pb-5 text-progress" }, [
                  _vm._v("所属を削除します。")
                ]),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { justify: "end" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-primary",
                            attrs: { outlined: "" },
                            on: {
                              click: function($event) {
                                return _vm.removeAff(_vm.cardSelected)
                              }
                            }
                          },
                          [_vm._v("\n              削除\n            ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.isShow
          ? _c("AffiliationListModalPage", {
              attrs: { isPage: "detail", department: _vm.department },
              on: {
                loading: function($event) {
                  return _vm.$emit("loading-from-child", $event)
                },
                getReload: function($event) {
                  return _vm.getReload($event)
                },
                "close-page-modal": function($event) {
                  return _vm.closeModalAff(_vm.isReloadData)
                },
                "get-list-user": function($event) {
                  return _vm.$emit("get-list-user")
                },
                "back-group-detail": function($event) {
                  _vm.$emit("back-group-detail", $event)
                  _vm.isShow = false
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }