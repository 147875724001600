var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-template", {
    attrs: {
      dataModal: _vm.dataModal,
      isAction: _vm.isAction,
      dataProgress: _vm.dataProgress,
      isPage: _vm.isPage,
      authority: _vm.authority,
      userId: _vm.userId,
      userName: _vm.userName,
      departmentId: _vm.departmentId,
      groupPassages: _vm.groupPassages,
      dataAdded: _vm.dataAdded,
      loadingParent: _vm.loadingParent,
      errorName: _vm.errorName
    },
    on: {
      "change-page-group": function($event) {
        return _vm.changePageGroup($event)
      },
      "change-per-page-group": function($event) {
        return _vm.changePerPageGroupAll($event)
      },
      "add-group-user": function($event) {
        return _vm.addGroupToUser($event)
      },
      "remove-group-user": function($event) {
        return _vm.delGroupUser($event)
      },
      "close-modal-group": function($event) {
        return _vm.closeModal()
      },
      "select-Ids": function($event) {
        return _vm.$emit("select-Ids", $event)
      },
      "add-group-pass": function($event) {
        return _vm.addGroupPass($event)
      },
      "load-all-group": function($event) {
        return _vm.loadListGroups({ perPage: 25, page: 1 })
      },
      "change-page-user": function($event) {
        return _vm.changePageUser($event)
      },
      "change-per-page-user": function($event) {
        return _vm.changePerPageUser($event)
      },
      "add-user-to-group": function($event) {
        return _vm.addUserToGroup($event)
      },
      "add-user-to-aff": function($event) {
        return _vm.addUserToAff($event)
      },
      "get-all-group": function($event) {
        return _vm.getAllGroups($event)
      },
      "select-data-add": function($event) {
        return _vm.$emit("select-data-add", $event)
      },
      "get-data-add": function($event) {
        return _vm.getDataAdd($event)
      },
      "search-all-user": function($event) {
        return _vm.searchAllUser($event)
      },
      "affiliation-add-item": function($event) {
        return _vm.$emit("affiliation-add-item-direct", $event)
      },
      "close-popup": function($event) {
        return _vm.$emit("close-popup", $event)
      },
      "remove-group-in-edit": function($event) {
        return _vm.removeGroupInEdit($event)
      },
      "add-group-in-edit": function($event) {
        return _vm.addGroupInEdit($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }