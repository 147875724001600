



































































import {Vue } from 'vue-property-decorator';
import CardListTemplate from '@/components/templates/CardListTemplate/CardListTemplate.vue';
import * as Config from '@/config';
import moment from 'moment';
import { CardDetail, UserRegister } from '@/types/alligate';
import AlligateUtils from '@/utils/alligate';
const countChangeExport = 2;
let countChange = 2;
export interface DataType {
  isGetDataList: boolean;
  dataCards: CardDetail[];
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  cardTotalCount: number;
  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;

  // CSV
  showProgressExportCSV: boolean;
  errorMessageCsvXlsx: string;
  // Excel
  showModalImport: boolean;
  showProgressImport: boolean;
  progressCsvXlsx: number;
  isShowViewCSV: boolean;
  successItemImport: number;
  totalDataRowImport: number;
  percentProcess: number;
  errorMessageImport: any;
  isFinishProcess: boolean;
  isLoading: boolean;
  UserSheetImportData: any;
  groupGateArray: any;
  dataImport: any;
  sheetUserName: string;
  userIdArray: any;
  sheetGroupName: string;
  rowinErrDialog: number;
  maxPagePcode: number;
  exportDataCSV: any;
  userInGroupMx: any;
  successItemSheet2: number;
  totalDataRowSheet2: number;
  progressSheet2: number;
  percentProcessSheet2: number;

  showProgress: boolean;
  errorMessageProcess: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  progress: number;
  isFinishProcessEnable: boolean;
  typeAction: number;
  dataAction: any;
  showConfirm: boolean;
  isHasLowerCaseCardNumberCSV: boolean;
  isHasLowerCaseCardNumberExcel: boolean;
  isExportUserInfoCSV: boolean;
  exportUserData: any;
  maxPageUser: number;
  maxPageGroup: number;
  exportGroupData: any;
  countColumn: number;
  exportGroupPassages: any;
  typeConfirm: any;
  showModalProcess: any;
  showModalConfirmEmail: any;
  isReload: boolean;
}
export default Vue.extend({
  name: 'CardListPage',
  components: {
    CardListTemplate,
  },
  data: (): DataType => ({
    isReload: false,
    isGetDataList: false,
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['pCodeId'],
    keyword: '',
    cardTotalCount: 0,
    dataCards: [],
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',

    // CSV
    exportDataCSV: [],
    showProgressExportCSV: false,
    errorMessageCsvXlsx: '',
    // Excel
    progressCsvXlsx: 0,
    showModalImport: false,
    isShowViewCSV: false,
    showProgressImport: false,
    successItemImport: 0,
    totalDataRowImport: 0,
    percentProcess: 0,
    errorMessageImport: [],
    isFinishProcess: false,
    isLoading: false,
    UserSheetImportData: [],
    groupGateArray: [],
    dataImport: [],
    sheetUserName: '',
    sheetGroupName: '',
    rowinErrDialog: 0,
    maxPagePcode: 1,
    userIdArray: [],
    userInGroupMx: [],
    successItemSheet2: 0,
    totalDataRowSheet2: 0,
    progressSheet2: 0,
    percentProcessSheet2: 0,

    // Disable, enable Pcodes
    showProgress: false,
    errorMessageProcess: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    progress: 0,
    isFinishProcessEnable: false,
    typeAction: 0,
    dataAction: [],
    showConfirm: false,
    isHasLowerCaseCardNumberCSV: false,
    isHasLowerCaseCardNumberExcel: false,
    isExportUserInfoCSV: false,
    exportUserData: [],
    maxPageUser: 1,
    maxPageGroup: 1,
    exportGroupData: [],
    countColumn: 1,
    exportGroupPassages: [],
    typeConfirm: 0,
    showModalProcess: false,
    showModalConfirmEmail: false,
  }),

  watch: {
    isLoading(value) {
      this.$emit('loading', value);
    },
    loading(value) {
      this.$emit('loading', value);
    },
  },
  created() {
    if (typeof localStorage.isVaildDataCard === 'undefined') {
      localStorage.setItem('isVaildDataCard', 'true');
    }
    if (typeof localStorage.cardSortKey === 'undefined') {
      localStorage.setItem('cardSortKey', 'registered');
    }
    if (typeof localStorage.cardSortName === 'undefined') {
      localStorage.setItem('cardSortName', 'desc');
    }
    if (typeof localStorage.pageSizeCard === 'undefined') {
      localStorage.setItem('pageSizeCard', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeCard);
    }
    this.getListCards();
  },
  mounted() {
    this.$emit('activeMenu', 'cards');
  },
  methods: {
    ShowOnlyIsVaild(data: any) {
      localStorage.setItem('isVaildDataCard', data.toString());
      this.getListCards();
    },
    clickAddCard() {
      this.$router.push({ path: Config.CARD_ADD});
    },
    clickCardDetail(value: any) {
      this.$router.push({ path: Config.CARD_DETAIL, query: {pCodeId: value}});
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeCard', value);
      this.getListCards();
    },
    changePage(value: any): any {
      this.currPage = value;
      this.getListCards();
    },
    searchCard(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      this.getListCards();
    },
    getListCards(): any {
      this.isGetDataList = true;
      this.isFinishProcessEnable = false;
      let apiLink = Config.CARD_LIST_API + '?limit=' + this.perPage +
        '&page=' + this.currPage + '&sortKey=' +
        localStorage.cardSortName + ':' + localStorage.cardSortKey;
      if (this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      if (localStorage.isVaildDataCard === 'true') {
        apiLink += '&isValid=true';
      }
      apiLink += '&embed=user';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.resetData();
          this.isFinishProcessEnable = true;
          this.isGetDataList = false;
          if (!this.isEmpty(response.body.pCodes)) {
            this.dataCards = response.body.pCodes;
            this.dataCards.forEach((element: any, key: any) => {
              if (element.user) {
                this.dataCards[key].userName = element.user.name;
              } else {
                this.dataCards[key].userName = '';
              }
            });
            this.cardTotalCount = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
            console.log('currPage = ', this.currPage);
          } else {
            this.cardTotalCount = 0;
            this.currPage = 1;
          }
        })
        .then( null, (err: any) => {
          this.resetData();
          this.isFinishProcessEnable = true;
          this.isGetDataList = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
          }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataCards = [];
      this.errorMessageLogs = '';
      // this.cardTotalCount = 0;
      // this.page = 0;
    },
    // CSV
    resetAllValue() {
      // value of import excel and CSV
      this.isLoading = false;
      this.progressCsvXlsx = 0;
      this.successItemImport = 0;
      this.isFinishProcess = false;
      this.totalDataRowImport = 0;
      this.errorMessageImport = [];
      this.rowinErrDialog = 0;
      this.showProgressImport = false;
      this.errorMessageCsvXlsx = '';
      this.dataImport = [];
      this.exportDataCSV = [];
      this.maxPagePcode = 1;
      this.showProgressExportCSV = false;
    },
    clickExportCSV(isCheckDataCSV: any) {
      this.resetAllValue();
      this.showProgressExportCSV = true;
      setTimeout(() => {
        this.getDataForPcode(isCheckDataCSV);
      }, 200);
    },
    async getDataForPcode(isCheckDataCSV: any) {
      if (isCheckDataCSV) {
        const vm = this;
        let page = 1;
        let isContinueGetData: boolean = true;
        while (isContinueGetData) {
          await this.getPCodeExport(page);
          this.progressCsvXlsx += (100 / this.maxPagePcode);
          page++;
          if (page <= this.maxPagePcode) {
            isContinueGetData = true;
          } else {
            isContinueGetData = false;
          }
        }
      } else {
        this.progressCsvXlsx = 100;
      }
      this.downloadCSV();
      this.isFinishProcess = true;
    },

    getPCodeExport(page: number) {
      const vm = this;
      return new Promise((resolve) => {
        this.$http.get(Config.CARD_LIST_API + '?limit=1000&page=' + page)
        .then((response: any) => {
          for (const item of  response.body.pCodes) {
            vm.exportDataCSV.push({
              'ユーザーID': item.userId,
              '個人コードID': item.pCodeId,
              'コード種別（変更不可）': item.type,
              'ステータス(1:有効.0:無効)': vm.converToBit(item.isValid),
              '登録日（読み取り専用）': vm.converToDate(item.registered),
              '更新日（読み取り専用）': vm.converToDate(item.updated),
            });
          }
          if (response.body.maxRecords > 0) {
              vm.maxPagePcode = Math.ceil((response.body.maxRecords) / 1000);
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          console.log(err);
          resolve(false);
        });
      });
    },
    downloadCSV() {
      const saveAs = require('file-saver');
      let filename;
      if (this.exportDataCSV.length === 0) {
        this.exportDataCSV.push({
            'ユーザーID': '',
            '個人コードID': '',
            'コード種別（変更不可）': '',
            'ステータス(1:有効.0:無効)': '',
            '登録日（読み取り専用）': '',
            '更新日（読み取り専用）': '',
          });
      }
      const csv = this.convertArrayOfObjectsToCSV({
        data: this.exportDataCSV,
      });
      filename = 'pcode-data.csv';
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([ bom, csv ], {type: 'text/csv;charset=utf-8;'});
      // ダウンロード実行
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        saveAs(blob, filename);
      } else {
        console.log('window.navigator.msSaveOrOpenBlob', window.navigator.msSaveOrOpenBlob);
        if (window.navigator.msSaveOrOpenBlob !== undefined) {
          // IEの場合
          navigator.msSaveBlob(blob, filename);
        } else {
          // IE以外(Chrome, Firefox)
          // const downloadLink = $('<a></a>');
          const downloadLink =  document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          // $('body').append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      }
    },

    closeModalProcessCSV() {
      this.resetAllValue();
    },

    clickImportCSV(files: any) {
      this.resetAllValue();
      this.isLoading = true;
      setTimeout(() => {
        this.importCSV(files);
      }, 200);
    },

    importCSV(data: any): void {
      const files = data.files;
      const vm = this;
      if (files !== null && files !== undefined && files.length !== 0) {
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const lineArr = read.split('\n');
          const itemArr = [];
          let isHasLowerCase = false;
          this.isLoading = false;
          this.showProgressImport = true;
          for (let i = 0; i < lineArr.length; i++) {
            itemArr[i] = lineArr[i].split(',');
          }
          if (itemArr.length > 1) {
            for (let k = 1; k < itemArr.length; k++) {
              if (itemArr[k].length === 6 && itemArr[k][1].length > 0 &&
                    itemArr[k][2].length > 0 && itemArr[k][3].length > 0) {
                if (itemArr[k][1].toUpperCase() !== itemArr[k][1]) {
                  isHasLowerCase = true;
                }
                vm.dataImport.push(itemArr[k]);
              }
            }
            if (vm.dataImport.length > 0) {
              this.totalDataRowImport = this.dataImport.length;
              this.percentProcess = (100 / (this.totalDataRowImport));
              this.isHasLowerCaseCardNumberCSV = isHasLowerCase;
              if (!this.isHasLowerCaseCardNumberCSV) {
                // Send data
                vm.excuteImportCsv();
              } else {
                this.showProgressImport = false;
              }
            } else {
              vm.isFinishProcess = true;
              vm.errorMessageCsvXlsx = '不正なファイルです。正しいファイルを選択しなおしてください。';
            }
          } else {
            vm.isFinishProcess = true;
            vm.errorMessageCsvXlsx = '不正なファイルです。正しいファイルを選択しなおしてください。';
          }
        };
        reader.onerror = (): void => {
          vm.isLoading = false;
          vm.showProgressImport = true;
          vm.errorMessageCsvXlsx = '選択されているファイルの読み込みに失敗しました。<br>ブラウザの更新、もしくは別ファイルを選択してから<br>再度アップロードしたいファイルを選択してください。';
          vm.isFinishProcess = true;
        };
        reader.readAsText(files, 'Shift_JIS');
      } else {
        vm.isLoading = false;
        vm.showProgressImport = true;
        vm.errorMessageCsvXlsx = 'CSVのファイルを選択してください。';
        vm.isFinishProcess = true;
      }
    },
    async excuteImportCsv() {
      this.closeDialogCheckCardCSV();
      this.showProgressImport = true;
      let line = 1;
      for (const item of this.dataImport) {
        if (this.dataImport.length === 0) {
          break;
        }
        let resPcode = await this.pCodeAddExe(item[0], item[2], item[1]
          , this.convertStringToBoolean(item[3], false), line += 1);
        if (resPcode === 2) {
          // send Edit Pcode
          resPcode = await this.pCodeEditCSV(item[0], item[1], this.convertStringToBoolean(item[3], false), line);
        }
        if (resPcode === 1 && this.dataImport.length > 0) {
          this.successItemImport++;
        }
        if (this.dataImport.length > 0) {
          this.progressCsvXlsx += this.percentProcess;
        }
      }
      this.isFinishProcess = true;
    },
    convertArrayOfObjectsToCSV(args: any) {
      let result: any;
      let ctr: number;
      let keys: any[];
      let columnDelimiter: string|undefined;
      let lineDelimiter: any;
      let data: any;
      data = args.data || null;
      if (data == null || !data.length) {
        return null;
      }
      columnDelimiter = args.columnDelimiter || ',';
      lineDelimiter = args.lineDelimiter || '\n';
      keys = Object.keys(data[0]);
      result = '';
      result += keys.join(columnDelimiter);
      result += lineDelimiter;
      data.forEach((item: { [x: string]: any; }) => {
        ctr = 0;
        keys.forEach((key: any) => {
          if (ctr > 0) {
            result += columnDelimiter;
          }
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      });
      return result;
    },

    // Excel
    clickExportExcel(check: any) {
      this.loading = true;
      this.exportUserData = [];
      this.exportGroupData = [];
      this.exportGroupPassages = [];
      if (check) {
        this.getDataForUserExcel().then(() => this.getDataForGroup(check)).then(() => this.downloadExcel()).then(() => {
          this.loading = false;
        });
      } else {
        this.getDataForGroup(check).then(() => this.downloadExcel()).then(() => {
          this.loading = false;
        });
      }
    },

    async downloadExcel() {
      const setPassword = '!@#' + Math.random().toString(36).substring(7) + Date.now();
      const dataGroup = this.exportGroupData;
      const dataUser = this.exportUserData;
      const dataGroupPassages = this.exportGroupPassages;
      const ExcelJS = require('excel4node');

      const wb = new ExcelJS.Workbook();
      const totalLine = dataUser.length > 0 ? dataUser.length + 2 : 1002;
      const options1 = {
        sheetFormat: {
          defaultColWidth: 13,
        },
      };
      const options2 = {
        sheetFormat: {
          defaultColWidth: 3,
        },
      };
      const options3 = {
        sheetProtection: {
          password: setPassword,
        },
        hidden: true,
      };
      const sheetNameExport = ['ユーザーとカードの設定', '通行権限グループとユーザーの紐づけ', '参照'];
      const ws1 = wb.addWorksheet(sheetNameExport[0], options1);
      const ws2 = wb.addWorksheet(sheetNameExport[1], options2);
      const ws3 = wb.addWorksheet(sheetNameExport[2], options3);
      const styleFormat = wb.createStyle({
        fill: {
          type: 'pattern',
          patternType: 'solid',
          bgColor: 'FFC008',
        },
        font: {
          color: '000000',
          name: 'MS PGothic',
        },
      });
      const style1 = wb.createStyle({
        alignment: {
          vertical: 'center',
          horizontal: 'left',
          wrapText: true,
        },
      });

      const styleBorder = wb.createStyle({
        font: {
          size: 11,
          name: 'MS PGothic',
        },
        border: {
          left: {
            style: 'thin',
            color: '000000',
          },
          right: {
            style: 'thin',
            color: '000000',
          },
          top: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          },
        },
      });
      const styleSheet3 = wb.createStyle({
        alignment: {
          vertical: 'center',
          horizontal: 'center',
        },
        font: {
          size: 11,
          name: 'MS PGothic',
        },
        border: {
          left: {
            style: 'thin',
            color: '000000',
          },
          right: {
            style: 'thin',
            color: '000000',
          },
          top: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          },
        },
      });
      const B2 = [
        {
          color: '000000',
          size: 11,
          value: 'ユーザーID \n',
          name: 'MS PGothic',
        },
        {
          value: '（重複不可）\n',
          bold: true,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: '（半角英数字）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
          bold: false,
        },
      ];
      const C2 = [
        'ログインパスワード',
        {
          value: '（新規ユーザー登録のみ有効）\n',
          bold: true,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: '（必須：半角英大文字+半角英小文字+半角数字の組み合わせ）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
          bold: false,
        },
      ];
      const M2 = [
        '個人コード1 \n',
        {
          value: '（テンキー:半角数字）（NFC:半角英数字）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
        },
      ];
      const O2 = [
        '個人コード2 \n',
        {
          value: '（テンキー:半角数字）（NFC:半角英数字）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
        },
      ];
      const Q2 = [
        '個人コード3 \n',
        {
          value: '（テンキー:半角数字）（NFC:半角英数字）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
        },
      ];
      const C1 = [
        '通行権限グループ一覧 ',
        {
          value: '※紐づけ設定　1：設定、0：解除',
          color: 'ff0000',
          name: 'MS PGothic',
        },
      ];
      const A1 = [
        '※赤セルの箇所は、',
        {
          value: '入力必須項目',
          bold: true,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: 'です \n',
          bold: false,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: '※青セルの箇所は、',
          bold: false,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: 'サービス加入者のみ入力可能',
          bold: true,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: 'です',
          bold: false,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
      ];
      // Sheet 3
      ws3.cell(2, 2)
        .string('通行権限グループの値')
        .style(styleSheet3);
      ws3.cell(3, 2)
        .string(' ')
        .style(styleSheet3);
      ws3.cell(4, 2)
        .number(0)
        .style(styleSheet3);
      ws3.cell(5, 2)
        .number(1)
        .style(styleSheet3);
      ws3.column(2).setWidth(22);
      // Sheet 1
      ws1.cell(1, 2, 1, 5, true)
        .string(A1)
        .style({
          alignment: {
            vertical: 'center',
            horizontal: 'left',
            wrapText: true,
          },
          font: {
            size: 11,
            name: 'MS PGothic',
          },
        });
      ws1.row(1).setHeight(31);
      ws1.cell(2, 2)
        .string(B2)
        .style(style1);
      ws1.cell(2, 3)
        .string(C2)
        .style(style1);
      ws1.cell(2, 4)
        .string('ユーザー名')
        .style(style1);
      ws1.cell(2, 5)
        .string('ふりがな')
        .style(style1);
      ws1.cell(2, 6)
        .string('メールアドレス')
        .style(style1);
      ws1.cell(2, 7)
        .string('組織管理権限(1:有効.0:無効)')
        .style(style1);
      // New
      ws1.cell(2, 8)
        .string('所属ID')
        .style(style1);
      ws1.cell(2, 9)
        .string('所属名')
        .style(style1);
      ws1.cell(2, 8 + countChangeExport)
        .string('ゲート設定権限ステータス(1:有効.0:無効)')
        .style(style1);
      ws1.cell(2, 9 + countChangeExport)
        .string('鍵共有権限ステータス(1:有効.0:無効)')
        .style(style1);
      ws1.cell(2, 10 + countChangeExport)
        .string('アプリへのログイン(1:有効.0:無効)')
        .style(style1);
      ws1.cell(2, 11 + countChangeExport)
        .string('SMS認証(1:有効.0:無効)')
        .style(style1);
      ws1.cell(2, 12 + countChangeExport)
        .string(
          [
            '電話番号 \n',
            {
              value: '（-(ハイフン)無しの携帯電話番号）',
              size: 11,
              color: 'ff0000',
              name: 'MS PGothic',
            },
          ])
        .style(style1);
      ws1.cell(2, 13 + countChangeExport)
        .string('備考1')
        .style(style1);
      ws1.cell(2, 14 + countChangeExport)
        .string('備考2')
        .style(style1);
      ws1.cell(2, 15 + countChangeExport)
        .string('個人コード1-種別（1:テンキー.0：NFCカード）')
        .style(style1);
      ws1.cell(2, 16 + countChangeExport)
        .string(M2)
        .style(style1);
      ws1.cell(2, 17 + countChangeExport)
        .string('個人コード2-種別（1:テンキー.0：NFCカード）')
        .style(style1);
      ws1.cell(2, 18 + countChangeExport)
        .string(O2)
        .style(style1);
      ws1.cell(2, 19 + countChangeExport)
        .string('個人コード3-種別（1:テンキー.0：NFCカード）')
        .style(style1);
      ws1.cell(2, 20 + countChangeExport)
        .string(Q2)
        .style(style1);
      ws1.cell(2, 2, totalLine, 4, false)
        .style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            bgColor: 'fcd5b4',
            fgColor: 'fcd5b4',
          },
        });
      ws1.cell(3, 2, totalLine, 20 + countChangeExport, false)
        .style({
          alignment: {
            vertical: 'center',
            horizontal: 'left',
          },
        });
      ws1.cell(2, 7, totalLine, 9 + countChangeExport, false)
        .style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            bgColor: 'fcd5b4',
            fgColor: 'fcd5b4',
          },
        });
      ws1.cell(2, 11 + countChangeExport, totalLine, 12 + countChangeExport, false)
        .style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            bgColor: 'daeef3',
            fgColor: 'daeef3',
          },
        });
      ws1.cell(3, 12 + countChangeExport, totalLine, 12 + countChangeExport, false)
        .style({
          numberFormat: '@',
        });
      // Set border
      ws1.cell(2, 2, totalLine, 20 + countChangeExport, false)
        .style(styleBorder);
      // set width for column
      ws1.column(13 + countChangeExport).setWidth(34);
      ws1.column(15 + countChangeExport).setWidth(34);
      ws1.column(17 + countChangeExport).setWidth(34);
      ws1.column(1).setWidth(3);
      const countUser = 3;
      if (dataUser.length > 0) {
      await dataUser.forEach((item: any, index: any) => {
        ws1.cell(countUser + index, 2)
          .string(item.userId);
        ws1.cell(countUser + index, 4)
          .string(item.name);
        ws1.cell(countUser + index, 5)
          .string(item.furigana);
        ws1.cell(countUser + index, 6)
          .string(item.email);
        ws1.cell(countUser + index, 7)
          .number(item.isAdmin);
        ws1.cell(countUser + index, 8)
          .string(item.departmentId);
        ws1.cell(countUser + index, 9)
          .string(item.departmentName);
        ws1.cell(countUser + index, 8 + countChangeExport)
          .number(item.isPhoneAdmin);
        ws1.cell(countUser + index, 9 + countChangeExport)
          .number(item.shareKeyBeValid);
        ws1.cell(countUser + index, 10 + countChangeExport)
          .number(item.isPasswordLoginValid);
        ws1.cell(countUser + index, 11 + countChangeExport)
          .number(item.isMfaSms);
        ws1.cell(countUser + index, 12 + countChangeExport)
          .string(item.phoneNumber);
        ws1.cell(countUser + index, 13 + countChangeExport)
          .string(item.option1);
        ws1.cell(countUser + index, 14 + countChangeExport)
          .string(item.option2);
        if (item.pCodes[0]) {
          ws1.cell(countUser + index, 15 + countChangeExport)
            .number(item.pCodes[0].type);
          ws1.cell(countUser + index, 16 + countChangeExport)
            .string(item.pCodes[0].pCodeId);
        }
        if (item.pCodes[1]) {
          ws1.cell(countUser + index, 17 + countChangeExport)
            .number(item.pCodes[1].type);
          ws1.cell(countUser + index, 18 + countChangeExport)
            .string(item.pCodes[1].pCodeId);
        }
        if (item.pCodes[2]) {
          ws1.cell(countUser + index, 19 + countChangeExport)
            .number(item.pCodes[2].type);
          ws1.cell(countUser + index, 20 + countChangeExport)
            .string(item.pCodes[2].pCodeId);
        }
        });
      }
      // sheet 2
      ws2.cell(2, 2)
        .string('ユーザーID');
      ws2.cell(3, 2)
        .string('編集禁止')
        .style({
          alignment: {
            vertical: 'center',
            horizontal: 'right',
          },
        });
      await dataGroup.forEach((value: any, index: any) => {
        const cell = index + 3;
        ws2.cell(2, cell)
          .string(value.name)
          .style({
            alignment: {
              textRotation: 90,
              vertical: 'center',
              horizontal: 'center',
            },
          });
        ws2.cell(3, cell)
          .string(value.groupId)
          .style({
            alignment: {
              textRotation: 90,
              vertical: 'center',
              horizontal: 'center',
            },
            fill: {
              type: 'pattern',
              patternType: 'solid',
              bgColor: '808080',
              fgColor: '808080',
            },
          });
        for (let i = 3; i <= totalLine; i++) {
          const cellValue = sheetNameExport[0] + '!B' + i;
          ws2.cell(i + 1, cell)
            .string('');
          ws2.cell(i + 1, 2)
            .formula('IF( ' + cellValue + '= "", "", ' + cellValue + ')')
            .style({
              alignment: {
                vertical: 'center',
                horizontal: 'left',
              },
            });
        }
        this.countColumn++;
      });

      await dataUser.forEach((userItem: any, userIndex: any) => {
        dataGroup.forEach((groupItem: any, groupIndex: any) => {
          const checkGroupPassages = dataGroupPassages.filter((item: any) => {
            return (item.userId === userItem.userId && item.groupId === groupItem.groupId);
          });
          if (checkGroupPassages.length > 0) {
            ws2.cell(userIndex + 4, groupIndex + 3).number(1);
          }
        });
      });

      const getColumn = ExcelJS.getExcelCellRef(totalLine + 1, this.countColumn + 1);
      // Set border
      ws2.cell(2, 2, totalLine + 1, this.countColumn + 1, false)
        .style(styleBorder);
      ws2.cell(1, 3)
        .string(C1)
        .style({
          alignment: {
            vertical: 'center',
            horizontal: 'left',
          },
          font: {
            name: 'MS PGothic',
          },
        });
      ws2.addDataValidation({
        type: 'list',
        allowBlank: true,
        showDropDown: true,
        sqref: 'C4:' + getColumn,
        formulas: ['=' + sheetNameExport[2] + '!$B$3:$B$5'],
      });
      ws2.cell(2, 2).style({
        alignment: {
          vertical: 'center',
          horizontal: 'left',
        },
      });
      // set default data
      ws2.cell(4, 3, totalLine + 1, this.countColumn + 1)
        .style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            bgColor: 'bfbfbf',
            fgColor: 'bfbfbf',
          },
        });
      ws2.column(2).setWidth(24);
      ws2.row(3).hide();
      ws2.addConditionalFormattingRule('C4:' + getColumn, {
        type: 'expression',
        priority: 1,
        formula: 'NOT(ISERROR(SEARCH("1",C4:' + getColumn + ')))',
        style: styleFormat,
      });
      const saveAs = require('file-saver');
      wb.writeToBuffer().then((buffer: any) => {
        const blob = new Blob([buffer]);
        saveAs(blob, 'alluser-data.xlsx');
      });
    },
    clickConfirmEmail(data: any) {
      this.typeConfirm  = data.type;
      this.showModalProcess = false;
      this.showModalConfirmEmail = true;
    },
    clickImportExcel(files: any) {
      this.showModalConfirmEmail = false;
      this.resetAllValue();
      this.isLoading = true;
      setTimeout(() => {
        this.importExelAllUser(files);
      }, 200);
    },
    importExelAllUser(data: any): void {
      const files = data.files;
      if (files !== null && files !== undefined && files.length !== 0) {
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const XLSX = require('xlsx');
          const workbook = XLSX.read(read, {type: 'array'});
          this.sheetUserName = workbook.SheetNames[0];
          this.sheetGroupName = workbook.SheetNames[1];
          const usersheetdata = workbook.Sheets[this.sheetUserName];
          const gatesheetdata = workbook.Sheets[this.sheetGroupName];
          this.isLoading = false;
          this.showProgressImport = true;
          if (usersheetdata !== undefined && gatesheetdata !== undefined) {
            const usersheetarray = XLSX.utils.sheet_to_json(usersheetdata, {header: 1});
            const gatesheetarray = XLSX.utils.sheet_to_json(gatesheetdata, {header: 1});
            const headerarray = usersheetarray[1];
            const gateheaderarray = gatesheetarray[1];
            const isEmptyFirstColumnUser = (headerarray[0] === undefined);
            usersheetarray.forEach((value: any) => {
              if (isEmptyFirstColumnUser) {
                if (value[0] === undefined) {
                  value.splice(0, 1);
                }
              }
              for (let i = 0; i < value.length; i++) {
                if (value[i] === undefined || value[i].toString().trim().length === 0) {
                  value[i] = '';
                }
              }
            });
            const isEmptyFirstColumnGate = (gateheaderarray[0] === undefined);
            gatesheetarray.forEach((value: any) => {
              if (isEmptyFirstColumnGate) {
                if (value[0] === undefined) {
                  value.splice(0, 1);
                }
              }
              for (let i = 0; i < value.length; i++) {
                if (value[i] === undefined || value[i].toString().trim().length === 0) {
                  value[i] = '';
                }
              }
            });
            console.log('usersheetarray', usersheetarray);
            console.log('gatesheetarray', gatesheetarray);
            if (headerarray.length === 19) {
              countChange = 0;
            }
            const CloumnData = 19 + countChange; // Sheet must have 19 cloumn
            let isHasLowerCase = false;
            if (headerarray.length === CloumnData && gatesheetarray.length !== 0) {
              // Sheet User Data
              for (let index = 2; index < usersheetarray.length; index++) {
                const itemArr = [];
                if (usersheetarray[1].length === CloumnData) {
                  let rowIsblank = true;
                  for (let headeridx = 0; headeridx < CloumnData; headeridx++) {
                    itemArr[headeridx] = (usersheetarray[index][headeridx] === undefined)
                      ? '' : usersheetarray[index][headeridx] + '';
                    if (itemArr[headeridx] !== '') {
                      rowIsblank = false;
                    }
                  }
                  if (rowIsblank === false) {
                    if (itemArr[14 + countChange].toUpperCase() !== itemArr[14 + countChange]
                          || itemArr[16 + countChange].toUpperCase() !== itemArr[16 + countChange]
                            || itemArr[18 + countChange].toUpperCase() !== itemArr[18 + countChange]) {
                      isHasLowerCase = true;
                    }
                    this.UserSheetImportData.push([itemArr, index + 1]);
                  }
                }
              }
              // Sheet2
              if (gatesheetarray[2][1] !== undefined && gatesheetarray[2][1].match(/^[0-9a-fA-F-]{36}$/)) {
                this.groupGateArray = gatesheetarray[2]; // Array key start from 1.
              }
              if (this.groupGateArray.length > 0) {
                for (let index = 0; index < gatesheetarray.length - 3; index++) {
                  if (gatesheetarray[index + 3][0] !== undefined && (gatesheetarray[index + 3][0].toString() !== '')) {
                    let noStatusData = true;
                    for (let gridx = 1; gridx < this.groupGateArray.length; gridx++) {
                      if (gatesheetarray[(index + 4) - 1][gridx] !== undefined
                      && gatesheetarray[(index + 4) - 1][gridx] !== '') {
                        noStatusData = false;
                      }
                    }
                    if (noStatusData === false) {
                      this.userIdArray.push([gatesheetarray[index + 3][0], index + 4]);
                    }
                  }
                }
              }
              if (this.userIdArray.length > 0 && this.groupGateArray.length > 0) {
                for (let gridx = 1; gridx < this.groupGateArray.length; gridx++) {
                  const tempArray = [];
                  for (const item of this.userIdArray) {
                    tempArray[item[0]] = gatesheetarray[item[1] - 1][gridx];
                  }
                  // for (let uidx = 0; uidx < this.userIdArray.length; uidx++) {
                  //   console.log(this.userIdArray[uidx]);
                  //   tempArray[this.userIdArray[uidx][0]] = gatesheetarray[this.userIdArray[uidx][1] - 1][gridx];
                  // }
                  this.userInGroupMx[this.groupGateArray[gridx]] = tempArray;
                }
              }
              console.log(this.UserSheetImportData);
              console.log(this.userInGroupMx);
              this.totalDataRowImport = this.UserSheetImportData.length;
              this.totalDataRowSheet2 = (this.groupGateArray.length - 1) * this.userIdArray.length;
              this.isHasLowerCaseCardNumberExcel = isHasLowerCase;
              if ((this.UserSheetImportData.length + this.userIdArray.length) > 0) {
                if (!this.isHasLowerCaseCardNumberExcel) {
                  // Send data
                  if (this.UserSheetImportData.length > 0) {
                  this.percentProcess = (100 / (this.totalDataRowImport));
                  // Send data
                  this.excuteRegisterUser()
                    .then(() => {
                      if (this.groupGateArray.length > 1) {
                        // Send data
                        this.percentProcessSheet2 = (100 / (this.totalDataRowSheet2));
                        this.gateGrpImportExe();
                      } else {
                        this.isFinishProcess = true;
                      }
                    });
                  } else {
                    if (this.groupGateArray.length > 0) {
                      // Send data
                      this.gateGrpImportExe();
                    } else {
                      this.isFinishProcess = true;
                    }
                  }
                } else {
                  this.showProgressImport = false;
                }
              } else {
                this.isFinishProcess = true;
                this.errorMessageCsvXlsx = '不正なファイルです。正しいファイルを選択しなおしてください。';
              }
            } else {
              this.isFinishProcess = true;
              this.errorMessageCsvXlsx = '不正なファイルです。正しいファイルを選択しなおしてください。';
            }
          } else {
            this.isFinishProcess = true;
            this.errorMessageCsvXlsx = '不正なファイルです。正しいファイルを選択しなおしてください。';
          }
        };
        reader.onerror = (): void => {
          this.isLoading = false;
          let msg = '選択されているファイルの読み込みに失敗しました。<br>';
          msg += 'ブラウザの更新、もしくは別ファイルを選択してから<br>再度アップロードしたいファイルを選択してください。';
          this.errorMessageCsvXlsx = msg;
          this.isFinishProcess = true;
          this.showProgressImport = true;
        };
        reader.readAsArrayBuffer(files);
      } else {
        this.isLoading = false;
        this.showProgressImport = true;
        this.errorMessageCsvXlsx = 'XLSXのファイルを選択してください。';
        this.isFinishProcess = true;
      }
    },
    async excuteRegisterUser() {
      this.closeDialogCheckCardExcel();
      this.showProgressImport = true;
      for (const item of this.UserSheetImportData) {
        if ( this.UserSheetImportData.length === 0) {
          break;
        }
        let rowstatus = '';
        if (item[0] === undefined || item[0][0].length < 4
              || item[0][0].length > 96) {
            rowstatus = this.sheetUserName + 'シート>>' + item[1] + ' 行目>>' + '<span>入力したパラメーターが誤っています。入力内容をご確認ください。</span>';
            this.$set(this.errorMessageImport, this.rowinErrDialog++, rowstatus);
        } else {
          const phoneNumber = item[0][10 + countChange].replace(/\u200B/g, '');
          const phone = phoneNumber.substring(1);
          const isMfaSms = item[0][9 + countChange];
          let phoneNumberEnd = '';
          const checkMfaSms = this.convertStringToBoolean(isMfaSms, false);
          const admin = this.convertStringToBoolean(item[0][5], false);
          const phoneAdmin = this.convertStringToBoolean(item[0][6 + countChange], false);
          const isShareKeyBeValid = this.convertStringToBoolean(item[0][7 + countChange], false);
          const checkPasswordLoginValid = this.convertStringToBoolean(item[0][8 + countChange], true);
          let tmpIsSendEmail = false;
          if (item[0][4] !== null && item[0][4] !== '') {
            tmpIsSendEmail = true;
          } else {
            tmpIsSendEmail = false;
          }
          console.log('excuteRegisterUser', item[0][0]);
          const user: UserRegister = {
            userId: item[0][0],
            name: item[0][2],
            furigana: item[0][3],
            email: item[0][4],
            isAdmin: admin,
            isPhoneAdmin: phoneAdmin,
            shareKeyBeValid: isShareKeyBeValid,
            isMfaSms: checkMfaSms,
            option1: item[0][11 + countChange],
            option2: item[0][12 + countChange],
            isSendEmail: tmpIsSendEmail,
            isPasswordLoginValid: checkPasswordLoginValid,
          };
          if (checkMfaSms) {
            if (phoneNumber.match(/^[0][0-9]{10}$/)) {
              phoneNumberEnd = '+81' + phone;
            }
            user.phoneNumber = phoneNumberEnd;
          }
          if (item[0][1]) {
            user.password = item[0][1];
          }
          // result = 0 is fail
          // result = 1 is sucess
          // result = 2 is change request
          let resultUser = await this.userEditExel(user, item[1], item[0][6]);
          if (resultUser === 2) {
            // send Add User
            if (item[0][6] !== '' && countChange > 0) {
              user.departmentIds = [item[0][6]];
            }
            resultUser = await this.userAddExel(user, item[1]);
          }
          // excute pCode
          // pCode 1
          let resPcode1 = await this.pCodeAddExel(item[0][0], item[0][13 + countChange],
          item[0][14 + countChange], item[1]);
          if (resPcode1 === 2) {
            // send Edit Pcode
            resPcode1 = await this.pCodeEditExel(item[0][0], item[0][14 + countChange], item[1]);
          }

          // pcode 2
          let resPcode2 =  await this.pCodeAddExel(item[0][0], item[0][15 + countChange],
          item[0][16 + countChange], item[1]);
          if (resPcode2 === 2) {
            // send Edit Pcode
            resPcode2 = await this.pCodeEditExel(item[0][0], item[0][16 + countChange], item[1]);
          }
          // pcode 3
          let resPcode3 = await this.pCodeAddExel(item[0][0], item[0][17 + countChange],
          item[0][18 + countChange], item[1]);
          if (resPcode3 === 2) {
            // send Edit Pcode
            resPcode3 = await this.pCodeEditExel(item[0][0], item[0][18 + countChange], item[1]);
          }
          if (resultUser === 1 && resPcode1 === 1
                && resPcode2 === 1 && resPcode3 === 1 && this.UserSheetImportData.length > 0) {
              this.successItemImport++;
          }
        }
        if ( this.UserSheetImportData.length > 0) {
          this.progressCsvXlsx += this.percentProcess;
        }
      }
    },

    userEditExel(user: UserRegister, z: number, derpartmentId: any) {
      const vm = this;
      console.log('useruseruseruser', user);
      return new Promise((resolve) => {
        this.$http.put(Config.USERS_LIST_API + '/' + encodeURIComponent(encodeURIComponent(user.userId)), user)
          .then(async (response: any) => {
            console.log(response);
            await this.updateDepartmentId(derpartmentId, user.userId);
            resolve(1);
          })
          .then( null, (err: any) => {
            // error
            console.log('err: ', err);
            let rowstatus = '';
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 403:
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 404:
              case 401:
                // const result = await vm.userAddExe(user, z);
                resolve(2);
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              }
            resolve(0);
            });
      });
      // let vm = this;
    },
    async gateGrpImportExe() {
      const vm = this;
      const userCheckListSheet2 = new Map();
      for (let index = 1; index < this.groupGateArray.length; index++) {
        if ( this.groupGateArray.length === 0) {
          break;
        }
        const groupId = this.groupGateArray[index];
        const data = this.userInGroupMx[groupId];
        for (const item of this.userIdArray) {
          if ( this.groupGateArray.length === 0) {
            break;
          }
          const userId = item[0];
          const rownumber = item[1];
          const status = data[userId];
          if (userId === undefined || userId.toString().length < 4 || userId.toString().length > 96) {
            const rowstatus = vm.sheetGroupName + 'シート>>' + '>>' + rownumber + ' 行目>><span>入力したパラメーターが誤っています。入力内容をご確認ください。</span>';
            this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
          } else {
            if (userCheckListSheet2.has(userId) !== true) {
              // need to call api to check user
              console.log('call api to check user', userId);
              const isValid = await this.checkUserValid(userId);
              console.log(userId, 'is valid', isValid);
              userCheckListSheet2.set(userId, isValid);
            }
            const isUserValid = userCheckListSheet2.get(userId);
            if (isUserValid) {
              if (status === undefined || status === '') {
                // skip data
                vm.successItemSheet2++;
              } else if (parseInt(status) === 1 || parseInt(status) === 0) {
                const groupPassageId = await this.getGroupPassageId(groupId, userId);
                if (groupPassageId === false) {
                  // error
                  if (parseInt(status) === 1) {
                    console.log('Add ' + userId + ' to ' + groupId);
                    const result = await this.addGroupPassage(groupId, userId);
                    if (result === true) {
                      vm.successItemSheet2++;
                    } else {
                      console.log(userId, vm.rowinErrDialog);
                      const rowstatus = vm.sheetGroupName + 'シート>>' + rownumber + ' 行目>>' + groupId + '>>' + userId + '>><span>' + result + '</span>';
                      this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                    }
                  } else if (parseInt(status) === 0) {
                    vm.successItemSheet2++;
                  }
                } else {
                  if (parseInt(status) === 0) {
                    console.log('Delelte ' + userId + ' from ' + groupId);
                    const result = await vm.deleteGroupPassage(groupPassageId as string);
                    if (result === true) {
                      vm.successItemSheet2++;
                    } else {
                      const rowstatus = vm.sheetGroupName + 'シート>>' + rownumber + ' 行目>>' + groupId + '>>' + userId + '>><span>' + result + '</span>';
                      this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                    }
                  } else if (parseInt(status) === 1) {
                    vm.successItemSheet2++;
                  }
                }
              } else {
                // status is invalid
                console.log('status is invalid');
                const rowstatus = vm.sheetGroupName + 'シート>>' + rownumber + ' 行目>>' + groupId + '>>' + userId + '>><span>ステータス値が無効です</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
              }
            } else {
              // user not valid
              console.log('user is invalid', userId);
              const rowstatus = vm.sheetGroupName + 'シート>>' + rownumber + ' 行目>>' + userId + '>><span>ユーザーは存在しません。入力内容をご確認ください。</span>';
              this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
            }
          }
          if ( this.groupGateArray.length > 0) {
            this.progressSheet2 += this.percentProcessSheet2;
          }
        }
      }
      this.isFinishProcess = true;
    },
    addGroupPassage(groupID: string, userID: string) {
      return new Promise((resolve) => {
        this.$http.post(Config.GROUPS_PASSAGE_API, { groupId: groupID, userId: userID})
          .then((res: any) => {
            resolve(true);
          }, (error) => {
            console.log(error);
            let errorName = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                break;
              case 404:
                errorName = '該当するデーターがありません。';
                break;
              case 409:
                console.log(Config.GROUPS_PASSAGE_API + ':' + groupID + ' ' + userID + ' ' + 'failed');
                errorName = '通行権限が重複しています。';
                break;
              case 420:
                errorName = 'ユーザー登録数が上限に達しています。';
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
            }
            resolve(errorName);
          });
      });
    },
    deleteGroupPassage(groupPassageId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUPS_PASSAGE_API + '/' + encodeURIComponent(groupPassageId))
          .then((res: any) => {
            resolve(true);
          }, (error) => {
            console.log(error);
            let errorName = '';
            switch (error.status) {
            case 403:
              errorName = '通行権限グループ通行権限の削除権限がありません。';
              break;
            case 404:
              errorName = '該当するデーターがありません。';
              break;
            case 500:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            case 503:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            default:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
          }
            resolve(errorName);
          });
      });
    },
    checkUserValid(userId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
          .then((res: any) => {
            resolve(true);
          }, (err) => {
            console.log(err);
            resolve(false);
          });
      });
    },
    getGroupPassageId(groupId: string, userId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.GROUPS_PASSAGE_API + '?groupId=' + encodeURIComponent(groupId)
          + '&userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            if (response.data.groupPassages.length > 0) {
              resolve(response.data.groupPassages[0].groupPassageId);
            }
            resolve('');
          }, (err) => {
            console.log(err);
            resolve(false);
          });
      });
    },
    pCodeAddExe(id: any, typePcode: any, pCode: any, isPValid: boolean, z: number) {
      const vm = this;
      return new Promise((resolve) => {
        if (typePcode !== '' && pCode !== '') {
          const Infor = {
            userId: id,
            pCodeId: pCode,
            type: parseInt(typePcode),
            isValid: isPValid,
          };
          this.$http.post(Config.PCODE_LIST_API, Infor)
            .then((response: any) => {
            console.log(response);
            resolve(1);
          })
          .then( null, (error: any) => {
            // catch
            console.log('err: ', error);
            let rowstatus = '';
            let errorName = '';
            let sheetName = '';
            if (vm.sheetUserName.length > 0) {
              sheetName = vm.sheetUserName + 'シート>>';
            }
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                rowstatus = sheetName + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 403:
                errorName = '個人コードの追加権限がありません。';
                rowstatus = sheetName + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 404:
                resolve(3);
                break;
              case 409:
                // duplicate
                resolve(2);
                break;
              case 420:
                this.errorMessageCsvXlsx = '個人コード登録数が上限に達しています。';
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = sheetName + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = sheetName + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = sheetName + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              }
            resolve(0);
            });
        } else {
          resolve(1);
        }
      });
    },

    pCodeAddExel(id: any, typePcode: any, pCode: any, z: any) {
      const vm = this;
      return new Promise((resolve) => {
        if (typePcode !== '' && pCode !== '') {
          const Infor = {
            userId: id,
            pCodeId: pCode,
            type: parseInt(typePcode),
            isValid: true,
          };
          this.$http.post(Config.PCODE_LIST_API, Infor)
            .then((response: any) => {
            console.log(response);
            resolve(1);
          })
          .then( null, (error: any) => {
            // catch
            console.log('err: ', error);
            let rowstatus = '';
            let errorName = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 403:
                errorName = '個人コードの追加権限がありません。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 404:
                errorName = Config.ERROR_404;
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 409:
                // duplicate
                resolve(2);
                break;
              case 420:
                this.errorMessageCsvXlsx = '個人コード登録数が上限に達しています。';
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
                break;
              }
            resolve(0);
            });
        } else {
          resolve(1);
        }
      });
    },
    pCodeEditCSV(id: any, pCode: any, isPValid: any, z: number) {
      const vm = this;
      return new Promise((resolve) => {
        let Infor;
        if (isPValid !== null && isPValid !== undefined) {
          Infor = {
            userId: id,
            isValid: isPValid,
          };
        } else {
          Infor = {
            userId: id,
          };
        }
        this.$http.put(Config.PCODE_LIST_API + '/' + encodeURIComponent(pCode), Infor)
            .then((response: any) => {
            console.log(response);
            resolve(1);
          }).then( null, (error: any) => {
            // catch
            console.log('err: ', error);
            let rowstatus = '';
            let errorName = '';
            let sheetName = '';
            if (vm.sheetUserName.length > 0) {
              sheetName = vm.sheetUserName + 'シート>>';
            }
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                errorName = '個人コードの編集権限がありません。';
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
            }
            rowstatus = sheetName + z + ' 行目>> 個人コード: ' + pCode + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
            resolve(0);
            });
      });
    },
    pCodeEditExel(id: any, pCode: any, z: any) {
      const vm = this;
      return new Promise((resolve) => {
        const Infor = {
          userId: id,
        };
        this.$http.put(Config.PCODE_LIST_API + '/' + encodeURIComponent(pCode), Infor)
            .then((response: any) => {
            console.log(response);
            resolve(1);
          }).then( null, (error: any) => {
            // catch
            console.log('err: ', error);
            let rowstatus = '';
            let errorName = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                errorName = '個人コードの編集権限がありません。';
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
            }
            rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>> 個人コード: ' + pCode + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
            resolve(0);
            });
      });
    },

    userAddExel(user: UserRegister, z: number) {
      const vm = this;
      return new Promise((resolve) => {
        this.$http.post(Config.USERS_LIST_API, user)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              console.log(response);
              resolve(1);
            } else {
              let rowstatus = '';
              let errorName = '';
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
              resolve(0);
            }
          })
          .then( null, (error: any) => {
            console.log('err: ', error);
            let rowstatus = '';
            let errorName = '';
            switch (error.status) {
              case 401:
              if (error.body.message === 'The incoming token has expired') {
                errorName = '';
              } else {
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
              }
              break;
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            case 403:
              errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            case 409:
              errorName = '入力されたユーザーIDは既に使用されています。他のユーザーIDを入力してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            case 500:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            case 503:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            default:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
              }
            this.$set(this.errorMessageImport, vm.rowinErrDialog++, rowstatus);
            resolve(0);
            }); // catch
      });
    },
    closeModalProgressImport() {
      if (this.successItemImport > 0) {
        this.isReload = true;
        this.resetAllValue();
      } else {
        this.resetAllValue();
      }
    },
    showDialogImport(isShowCSV: boolean) {
      // showImport(isShowCSV: boolean) {
      this.showModalImport = true;
      this.isShowViewCSV = isShowCSV;
      this.isReload = false;
    // },
    },
    clickEditCard(value: any) {
      this.$router.push({ path: Config.CARD_EDIT, query: {pCodeId: value}});
    },
    closeImportCSV() {
      this.showModalImport = false;
      if (this.isReload) {
        this.getListCards();
      }
    },
    convertStringToBoolean(text: any, value: any) {
      const str = text.trim().toLowerCase();
      switch (str) {
        case '1':
          return true;
        case '0':
          return false;
        default:
          return value;
      }
    },
    converToBit(text: any) {
      let str = text !== undefined ? text.toString() : '';
      if (str !== null && str !== '') {
        str = str.trim().toLowerCase();
      } else {
        str = 'false';
      }
      switch (str) {
        case 'true':
          return 1;
        case 'false':
          return 0;
        default:
          return 1;
      }
    },
    converToDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },

    // Disable, Enable Pcodes
    closeDialogEnable() {
      this.showProgress = false;
      if (this.successItemProcess > 0) {
        this.getListCards();
      }
    },

    showDialogConfirm(value: any) {
      this.typeAction = value.type;
      this.dataAction = value.data;
      this.showConfirm = true;
    },

    closeDialogConfirm() {
      this.showConfirm = false;
    },

    async clickPCodesAction() {
      this.showConfirm = false;
      this.errorMessageLogs = '';
      this.showProgress = true;
      this.rowinErrDialog = 0;
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcessEnable = false;
      this.totalDataRowProcess = this.dataAction.length;
      const percent = (100 / this.totalDataRowProcess);

      for (const pCodeId of this.dataAction) {
        let result;
        if (this.typeAction === 0) {
          //  enable
          result = await this.enableOrDisablePcodes(pCodeId, true);
        } else if (this.typeAction === 1) {
          //  disable
          result = await this.enableOrDisablePcodes(pCodeId, false);
        }
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcessEnable = true;
    },
    enableOrDisablePcodes(pCodeId: string, isEnable: boolean) {
      return new Promise((resolve) => {
        const data = {
          isValid: isEnable,
        };
        this.$http.put(Config.PCODE_LIST_API + '/' + encodeURIComponent(pCodeId), data)
        .then((response: any) => {
          console.log(response);
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = 'pcodeIdの形式が間違っている。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = 'pcodeIdが存在しない。';
              break;
            case 420:
              errorName = 'ゲートに紐づく個人コードが上限を超えるため、変更できない。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = pCodeId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        });
      });
    },
    closeDialogCheckCardCSV() {
      this.isHasLowerCaseCardNumberCSV = false;
    },
    closeDialogCheckCardExcel() {
      this.isHasLowerCaseCardNumberExcel = false;
    },
    clickCheckBoxExportUserInfoCSV() {
      this.isExportUserInfoCSV = !this.isExportUserInfoCSV;
    },
    async getDataForGroup(check: any) {
      const vm = this;
      let page = 1;
      let isContinueGetData: boolean = true;
      while (isContinueGetData) {
        await this.getGroupExport(page, check);
        page++;
        if (page <= this.maxPageGroup) {
          isContinueGetData = true;
        } else {
          isContinueGetData = false;
        }
      }
    },
    getGroupExport(page: any, check: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GROUP_LIST_API + '?limit=1000&page=' + page)
          .then(async (res: any) => {
            for (const item of  res.body.groups) {
              this.exportGroupData.push({
                groupId: item.groupId,
                name: item.name,
              });
            }
            if (check) {
              await Promise.all(res.body.groups.map((item: any) => this.getUserGroup(item.groupId)));
            }
            console.log(this.exportGroupData);
            if (res.body.maxRecords > 0) {
              this.maxPageGroup = Math.ceil((res.body.maxRecords) / 1000);
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    async getDataForUserExcel() {
      const vm = this;
      let page = 1;
      let isContinueGetData: boolean = true;
      while (isContinueGetData) {
        await this.getUserExportExcel(page);
        page++;
        if (page <= this.maxPageUser) {
          isContinueGetData = true;
        } else {
          isContinueGetData = false;
        }
      }
    },
    getUserExportExcel(page: any) {
      const limit = 1000;
      return new Promise((resolve, reject) => {
        this.$http.get(Config.USERS_LIST_API + '?limit=' + limit + '&page=' + page + '&isExceptOptionalUser=true&exclude=thumbnail')
          .then(async (res: any) => {
            for (let i = 0; i < res.body.users.length; i += 20) {
              const chunk = res.body.users.slice(i, i + 20);
              const arrayPromise: any = [];
              for (const [index, item] of  chunk.entries()) {
                const itemData = {
                  userId: item.userId,
                  name: item.name,
                  furigana: item.furigana,
                  email: item.email,
                  isMfaSms: this.converToBit(item.isMfaSms),
                  phoneNumber: String.fromCharCode(8203) + item.phoneNumber.replace('+81', '0'),
                  isAdmin: this.converToBit(item.isAdmin),
                  departmentId: '',
                  departmentName: '',
                  isPhoneAdmin: this.converToBit(item.isPhoneAdmin),
                  shareKeyBeValid: this.converToBit(item.shareKeyBeValid),
                  passCode: item.passCode,
                  option1: item.option1,
                  option2: item.option2,
                  isValid: this.converToBit(item.isValid),
                  registered:  this.converToDate(item.registered),
                  updated:  this.converToDate(item.updated),
                  isPasswordLoginValid: this.converToBit(item.isPasswordLoginValid),
                  pCodes: [],
                };
                this.exportUserData.push(itemData);
                await this.actionWait(100);
                const promise3  =  this.getPcodeOneUser(item.userId, limit * (page - 1) + index);
                const promise4 = AlligateUtils.user.getDepartmentUser(this, item.userId);
                const result = Promise.all([promise3, promise4]).then((values) => {
                  const relationsDepartmentUser: any = values[1];
                  const check = this.exportUserData.findIndex((x: any) => x.userId === item.userId);
                  if (check >= 0) {
                    this.exportUserData[check].departmentId = (relationsDepartmentUser.length > 0
                    && relationsDepartmentUser[0].departmentId !== '') ?
                    relationsDepartmentUser[0].departmentId : '';
                    this.exportUserData[check].departmentName = (relationsDepartmentUser.length > 0
                    && relationsDepartmentUser[0].departmentId !== '') ?
                    relationsDepartmentUser[0].department.name : '';
                  }
                });
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            if (res.body.maxRecords > 0) {
              this.maxPageUser = Math.ceil((res.body.maxRecords) / limit);
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          });
      });
    },
    getPcodeOneUser(userId: any, index: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.CARD_LIST_API + '?limit=1000&page=1&sortKey=desc:registered' +
        '&userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            if (response.body.maxRecords > 0 && response.body.pCodes) {
              const i = this.exportUserData.findIndex((x: any) => x.userId === userId);
              if (i >= 0) {
                this.exportUserData[i].pCodes = response.body.pCodes.slice(0, 3);
              }
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            // console.log('err: ', err);
            resolve(false);
            }); // catch
        });
    },
    getUserGroup(groupId: any) {
      return new Promise((resolve, reject) => {
      this.$http.get(Config.GROUPS_PASSAGE_API + '?groupId=' + encodeURIComponent(groupId) + '&limit=1000&page=1')
        .then((response: any) => {
          if (response.body.maxRecords > 0 && response.body.groupPassages) {
            for (const item of response.body.groupPassages) {
              const itemData = {
                groupId: item.groupId,
                userId: item.userId,
              };
              this.exportGroupPassages.push(itemData);
            }
            setTimeout(() => {
              resolve(true);
            }, 300);
          } else {
            setTimeout(() => {
              resolve(false);
            }, 300);
          }
        })
        .then( null, (err: any) => {
          setTimeout(() => {
              resolve(false);
            }, 300);
        }); // catch
      });
    },
    excuteImportExcel() {
      if (this.UserSheetImportData.length > 0) {
        this.percentProcess = (100 / (this.totalDataRowImport));
        // Send data
        this.excuteRegisterUser()
          .then(() => {
            if (this.groupGateArray.length > 1) {
              // Send data
              this.percentProcessSheet2 = (100 / (this.totalDataRowSheet2));
              this.gateGrpImportExe();
            } else {
              this.isFinishProcess = true;
            }
          });
      } else {
        if (this.groupGateArray.length > 0) {
          // Send data
          this.gateGrpImportExe();
        } else {
          this.isFinishProcess = true;
        }
      }
    },
    actionWait(t: any) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, t);
      });
    },
    // 所属選択
    getDepartmentUser(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_DEPARTMENT_USER_API + `?userId=${encodeURIComponent(userId)}&embed=department`)
          .then((response: any) => {
            resolve(response.body);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    async updateDepartmentId(ID: any, userID: any) {
      if (countChange > 0) {
        const department: any = await this.getDepartmentUser(userID);
        let departmentId = '';
        if (department) {
          const relationsDepartmentUser = department.relationsDepartmentUser[0];
          departmentId = relationsDepartmentUser.department.departmentId;
          const relationId = relationsDepartmentUser.relationId;
          if (ID !== departmentId) {
            const result = await this.deleteDepartmentId(relationId);
            if (result) {
              await this.addDepartmentId({
                departmentId: ID,
                userId: userID,
              });
            }
          }
        } else {
          await this.addDepartmentId({
            departmentId: ID,
            userId: userID,
          });
        }
      }
    },
    deleteDepartmentId(ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_DEPARTMENT_USER_API + '/' + ID)
        .then((response: any) => {
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
    addDepartmentId(data: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.RELATION_DEPARTMENT_USER_API, data)
        .then((response: any) => {
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
  },
});
