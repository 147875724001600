









































import Vue from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UserDetailTemplate from '@/components/templates/UserDetailTemplate/UserDetailTemplate.vue';
import * as Config from '@/config';
import { User} from '@/types/alligate';
import moment from 'moment';

export interface DataType {
  // Props
  page: number;
  loading: boolean;
  tempGroupPassages: any;
  cardItems: any;
  phoneItems: any;
  errorMessageLogs: string;
  listphones: any;
  listGatePassagesInUser: any;
  userData: any;
  goBack: string;
  departments: any;
  ExpandedData: any;
  rowinErrDialog: number;
  errorMessageProcess: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  showProgress: boolean;
  isFinishProcess: boolean;
  showConfirm: boolean;
  progress: any;
  gatePassageIds: any;
  userId: string;
  messageSetting: any;
  gateList: any;
  dataProgress: any;
  dataAllow: any;

}

export default Vue.extend({
  name: 'UserDetailPage',
  components: {
    Breadcrumbs,
    SuccessButton,
    ActionButton,
    UserDetailTemplate,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    userIdModal: {
      type: String,
      default: '',
    },
    titlePage: {
      type: String,
      default: '',
    },
    authority: {
      type: Object,
      default: ({}),
    },
  },
  async mounted() {
    let userId = '';
    if (this.isModal) {
      userId = this.userIdModal;
    } else {
      userId = this.$route.query.userId.toString();
    }
    if (userId !== null && userId !== undefined) {
      this.loading = true;
      const result = await this.getInFoUser(userId);
      if (result) {
        this.userData.departmentAdministrators = [];
        this.userData.groupAdministrators = [];
        this.getDepartmentUser(userId);
        if (this.userData.isAdmin !== true) {
          await this.getGroupAuth(userId);
          await this.getDepartmentAuth(userId);
        }
        await this.getDepartmentAuthAll(localStorage.userProfileId, 1);
        this.loading = false;
        if (this.userData.groupAdministrators.length > 0 || this.userData.departmentAdministrators.length > 0) {
          this.userData.isAdmin = null;
        }
        this.getInforGatePassage(1, userId);
        this.getPcode(1, userId);
        this.getPhone(1, userId);
        this.loading = false;
      }
      this.getListGate(1);
    } else {
      this.loading = false;
    }
    if (this.$route.query.goBack !== null && this.$route.query.goBack !== undefined) {
      this.goBack = this.$route.query.goBack.toString();
    }
    this.$emit('activeMenu', 'user');
  },
  watch: {
    loading(value) {
      if (this.isModal !== true) {
        this.$emit('loading', value);
      }
    },
  },
  data: (): DataType => ({
    // Props
    loading: false,
    page: 1,
    tempGroupPassages: [],
    cardItems: [],
    phoneItems: [],
    errorMessageLogs: '',
    listphones: [],
    listGatePassagesInUser: [],
    userData: {
      isAdmin: false,
      isMfaSms: false,
      userId: '',
      password: '',
      name: '',
      furigana: '',
      phoneNumber: '',
      option1: '',
      option2: '',
      groupAdministrators: [],
      departmentAdministrators: [],
    },
    goBack: 'user',
    departments: [],
    ExpandedData: {
      data: [],
      maxRecords: 0,
      page: 1,
      loading: false,
    },
    rowinErrDialog: 0,
    errorMessageProcess: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    showProgress: false,
    isFinishProcess: false,
    showConfirm: false,
    progress: 0,
    gatePassageIds: [],
    userId: '',
    messageSetting: {
      success: '',
      error: '',
    },
    gateList: [],
    dataProgress: [],
    dataAllow: [],
  }),
  methods: {
    getInFoUser(userId: any) {
      return new Promise((resolve, reject) => {
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          this.userData = response.body.users[0];
          this.userData.phoneNumber = this.userData.phoneNumber.replace('+81', '0');
          console.log(this.userData);
          resolve(true);
        })
        .then(null, (error: any) => {
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
          resolve(false);
        });
      });
    },
    getInforGroup(page: any, userId: any) {
      this.$http.get(Config.GROUPS_PASSAGE_API + '?userId=' + encodeURIComponent(userId) + '&embed=group&limit=1000&page=' + page)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groupPassages.forEach((element: any) => {
              this.tempGroupPassages.push(element);
              this.tempGroupPassages.sort((a: any, b: any) =>
              (a.group.groupName.toLowerCase() < b.group.groupName.toLowerCase() ? -1 : 1));
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getInforGroup(page + 1, userId);
            }
          } else {
            this.tempGroupPassages = [];
          }
        })
        .then( null, (err: any) => {
          console.log(err);
        });
    },
    getPcode(page: any, userId: any) {
      this.$http.get(Config.CARD_LIST_API + '?limit=1000&page=' + page +
      '&sortKey=desc:registered' + '&userId=' + encodeURIComponent(userId) + '&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0 && response.body.pCodes !== undefined) {
            response.body.pCodes.forEach((element: any) => {
              this.cardItems.push({text: element.pCodeId, value: element.pCodeId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getPcode(page + 1, userId);
            }
          } else {
            this.cardItems = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          }); // catch
    },
    getPhone(page: any, userId: any) {
      const applyingPhones: any = [];
      const validPhones: any = [];
      this.$http.get(Config.PHONES_LIST_API + '?limit=1000&page=' + page +
      '&sortKey=desc:registered' + '&userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.phones.forEach((element: any) => {
              if (element.status === '申請中') {
                applyingPhones.push({text: '申請中 : ' + element.model, value: element.phoneId});
              }
              if (element.status === '有効') {
                validPhones.push({text: element.model, value: element.phoneId});
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getPhone(page + 1, userId);
            }
            applyingPhones.forEach((element: any) => {
              this.phoneItems.push(element);
            });
            validPhones.forEach((element: any) => {
              this.phoneItems.push(element);
            });
          } else {
            this.phoneItems = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          }); // catch
    },
    getInforGatePassage(page: any, userId: any) {
      return new Promise((resolve, reject) => {
      const d = new Date();
      const currentTime = moment(d).unix();
      this.$http.get(Config.GATES_PASSAGE_API + '?userId=' + encodeURIComponent(userId) + '&embed=gate' + '&limit=1000&page=' + page + '&isExceptOptionalGate=true&sortKey=desc:notAfter')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.passages.forEach((element: any, i: any) => {
              this.listGatePassagesInUser.push({
              subId: i + '-' + element.gateId + '-' + element.notAfter + '-' + element.notBefore,
              passageId: element.passageId,
              gateId: element.gateId,
              notAfter: element.notAfter, notBefore: element.notBefore,
              gateName: element.gate !== undefined ? element.gate.name : ''});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getInforGatePassage(page + 1, userId);
            } else {
              resolve(true);
            }
          } else {
            this.listGatePassagesInUser = [];
            resolve(false);
          }
        })
        .then(null, (error) => {
          console.log(error);
          resolve(false);
        });
      });
    },
    // 2024
    getDepartmentUser(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_DEPARTMENT_USER_API + '?userId=' + encodeURIComponent(userId) + '&limit=25&page=1&embed=department')
          .then((response: any) => {
            this.userData.department = response.body.relationsDepartmentUser[0].department.name;
            this.userData.departmentId = response.body.relationsDepartmentUser[0].department.departmentId;
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    getGroupAuth(userId: any) {
      return new Promise((resolve, reject) => {
        if (localStorage.isCustomUserAuthorityService === 'true') {
          this.$http.get(Config.GROUP_ADMINISTRATOR_API + '?userId=' + (userId) + '&embed=user,group')
          .then(async (response: any) => {
            this.userData.groupAdministrators = response.body.groupAdministrators;
            this.userData.groupAdministrators.map((v: any) => Object.assign(v, {isAdminToView: true,
              name: v.group.name}));
            resolve(true);
          }).
          then( null, (err: any) => {
            resolve(false);
          });
        } else {
          resolve(false);
        }
      });
    },
    getGroupAuthItem(item: any, index: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(item.groupId) + '&limit=25&page=1&isExceptOptionalGate=true&embed=passableTimeSetting')
          .then((response: any) => {
            this.userData.groupAdministrators[index].name = response.body.groups[0].name;
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    getDepartmentAuth(userId: any) {
      return new Promise((resolve, reject) => {
        if (localStorage.isCustomUserAuthorityService === 'true') {
          this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?userId=' + encodeURIComponent(userId))
          .then(async (response: any) => {
            this.userData.departmentAdministrators = response.body.departmentAdministrators;
            this.userData.departmentAdministrators.map((v: any) => Object.assign(v, {isAdminToView: true}));
            for (let i = 0; i < this.userData.departmentAdministrators.length; i++) {
              await this.getDepartmentItem(this.userData.departmentAdministrators[i], i);
            }
            resolve(true);
          }).
          then( null, (err: any) => {
            resolve(false);
          });
        } else {
          resolve(false);
        }
      });
    },
    getDepartmentItem(item: any, index: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.DEPARTMENT_API + '?limit=25&departmentId=' + encodeURIComponent(item.departmentId))
        .then((response: any) => {
          this.userData.departmentAdministrators[index].name = response.body.departments[0].name;
          resolve(true);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
    },
    getDepartment(page: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.DEPARTMENT_API + '?limit=1000&page=' + page)
        .then((response: any) => {
          this.departments = response.body.departments;
          resolve(true);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
    },
    // Expand getGatePassage
    getGatePassage(userId: any, pageGate: any) {
      return new Promise((resolve, reject) => {
        const apiLinkGetGate = Config.GATES_PASSAGE_API + '?userId=' + encodeURIComponent(userId) +
        '&limit=25&page=' + pageGate +
        '&embed=gate&isExceptOptionalGate=true';
        this.$http.get(apiLinkGetGate)
          .then(async (response: any) => {
            //
            this.ExpandedData.maxRecords = response.body.maxRecords;
            const data = response.body.passages;
            const item: any = [];
            for (let i = 0; i < data.length; i += 10) {
              const chunk = data.slice(i, i + 10);
              const arrayPromise: any = [];
              for (const [index, element] of  chunk.entries()) {
                item.push({
                  gateName: element.gate.name,
                  passageId: element.passageId,
                  groupName: '',
                  groupId: '',
                  passableTimeSetting: {},
                });
                this.ExpandedData.data = item;
                const result: any = this.getGateGroupItem(element.gateId, this.ExpandedData.data[index]);
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            this.ExpandedData.loading = false;
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            this.ExpandedData.loading = false;
            resolve(false);
          }); // catch
        // resolve(true);
      });
    },
    getGateGroupItem(gateId: string, data: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_GATE_GROUP_API + '?gateId=' + encodeURIComponent(gateId) + '&isExceptOptionalGate=true&embed=group')
          .then(async (response: any) => {
            const res =  response.body.relationsGateGroup;
            const result  = await this.getGroupItem(res[0].groupId, data);
            resolve(true);
          })
          .then(null, (error) => {
            console.log(error);
            resolve(false);
          });
      });
    },
    getGroupItem(groupId: any, data: any) {
      return new Promise((resolve, reject) => {
        let item: any = {};
        this.$http.get(Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=1&isExceptOptionalGate=true&embed=passableTimeSetting')
          .then((response: any) => {
            item = response.body.groups[0];
            // item.push(response.body.groups[0]);
            data.groupName = item.name;
            data.groupId = item.groupId;
            data.passableTimeSetting = item.passableTimeSetting;
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    closeModalProgress(userId: any) {
      this.showProgress = false;
      this.showConfirm = false;
    },
    confirmAction(data: any) {
      console.log(data);
      this.gatePassageIds = [];
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = false;
      this.gatePassageIds = data.dataIds;
      this.userId =  data.userId;
      this.showConfirm = true;
      /*
        0 : Enable Muti
        1 : Disable Muti
        2 : Enable One
        3 : Disable One
        4 : Leave Group
      */
    },
    async clickLeaveAction(data: any) {
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = true;
      this.showConfirm = false;
      this.isFinishProcess = false;
      this.errorMessageProcess = [];
      if (data.leave === 'gate-passage') {
        const dataIds: any = this.gatePassageIds;
        this.totalDataRowProcess = dataIds.length;
        const percent = (100 / this.totalDataRowProcess);
        for (const item of dataIds) {
          let result;
          result  = await this.deleteUserGate(item);
          if (result) {
            this.successItemProcess++;
          }
          this.progress += percent;
        }
        this.isFinishProcess = true;
      }
    },
    deleteGatePass(passageId: string) {
      return new Promise((resolve, reject) => {
        this.dataProgress = {
          total: 1,
          progressLoad: 0,
          successItem: 0,
          errorMessage: [],
          isFinish: false,
        };
        const errorMessageProcess: any = [];
        this.$http.delete(Config.GATES_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            this.dataProgress = {
              total: 1,
              progressLoad: 100,
              successItem: 1,
              errorMessage: [],
              isFinish: true,
            };
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = passageId + '>><span>' + errorName + '</span>';
            this.$set(errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            this.dataProgress = {
              total: 1,
              progressLoad: 100,
              successItem: 0,
              errorMessage: errorMessageProcess,
              isFinish: true,
            };
            resolve(false);
          }); // catch
      });
    },
    deleteUserGate(passageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GROUPS_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = passageId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    closeDialogConfirm() {
      this.showProgress = false;
      this.showConfirm = false;
      this.userId = '';
    },
    getListGate(page: any) {
      this.$http.get(Config.GATES_LIST_SIMPLE_API + '?limit=1000&page=' + page + '&sortKey=asc:gateId&isValid=true&isExceptOptionalGate=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.gates.forEach((element: any) => {
              this.gateList.push({text: element.gateName, value: element.gateId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getListGate(page);
            }
          } else {
            this.gateList = [];
          }
        })
        .then( null, (err: any) => console.log('err: ', err)); // catch
    },
    addGate(data: any) {
      return new Promise((resolve, reject) => {
        this.errorMessageLogs = '';
        this.loading = true;
        this.$http.post(Config.GATES_PASSAGE_API, {
          userId: data.userId,
          gateId: data.gateId,
          notBefore: data.notBefore,
          notAfter: data.notAfter,
        })
          .then((response: any) => {
            if (response.body.message === 'Success') {
              console.log('Success');
            }
            resolve(true);
            this.listGatePassagesInUser = [];
            this.getInforGatePassage(1, data.userId);
            this.loading = false;
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 409 : this.errorMessageLogs = '登録失敗ゲート名: ' + data.gateName;
            }
            resolve(true);
            this.loading = false;
          });
      });
    },
    delGate(ID: any) {
      this.deleteGatePass(ID);
    },
    async closeModalGate(ID: any) {
      this.loading = true;
      this.listGatePassagesInUser = [];
      await this.getInforGatePassage(1, ID);
      this.loading = false;
    },
    getDepartmentAuthAll(userId: any, page = 1) {
      return new Promise((resolve, reject) => {
        if (localStorage.isCustomUserAuthorityService === 'true') {
          this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?userId=' + (userId) + '&limit=1000&page=' + page)
          .then(async (response: any) => {
            response.body.departmentAdministrators.forEach((element: any) => {
              if (element.isAdminToEditUser) {
                this.dataAllow.push(element.departmentId);
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
                page++;
                this.getDepartmentAuthAll(userId, page);
              } else {
                resolve(true);
              }
          }).
          then( null, (err: any) => {
            resolve(false);
          });
        } else {
          resolve(false);
        }
      });
    },
    async loadUserAgain(userId: any) {
      this.loading = true;
      const result = await this.getInFoUser(userId);
      if (result) {
        this.userData.departmentAdministrators = [];
        this.userData.groupAdministrators = [];
        this.getDepartmentUser(userId);
        if (this.userData.isAdmin !== true) {
          await this.getGroupAuth(userId);
          await this.getDepartmentAuth(userId);
        }
        await this.getDepartmentAuthAll(localStorage.userProfileId, 1);
        this.loading = false;
        if (this.userData.groupAdministrators.length > 0 || this.userData.departmentAdministrators.length > 0) {
          this.userData.isAdmin = null;
        }
        this.listGatePassagesInUser = [];
        this.gateList = [];
        this.getInforGatePassage(1, userId);
        this.getPcode(1, userId);
        this.getListGate(1);
      }
    },
  },
});
