
























































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GroupGateTable from '@/components/organisms/GroupGateTable/GroupGateTable.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import GateDetailTemplate from '@/components/templates/GateDetailTemplate/GateDetailTemplate.vue';
import ModalPageGroupGateInUser from '@/components/pages/common-modal/ModalPage.vue';
import CheckBoxValid from '@/components/molecules/CheckBoxValid/CheckBoxValid.vue';
import moment from 'moment';
import { User, Group, RelationGateGroup, UnlockGate } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';

export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedDisplayOption: string[];
  selectedItem: User[];
  searchItem: [];
  sortBy: any;
  selectedIds: string[];
  displayOptionItems: DisplayOptionItem[];
  showOption: boolean;
  isVaildOnly: boolean;
  isChange: boolean;
  expanded: any;
}

export default Vue.extend({
  name: 'UserTable',
  components: {
    CancelButton,
    DisplayOption,
    Pagination,
    Searchbox,
    SelectPerPage,
    ShowPerPage,
    SuccessButton,
    TextLink,
    GroupGateTable,
    InfoItem,
    InputForm,
    GateDetailTemplate,
    ModalPageGroupGateInUser,
    CheckBoxValid,
  },
  props: {
    pageGate: {
      type: Number,
      default: 0,
    },
    gateTotalCount: {
      type: Number,
      default: 0,
    },
    loadingGroup: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    loadingGate: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    ExpandedGroup: {
      default: () => [],
    },
    userTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageGroup: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    groupDetail: {
      default: () => [],
    },
    gates: {
      type: Array as PropType<UnlockGate[]>,
      default: () => [],
    },
    userGroupLoading: {
      type: Boolean,
      default: false,
    },
    listGatePassages: {
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    userGroups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    userGroupTotalCount: {
      type: Number,
      default: 0,
    },
    userGroupPage: {
      type: Number,
      default: 1,
    },
    userGroupPerPage: {
      type: Number,
      default: 25,
    },
    userGroupRelationsGateGroupLoading: {
      type: Boolean,
      default: false,
    },
    userGroupRelationsGateGroup: {
      type: Array as PropType<RelationGateGroup[]>,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    listGroupPassages: {
      type: Array,
      default: () => [],
    },
    listphones: {
      type: Array,
      default: () => [],
    },
    tempGroupPassages: {
      default: () => [],
    },
    maxRecord: {
      type: Number,
      default: 0,
    },
    cardItems: {
      default: () => [],
    },
    phoneItems: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    groupToSearch: {
      default: [],
    },
    ExpandedData: {
      default: () => [],
    },
    dataAllow: {
      default: () => [],
    },
    authority: {
      type: Object,
      default: ({}),
    },
  },
  data: (): DataType => ({
    expanded: [],
    isChange: false,
    searchTargetItems: [
      { label: 'ユーザーID', value: 'userId' },
      { label: 'ユーザー名', value: 'name' },
      { label: 'ユーザー名ふりがな', value: 'furigana' },
      { label: 'メールアドレス', value: 'email' },
      { label: '備考1', value: 'option1' },
      { label: '備考2', value: 'option2' },
    ],
    selectedDisplayOption: [
      'name',
      'userId',
      'company',
    ],
    selectedItem: [],
    searchItem: [],
    selectedIds: [],
    sortBy: 'email',
    displayOptionItems: [
      { label: 'ユーザー名', value: 'name', isDisabled: true},
      { label: 'ユーザーID', value: 'userId' },
      { label: '所属名', value: 'company' },
      { label: 'メールアドレス', value: 'email' },
      { label: 'ユーザー名ふりがな', value: 'furigana' },
      { label: '組織管理権限', value: 'isAdmin' },
      { label: 'ゲート設定権限', value: 'isPhoneAdmin' },
      { label: '鍵共有権限', value: 'shareKeyBeValid' },
      { label: 'ステータス', value: 'isValid' },
      { label: '備考1', value: 'option1' },
      { label: '備考2', value: 'option2' },
      { label: '登録日', value: 'registered' },
      { label: '更新日', value: 'updated' },
      { label: 'SMS認証', value: 'isMfaSms' },
      { label: '二段階認証パスワード', value: 'passCode' },
    ],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataUser === 'true' ? true : false,
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const ret: DataTableHeader[] = [];
        ret.push({
          text: 'ユーザー名',
          align: 'start',
          sortable: true,
          value: 'name',
        });
        if (this.selectedDisplayOption.includes('userId')) {
          ret.push({
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'userId',
          });
        }
        if (this.selectedDisplayOption.includes('company')) {
          ret.push({
            text: '所属名',
            align: 'start',
            sortable: true,
            value: 'company',
          });
        }
        if (this.selectedDisplayOption.includes('email')) {
          ret.push({
            text: 'メールアドレス',
            align: 'start',
            sortable: true,
            value: 'email',
          });
        }
        if (this.selectedDisplayOption.includes('furigana')) {
          ret.push({
            text: 'ユーザー名ふりがな',
            align: 'start',
            sortable: true,
            value: 'furigana',
            class: 'furigana',
          });
        }
        if (this.selectedDisplayOption.includes('isAdmin')) {
          ret.push({
            text: '組織管理権限',
            align: 'start',
            sortable: true,
            value: 'isAdmin',
            class: 'isAdmin',
          });
        }
        if (this.selectedDisplayOption.includes('isPhoneAdmin')) {
          ret.push({
            text: 'ゲート設定権限',
            align: 'start',
            sortable: true,
            value: 'isPhoneAdmin',
            class: 'isPhoneAdmin',
          });
        }
        if (this.selectedDisplayOption.includes('shareKeyBeValid')) {
          ret.push({
            text: '鍵共有権限',
            align: 'start',
            sortable: true,
            value: 'shareKeyBeValid',
            class: 'shareKeyBeValid',
          });
        }
        if (this.selectedDisplayOption.includes('isValid')) {
          ret.push({
            text: 'ステータス',
            align: 'start',
            sortable: true,
            value: 'isValid',
            class: 'isValid',
          });
        }
        if (this.selectedDisplayOption.includes('option1')) {
          ret.push({
            text: '備考1',
            align: 'start',
            sortable: true,
            value: 'option1',
          });
        }
        if (this.selectedDisplayOption.includes('option2')) {
          ret.push({
            text: '備考2',
            align: 'start',
            sortable: true,
            value: 'option2',
          });
        }
        if (this.selectedDisplayOption.includes('registered')) {
          ret.push({
            text: '登録日',
            align: 'start',
            sortable: true,
            value: 'registered',
          });
        }
        if (this.selectedDisplayOption.includes('updated')) {
          ret.push({
            text: '更新日',
            align: 'start',
            sortable: true,
            value: 'updated',
          });
        }
        if (this.selectedDisplayOption.includes('isMfaSms')) {
          ret.push({
            text: 'SMS認証',
            align: 'start',
            sortable: true,
            value: 'isMfaSms',
            class: 'isMfaSms',
          });
        }
        if (this.selectedDisplayOption.includes('passCode')) {
          ret.push({
            text: '二段階認証パスワード',
            align: 'start',
            sortable: true,
            value: 'passCode',
            class: 'passCode',
          });
        }
        ret.push({
          text: '通行可能なゲート数',
          align: 'start',
          sortable: false,
          width: '220px',
          value: 'data-table-expand',
        });
        ret.push({
          text: '',
          align: 'end',
          sortable: false,
          width: '150px',
          value: 'icons',
        });
        return ret;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-page', {page: val, change: 'user'});
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-per-page', val);
        return;
      },
    },
    // groupNames: {
    //   get(): Array<{text: string, value: string }> {
    //     return this.groups.map((group) => ({
    //       text: group.groupName,
    //       value: group.groupId,
    //     }));
    //   },
    //   set(): void {
    //     return;
    //   },
    // },
  },
  watch: {
    selectedItem(val: User[]) {
      this.$emit('select-users', val.map((item) => item.userId));
    },
    isGetDataList(val: any) {
      if (val === false) {
        this.selectedItem = [];
      }
    },
  },
  methods: {
    changeEx($event: any) {
      console.log(this.expanded);
      this.isChange = true;
      this.$emit('change-expanded-user', {event: $event, expanded: 'user'});
      setTimeout(() => {
        this.isChange = false;
      }, 50);
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    CheckColumn(data: any) {
      console.log(data);
      localStorage.setItem('checkedUserList', JSON.stringify(data));
    },
    // changeSort(data: any) {
    //   console.log(data.sortBy);
    //   if (data.sortBy !== undefined  && data.sortBy.length !== 0 && data.sortBy[0] === 'data-table-expand') {
    //     this.sortBy = 'email';
    //   }
    // },
    converDateShort(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },
    goLogPage(value: any) {
      localStorage.setItem('userName', value);
      this.$router.push({ path: Config.LOG, query: {tabId: 'user'}});
    },
    clickJoinGroup(ID: any) {
      this.$router.push({ path: Config.USER_ADD_GROUP, query: {userId: ID}});
    },
    clickDetail(ID: any) {
      this.$router.push({ path: Config.USER_DETAIL, query: {userId: ID}});
    },
    makeHeader(): DataTableHeader[] {
      const ret: DataTableHeader[] = [
          {
            text: 'ユーザー名',
            align: 'start',
            sortable: false,
            value: 'name',
          },
        ];
      if (this.selectedDisplayOption.includes('email')) {
          ret.push({
            text: 'メールアドレス',
            align: 'start',
            sortable: true,
            value: 'email',
          });
      } else if (this.selectedDisplayOption.includes('option1')) {
          ret.push({
            text: '備考1',
            align: 'start',
            sortable: true,
            value: 'option1',
          });
        } else if (this.selectedDisplayOption.includes('option2')) {
          ret.push({
            text: '備考2',
            align: 'start',
            sortable: true,
            value: 'option2',
          });
        }
      ret.push({
          text: '通行権限グループ数/ゲート数',
          align: 'start',
          sortable: false,
          width: '200px',
          value: 'data-table-expand',
        });
      ret.push({
          text: '',
          align: 'end',
          sortable: false,
          value: 'icons',
        });
      return ret;
      },
    },
  created() {
    if (typeof localStorage.checkedUserList === 'undefined') {
      localStorage.setItem('checkedUserList', JSON.stringify(this.selectedDisplayOption));
    } else {
      this.selectedDisplayOption = JSON.parse(localStorage.checkedUserList);
    }
  },
});
