var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-list-template" },
    [
      _c("v-container", { staticStyle: { position: "relative" } }, [
        _c(
          "div",
          { staticClass: "container-user pt-0" },
          [
            _c(
              "div",
              { staticClass: "round-action" },
              [
                _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "end" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "btn-div", attrs: { justify: "end" } },
                          [
                            _vm.authority.isAdmin === true
                              ? _c(
                                  "div",
                                  { staticClass: "text-center mr-1" },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "btn-add pt-0" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-primary",
                                            attrs: { outlined: "" },
                                            on: {
                                              click: function($event) {
                                                _vm.isLoad = false
                                                _vm.$emit("clickAddGroup")
                                              }
                                            }
                                          },
                                          [_vm._v("通行権限グループ登録")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.authority.isAdmin === true
                              ? _c(
                                  "div",
                                  { staticClass: "text-center mr-4" },
                                  [
                                    _c(
                                      "action-button",
                                      {
                                        attrs: {
                                          text: "アクション ▼",
                                          "action-items": _vm.actionItems,
                                          disabled:
                                            _vm.selectedGroupIds.length === 0
                                        },
                                        on: {
                                          "click-enable": function($event) {
                                            return _vm.showDialogConfirm(0)
                                          },
                                          "click-disable": function($event) {
                                            return _vm.showDialogConfirm(1)
                                          },
                                          "click-delete": function($event) {
                                            return _vm.showDialogConfirm(2)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                      >\n                    "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "pb-0", attrs: { cols: "12" } },
                      [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  "no-gutters": "",
                                  justify: "space-between"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("searchbox", {
                                      staticClass:
                                        "input-search btn-user-search",
                                      attrs: {
                                        disabled: _vm.dataGroupsModal.loading,
                                        searchTargetItems:
                                          _vm.searchTargetItems,
                                        title: "通行権限グループを検索"
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.$emit(
                                            "search-group",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("group-list-modal-table", {
              attrs: {
                authority: _vm.authority,
                dataGroupsModal: _vm.dataGroupsModal,
                loading: _vm.dataGroupsModal.loading,
                page: _vm.page,
                perPage: _vm.perPage,
                groupExpanedGate: _vm.groupExpanedGate,
                isReRenderGate: _vm.isReRenderGate,
                groupExpanedUser: _vm.groupExpanedUser,
                isReRenderUser: _vm.isReRenderUser,
                isRemoveGateSelected: _vm.isRemoveGateSelected,
                isRemoveUserSelected: _vm.isRemoveUserSelected
              },
              on: {
                "change-per-page": function($event) {
                  return _vm.$emit("changePerPage", $event)
                },
                "click-group-detail": function($event) {
                  return _vm.$emit("click-group-detail", $event)
                },
                "change-page": function($event) {
                  return _vm.$emit("changePage", $event)
                },
                "get-group-isValid": function($event) {
                  return _vm.$emit("get-group-isValid", $event)
                },
                "select-groups": function(groupIds) {
                  _vm.selectedGroupIds = groupIds
                },
                clickEditGroup: _vm.clickEditGroup,
                "change-page-gate": function($event) {
                  return _vm.$emit("change-page-gate", $event)
                },
                "change-page-user": function($event) {
                  return _vm.$emit("change-page-user", $event)
                },
                "show-dialog-confirm": function($event) {
                  return _vm.isDialogConfirm($event)
                },
                "change-expanded-group": function($event) {
                  return _vm.$emit("change-expanded-group", $event)
                },
                "isValid-confirm": function($event) {
                  return _vm.showItemConfirm($event)
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: _vm.textTitle(),
          smallScreen: "800px",
          text: _vm.textAction(),
          className: "common-modal"
        },
        on: {
          close: function($event) {
            return _vm.closeDialogConfirm()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("success-button", {
                  attrs: { text: "OK" },
                  on: {
                    click: function($event) {
                      return _vm.clickGroupsAction()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.showConfirm,
          callback: function($$v) {
            _vm.showConfirm = $$v
          },
          expression: "showConfirm"
        }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v(_vm._s(_vm.textTitle()))
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.totalDataRowProcess > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemProcess > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowProcess) +
                                "件中" +
                                _vm._s(_vm.successItemProcess) +
                                _vm._s(_vm.successLabel())
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageProcess.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              _vm._l(_vm.errorMessageProcess, function(item) {
                                return _c("p", {
                                  key: item,
                                  domProps: { innerHTML: _vm._s(item) }
                                })
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFinishProcess
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.closeModalProgress()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: _vm.isEdit
            ? "通行権限グループ編集"
            : "新規通行権限グループ登録 ",
          "no-footer": "",
          smallScreen: "1200px",
          className: "common-padding"
        },
        on: {
          close: function($event) {
            return _vm.deleteBridgeProperty(_vm.isLoad)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm.isModalShow
                  ? _c("GroupAddPage", {
                      on: {
                        success: function($event) {
                          return _vm.isSuccess($event)
                        },
                        "go-time-settings": function($event) {
                          return _vm.$emit("go-time-settings", $event)
                        },
                        deleteBridgeProperty: function($event) {
                          return _vm.deleteBridgeProperty($event)
                        },
                        loading: function($event) {
                          return _vm.$emit("loading", $event)
                        },
                        loadingCircle: function($event) {
                          return _vm.$emit("loadingCircle", $event)
                        },
                        textCircle: function($event) {
                          return _vm.$emit("textCircle", $event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isModalShow,
          callback: function($$v) {
            _vm.isModalShow = $$v
          },
          expression: "isModalShow"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }