


























import Vue, { PropType } from 'vue';
import KeyAddForm from '@/components/organisms/KeyAddForm/KeyAddForm.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
export default Vue.extend({
  name: 'KeyAddTemplate',
  components: {
    KeyAddForm,
    Breadcrumbs,
  },
  props: {
    listTimes: {
      default: [],
    },
    passagesData: {
      default: {
        gateId: '0',
        terms: '0',
        isOnce: false,
        result: '',
      },
    },
    existGateId: {
      default: [],
    },
    loading: {
      default: false,
    },
    errorMessageLogs: {
      default: '',
    },
    successMessageLogs: {
      default: '',
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: '鍵共有', disabled: true },
          { text: '鍵共有キー発行', disabled: true },
        ];
      },
    },
  },
});
