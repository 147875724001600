































import Vue from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import CardDetailForm from '@/components/organisms/CardDetailForm/CardDetailForm.vue';
import * as Config from '@/config';
export default Vue.extend({
  name: 'CardDetailtemplate',
  components: {
    Breadcrumbs,
    CardDetailForm,
  },
  props: {
    errorMessageLogs: {
      type: String,
      default: '',
    },
    cardDetail: {
      default: {},
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'カード', link: true, exact: true, disabled: false, to: { path: Config.CARD_LIST} },
          { text: 'カード詳細', disabled: true },
        ];
      },
    },
  },
});
