










































import Vue, { PropType } from 'vue';
import CardAddForm from '@/components/organisms/CardAddForm/CardAddForm.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import * as Config from '@/config';
interface TypeSelect {
  text: string;
  value: number;
}

export default Vue.extend({
  name: 'CardAddtemplate',
  components: {
    CardAddForm,
    Breadcrumbs,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    userItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    cardDetail: {
      default: {},
    },
    isResetData: {
      default: false,
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
         { text: 'カード', link: true, exact: true, disabled: false, to: { path: Config.CARD_LIST} },
          { text: this.isEdit ? 'カード編集' : '新規カード登録', disabled: true },
        ];
      },
    },
  },
});
