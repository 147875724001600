





























































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';
import UsersAllowedTable from '@/components/organisms/UsersAllowedTable/UsersAllowedTable.vue';
import AccessPermissionGroupTable from '@/components/organisms/AccessPermissionGroupTable/AccessPermissionGroupTable.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
export interface DataType {
  dataAllow: any;
  rulesValidate: any;
  listAff: any;
  departmentInfo: any;
  dataDirectUser: any;
  dataDirectGroup: any;
}

export default Vue.extend({
  name: 'AffiliationAdd',
  components: {
    ShowPerPage,
    Pagination,
    DisplayOption,
    SelectPerPage,
    TextLink,
    UsersAllowedTable,
    AccessPermissionGroupTable,
    InputForm,
    Modal,
  },
  props: {
    successMessageLogs: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    expandData: {
      type: Object,
      default: ({}),
    },
    authority: {
      type: Object,
      default: ({}),
    },
    userItems: {
      type: Array,
      default: [],
    },
  },
  mounted() {
    //
  },
  watch: {
    //
    userItems(data: any) {
      if (data.length > 0) {
        const dataAllow  = Array.of(...data);
        this.dataAllow = dataAllow;
      }
    },
  },
  data: (): DataType => ({
    rulesValidate: {
      requiredName: (name: string) => !!name || '所属名は必須です。',
      validateName: (name: string) => {
        if (name !== null && name !== undefined && name !== '') {
          return name.length <= 64 || '所属名は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
    },
    dataAllow: [],
    departmentInfo: {},
    listAff: [{
      userId: '',
      isAdminToView: true,
      isAdminToAddUser: false,
      isAdminToEditUser: false,
      isAdminToSetPcodesToUser: false,
    }],
    dataDirectUser: {
      loading: false,
      total: 0,
      perPage: 25,
      page: 1,
      data: [],
      tmp: [],
      keyword: '',
      scorp: ['userId', 'name'],
    },
    dataDirectGroup: {
      loading: false,
      total: 0,
      perPage: 25,
      page: 1,
      data: [],
      tmp: [],
      keyword: '',
      scorp: ['name'],
    },
  }),
  computed: {
    //
  },
  methods: {
    addAffiliation() {
      this.listAff.push({
        userId: '',
        isAdminToView: true,
        isAdminToAddUser: false,
        isAdminToEditUser: false,
        isAdminToSetPcodesToUser: false,
      });
    },
    changeAllow() {
      this.dataAllow.forEach((element: any, key: any) => {
        const check = this.listAff.some((item: any) => item.userId.value === element.value);
        if (check) {
          this.dataAllow[key].disabled = true;
        }
      });
    },
    affiliationAddItemDirect(item: any) {
      this.dataDirectUser.keyword = '';
      this.dataDirectUser.data = item.filter((element: any) =>  element.departmentName === '');
      this.dataDirectUser.data.map((v: any) => Object.assign(v, {user: {
        userId: v.userId,
        name: v.name,
        isValid: v.isValid,
      }}));
      this.dataDirectUser.tmp = this.dataDirectUser.data;
      this.dataDirectUser.total = this.dataDirectUser.tmp.length;
    },
    affiliationRemoveItemDirect(data: any) {
      this.dataDirectUser.page = 1;
      data.forEach((item: any) => {
        const i = this.dataDirectUser.data.map((e: any) => e.userId).indexOf(item.userId);
        if (i >= 0) {
          this.dataDirectUser.data.splice(parseInt(i), 1);
        }
        const j = this.dataDirectUser.tmp.map((e: any) => e.userId).indexOf(item.userId);
        if (j >= 0) {
          this.dataDirectUser.tmp.splice(parseInt(j), 1);
        }
      });
      this.dataDirectUser.total = this.dataDirectUser.tmp.length;
    },
    searchItemDirect(agr: any) {
      this.dataDirectUser.scorp = agr.targets;
      this.dataDirectUser.keyword = agr.text;
      this.dataDirectUser.page = 1;
      this.loadDataDirect();
    },
    changePageUserDirect(agr: any) {
      this.dataDirectUser.page = agr.page;
      this.loadDataDirect();
    },
    loadDataDirect() {
      let dataOri: any = this.dataDirectUser.tmp;
      console.log('dataOri', dataOri);
      if (this.dataDirectUser.keyword !== '') {
          if (this.dataDirectUser.scorp.length === 2) {
            dataOri = dataOri.filter((element: any) =>
            element.user.name.toLowerCase().includes(this.dataDirectUser.keyword.toLowerCase()) === true ||
            element.user.userId.toLowerCase().includes(this.dataDirectUser.keyword.toLowerCase()) === true);
          } else {
            //
            if (this.dataDirectUser.scorp[0] === 'name') {
              dataOri = dataOri.filter((element: any) =>
              element.user.name.toLowerCase().includes(this.dataDirectUser.keyword.toLowerCase()) === true);
            } else {
              dataOri = dataOri.filter((element: any) =>
              element.user.userId.toLowerCase().includes(this.dataDirectUser.keyword.toLowerCase()) === true);
            }
          }
        }
      this.dataDirectUser.data =
      dataOri.slice((this.dataDirectUser.page - 1) * 25, this.dataDirectUser.page * 25);
    },
    addGroupDirect(item: any) {
      this.dataDirectGroup.keyword = '';
      this.dataDirectGroup.data = item;
      this.dataDirectGroup.data.map((v: any) => Object.assign(v, {group: {
        groupId: v.groupId,
        name: v.name,
        gateCount: v.gateCount,
        passableTimeSetting: v.passableTimeSetting,
      }}));
      this.dataDirectGroup.tmp = this.dataDirectGroup.data;
      this.dataDirectGroup.total = this.dataDirectGroup.tmp.length;
    },
    removeGroupDirect(data: any) {
      this.dataDirectGroup.keyword = '';
      this.dataDirectGroup.page = 1;
      data.forEach((item: any) => {
        const i = this.dataDirectGroup.data.map((e: any) => e.groupId).indexOf(item.groupId);
        if (i >= 0) {
          this.dataDirectGroup.data.splice(parseInt(i), 1);
        }
        const j = this.dataDirectGroup.tmp.map((e: any) => e.groupId).indexOf(item.groupId);
        if (j >= 0) {
          this.dataDirectGroup.tmp.splice(parseInt(j), 1);
        }
      });
      this.dataDirectGroup.total = this.dataDirectGroup.tmp.length;
    },
    searchGroupDirect(agr: any) {
      this.dataDirectGroup.scorp = agr.targets;
      this.dataDirectGroup.keyword = agr.text;
      this.dataDirectGroup.page = 1;
      this.loadDataGroupDirect();
    },
    changePageGroupDirect(val: any) {
      this.dataDirectGroup.page = val;
      this.loadDataGroupDirect();
    },
    loadDataGroupDirect() {
      let dataOri: any = this.dataDirectGroup.tmp;
      console.log('dataOri', dataOri);
      if (this.dataDirectGroup.keyword !== '') {
        if (this.dataDirectGroup.scorp.length > 0) {
          dataOri = dataOri.filter((element: any) =>
            element.group.name.toLowerCase().includes(this.dataDirectGroup.keyword.toLowerCase()) === true);
        }
      }
      this.dataDirectGroup.data =
      dataOri.slice((this.dataDirectGroup.page - 1) * 25, this.dataDirectGroup.page * 25);
    },
    registerAff() {
        //
        const result = (this.$refs.AffAddForm as Vue & { validate: () => boolean }).validate();
        if (result) {
          this.$emit('register-aff', {
            data: this.listAff,
            name: this.departmentInfo.name,
            userIds: this.dataDirectUser.tmp,
            groupIds: this.dataDirectGroup.tmp,
          });
        }
      },
    deleteAff(index: any) {
      const i = this.dataAllow.map((e: { value: any; }) =>
      e.value).indexOf(this.listAff[index].userId.value);
      if (i >= 0) {
        this.dataAllow[i].disabled = false;
      }
      this.listAff.splice(parseInt(index), 1);
    },
  },
});
