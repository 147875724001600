










































































































import Vue from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UsersTemplate from '@/components/templates/UsersTemplate/UsersTemplate.vue';
import * as Config from '@/config';
import moment from 'moment';
import { UserRegister } from '@/types/alligate';
import AlligateUtils from '@/utils/alligate';
let countChange = 2;
const countChangeExport = 2;
const countChange1 = 2;
export interface DataType {
  // Props
  users: any;
  loading: boolean;
  loadingGroup: boolean;
  maxRecord: number;
  page: number;
  perPage: number;
  showModalProcess: boolean;
  progress: number;
  success: boolean;
  totalDataRowCSV: number;
  tempGroupPassages: any;
  ExpandedGroup: any;
  ExpandedGroupDefault: any;
  cardItems: any;
  phoneItems: any;
  errorMessageLogs: string;
  listGroupPassages: any;
  listphones: any;
  successMessageLogs: string;
  listGatePassages: any;
  successItemCSV: number;
  errorMessage: any;
  gateList: any;
  groupDetail: any;
  message: string;
  groupId: string;
  // Page
  keyword: string;
  currPage: number;
  scorp: any;
  exeGateGroup: number;
  userDataLoad: number;
  exportUser: any;
  percentUser: number;
  pagesUser: number;
  isSendEmail: boolean;
  importDataCSV: any;
  exeCountCSV: number;
  percent: number;
  pageGroup: number;
  maxGroup: number;
  countGetDetailGroup: number;
  userId: string;
  action: number;
  loadingGate: boolean;
  isExport: boolean;
  showModalConfirmEmail: boolean;

  // Excel
  showModalImport: boolean;
  showProcessImportExcel: boolean;
  exportType: number;
  typeConfirm: number;
  successItem: number;
  totalDataRow: number;
  percentProcess: number;
  errorMessageExcel: any;
  isFinishImportExcel: boolean;
  isLoading: boolean;
  UserSheetImportData: any;
  sheetUserName: string;
  sheetGroupName: string;
  rowinErrDialog: number;
  errorMessageProcess: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  showProgress: boolean;
  isFinishProcess: boolean;
  showConfirm: boolean;
  typeAction: number;
  isGetDataList: boolean;
  isValid: boolean;
  successItemSheet2: number;
  totalDataRowSheet2: number;
  progressSheet2: number;
  percentProcessSheet2: number;

  groupToSearch: any;
  groupIds: any;
  gateIds: any;
  countColumn: number;
  typeOfSearch: number;
  groupIdToSearch: string;
  pageGate: number;
  isType: string;
  groupGateArray: any;
  userIdArray: any;
  userInGroupMx: any;
  exportGroupData: any;
  exportUserData: any;
  exportGroupPassages: any;
  isHasLowerCaseCardNumberExcel: boolean;
  maxPageGroup: number;
  maxPageUser: number;
  pcodesTmp: any;
  isExportUserInfoCSV: boolean;
  // New Update
  ExpandedData: any;
  ExpandedDataDefault: any;
  gatePassageIds: any;
  departmentAddFail: any;
  departments: any;
  dataAllow: any;
  isCountCSV: any;
  isCountExcel: any;
}

export default Vue.extend({
  name: 'UserListPage',
  components: {
    Breadcrumbs,
    SuccessButton,
    ActionButton,
    UsersTemplate,
  },
  props: {
    authority: {
      type: Object,
      default: ({}),
    },
  },
  async created() {
    if (typeof localStorage.listUserAddSortKey === 'undefined') {
      localStorage.setItem('listUserAddSortKey', 'userId');
    }
    if (typeof localStorage.isVaildDataUser === 'undefined') {
      localStorage.setItem('isVaildDataUser', 'true');
    }
    if (typeof localStorage.listUserAddSortName === 'undefined') {
      localStorage.setItem('listUserAddSortName', 'desc');
    }
    if (typeof localStorage.pageSizeUserList === 'undefined') {
      localStorage.setItem('pageSizeUserList', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeUserList);
    }
    this.isGetDataList = true;
    this.getAllGroups(1);
    this.departments = [];
    this.dataAllow = [];
    this.getDepartment(1);
    await this.getDepartmentAuthAll(localStorage.userProfileId, 1);
    this.getList();
  },
  mounted() {
    this.$emit('activeMenu', 'user');
  },
  watch: {
    userDataLoad(newVal, oldVal) {
      console.log('newVal', newVal);
      console.log('pagesUser', this.pagesUser);
      if (newVal === this.pagesUser) {
        this.downloadCSV();
        this.reset();
        this.success = true;
      } else if (newVal > 0) {
        this.getDataForUser(newVal + 1);
      }
    },
    loading(value) {
      this.$emit('loading', value);
    },
    exeCountCSV(newVal, oldVal) {
      if (newVal > 0 && newVal < this.totalDataRowCSV) {
        if (this.importDataCSV[newVal].length === (18 + countChange)) {
          // Import user
          const dataItem = {
            userId: this.importDataCSV[newVal][0],
            password: this.importDataCSV[newVal][1],
            name: this.importDataCSV[newVal][2],
            furigana: this.importDataCSV[newVal][3],
            email: this.importDataCSV[newVal][4],
            isPasswordLoginValid: this.importDataCSV[newVal][5],
            isMfaSms: this.importDataCSV[newVal][6],
            phoneNumber: this.importDataCSV[newVal][7],
            isAdmin: this.importDataCSV[newVal][8],
            derpartmentId: this.importDataCSV[newVal][9],
            isPhoneAdmin: this.importDataCSV[newVal][9 + countChange],
            shareKeyBeValid: this.importDataCSV[newVal][10 + countChange],
            passCode: this.importDataCSV[newVal][11 + countChange],
            thumbnail: this.importDataCSV[newVal][12 + countChange],
            option1: this.importDataCSV[newVal][13 + countChange],
            option2: this.importDataCSV[newVal][14 + countChange],
            isValid: this.importDataCSV[newVal][15 + countChange],
          };
          this.editExe(dataItem, (newVal + 2), this.percent);
        }
      } else if (newVal > 0 && newVal === this.totalDataRowCSV) {
        this.exeCountCSV = 0;
        this.success = true;
        this.importDataCSV = [];
      }
    },
    countGetDetailGroup(value) {
      if (value > 0 && this.maxGroup <= value) {
        this.ExpandedGroup[this.userId].groups.sort((a: any, b: any) =>
        (a.groupName.toLowerCase() < b.groupName.toLowerCase() ? -1 : 1));
        this.ExpandedGroupDefault[this.userId].groups.sort((a: any, b: any) =>
        (a.groupName.toLowerCase() < b.groupName.toLowerCase() ? -1 : 1));
        this.loadingGroup = false;
      }
    },
  },
  data: (): DataType => ({
    // Props
    users: [],
    loading: false,
    maxRecord: 0,
    page: 1,
    perPage: Config.PAGE_DEFAULT,
    showModalProcess: false,
    progress: 0,
    success: false,
    totalDataRowCSV: 0,
    tempGroupPassages: [],
    cardItems: [],
    phoneItems: [],
    errorMessageLogs: '',
    successMessageLogs: '',
    listGroupPassages: [],
    listphones: [],
    listGatePassages: [],
    successItemCSV: 0,
    errorMessage: [],
    message: '',
    groupDetail: [],
    // Page
    keyword: '',
    currPage: 1,
    scorp: ['userId'],
    exeGateGroup: 0,
    userDataLoad: 0,
    exportUser: [],
    percentUser: 0,
    pagesUser: 0,
    isSendEmail: false,
    isExport: false,
    importDataCSV: [],
    exeCountCSV: 0,
    percent: 0,
    pageGroup: 1,
    ExpandedGroup: {},
    ExpandedGroupDefault: {},
    ExpandedData: {},
    ExpandedDataDefault: {},
    gateList: {},
    loadingGroup: false,
    showModalConfirmEmail: false,
    typeConfirm: 0,

    // Excel
    showModalImport: false,
    exportType: 0, // 0: CSV (user), 1: CSV (gate), 2: Excel
    showProcessImportExcel: false,
    successItem: 0,
    totalDataRow: 0,
    percentProcess: 0,
    errorMessageExcel: [],
    isFinishImportExcel: false,
    isLoading: false,
    UserSheetImportData: [],
    sheetUserName: '',
    sheetGroupName: '',
    rowinErrDialog: 0,
    maxGroup: 0,
    countGetDetailGroup: 0,
    errorMessageProcess: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    showProgress: false,
    typeAction: 0,
    isFinishProcess: false,
    showConfirm: false,
    isGetDataList: false,
    isValid: false,
    userId: '',
    action: 0,
    pageGate: 1,
    groupToSearch: [],
    groupIds: [],
    gateIds: [],
    typeOfSearch: 0,
    groupIdToSearch: '',
    groupId: '',
    loadingGate: false,
    isHasLowerCaseCardNumberExcel: false,
    isType: '',
    groupGateArray: [],
    userIdArray: [],
    userInGroupMx: [],
    successItemSheet2: 0,
    totalDataRowSheet2: 0,
    progressSheet2: 0,
    percentProcessSheet2: 0,
    countColumn: 1,
    maxPageGroup: 1,
    exportGroupData: [],
    exportUserData: [],
    exportGroupPassages: [],
    pcodesTmp: [],
    maxPageUser: 1,
    isExportUserInfoCSV: false,
    gatePassageIds: [],
    departmentAddFail: [],
    departments: [],
    dataAllow: [],
    isCountCSV: false,
    isCountExcel: false,
  }),
  methods: {
    reset() {
      this.exportUser = [];
      this.userDataLoad = 0;
      this.exeCountCSV = 0;
      this.successItemCSV = 0;
      this.errorMessage = [];
      this.percent = 0;
      this.loading = false;
      this.success = false;
      this.errorMessageLogs = '';
    },
    changeExpanded(payload: any) {
      console.log(payload);
      const data = payload.event;
      if (data.value === true) {
        // get gate/passage
        // this.userId =  data.item.userId;
        const userId =  data.item.userId;
        this.$set(this.ExpandedData, userId, []);
        this.$set(this.ExpandedDataDefault, userId, []);
        this.ExpandedData[userId] = {
          maxRecords: 0,
          data: [],
          loading : true,
          page: 1,
        };
        // this.getGatePassage(userId, 1);
      }
    },
    ShowOnlyIsVaild(data: any) {
      localStorage.setItem('isVaildDataUser', data.toString());
      this.getList();
    },
    CancelProcessCSV() {
      this.totalDataRowCSV = 0;
      this.successItemCSV = 0;
      this.importDataCSV = [];
      this.exeCountCSV = this.totalDataRowCSV;
      this.showModalProcess = false;
      this.errorMessage = [];
      this.showModalConfirmEmail = false;
      this.reset();
    },
    closeModalProcessCSV() {
      if (this.successItemCSV > 0) {
        this.isCountCSV = true;
      }
      this.reset();
      this.showModalProcess = false;
      this.showModalConfirmEmail = false;
      this.errorMessage = [];
    },
    clickConfirmEmail(data: any) {
      this.typeConfirm  = data.type;
      this.showModalProcess = false;
      this.showModalConfirmEmail = true;
    },
    clickExportCSV(isCheckDataCSV: any) {
      this.isExport = true;
      this.errorMessageLogs = '';
      this.showModalProcess = true;
      this.exportUser = [];
      this.progress = 0;
      this.userDataLoad = 0;
      this.success = false;
      this.successItemCSV = 0;
      if (isCheckDataCSV) {
        this.getDataForUser(1);
      } else {
        this.getDataForHeaderCSV();
      }
    },
    clickExportCSVTimeUnlockGate() {
      this.isExport = true;
      this.errorMessageLogs = '';
      this.showModalProcess = true;
      this.progress = 0;
      this.success = false;
      this.successItemCSV = 0;
      this.progress = 100;
      this.downloadCSVTimeUnlockGate();
      this.reset();
      this.success = true;
    },
    clickImportCSV(data: any) {
      countChange = 2;
      this.isExport = false;
      this.success = false;
      this.showModalProcess = true;
      this.errorMessageLogs = '';
      this.errorMessage = [];
      this.isSendEmail = data.isSendEmail;
      this.isCountCSV = false;
      this.isCountExcel =  false;
      this.importCSV(data);
    },
    clickImportCSVTimeUnlockGate(data: any) {
      this.isExport = false;
      this.showModalProcess = true;
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.importCSVTimeUnlockGate(data);
    },
    closeDialogConfirm() {
      this.showProgress = false;
      this.showConfirm = false;
      this.groupIds = [];
      this.userId = '';
    },
    closeModalProgress(data: any) {
      console.log('data', data);
      this.showProgress = false;
      this.showConfirm = false;
      this.showModalConfirmEmail = false;
      if (this.successItemProcess > 0) {
        if (data.action === 4 && this.userId !== '') {
          const userId =  this.userId;
          console.log('userId', userId);
          this.$set(this.ExpandedData, userId, []);
          this.$set(this.ExpandedDataDefault, userId, []);
          this.ExpandedData[userId] = {
            maxRecords: 0,
            data: [],
            loading : true,
            page: 1,
          };
          this.getGatePassage(userId, 1);
        } else if (data.action === 5 && this.groupId !== '') {
          this.gateList = {};
          this.pageGroup = 1;
          this.countGetDetailGroup = 0;
          this.$set(this.ExpandedGroup, this.userId, []);
          this.$set(this.ExpandedGroupDefault, this.userId, []);
          this.getExpandedGroup(this.userId);
          this.$set(this.gateList, this.groupId, []);
          this.getGates(this.groupId);
          this.getList();
        } else {
          this.getList();
        }
      }
    },
    confirmAction(data: any) {
      console.log(data);
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = false;
      if (data.action === 2 || data.action === 3) {
        this.userId = data.userId;
        this.isValid = data.isValid;
      } else if (data.action === 4) {
        this.gatePassageIds = data.dataIds;
        this.userId =  data.userId;
      } else if (data.action === 5) {
        console.log(data.groudId);
        this.gateIds = data.gateIds;
        this.groupId =  data.groudId;
      }
      this.showConfirm = true;
      /*
        0 : Enable Muti
        1 : Disable Muti
        2 : Enable One
        3 : Disable One
        4 : Leave Group
      */
      this.action = data.action;
    },
    changeMutiUserIsvalid(data: any) {
      this.showConfirm = false;
      this.action = 0;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = true;
      this.userId = '';
      this.isFinishProcess = false;
      this.errorMessageProcess = [];
      // console.log('data', data);
      this.action = data.action;
      this.totalDataRowProcess = data.UserIds.length;
      this.updateMutiUser(data).then(() => {
        this.isFinishProcess = true;
      });
    },
    async updateMutiUser(data: any) {
      const percent = (100 / this.totalDataRowProcess);
      for (const item of data.UserIds) {
        const value = {
          userId: item,
          isValid: data.isValid,
        };
        let result;
        result  = await this.updateUser(value);
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
    },
    changeUserIsvalid(data: any) {
      this.showProgress = true;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.userId = '';
      this.isFinishProcess = false;
      // console.log('data', data);
      this.action = data.action;
      this.totalDataRowProcess = 1;
      this.errorMessageProcess = [];
      this.changeUserOneIsvalid(data).then(() => {
        this.isFinishProcess = true;
      });
    },
    async changeUserOneIsvalid(data: any) {
      const percent = (100 / this.totalDataRowProcess);
      let result;
      result = this.updateUser(data);
      if (await result) {
        this.successItemProcess++;
      }
      this.progress += percent;
    },
    updateUser(data: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.USERS_LIST_API + '/' +
        encodeURIComponent(encodeURIComponent(data.userId)), {isValid: data.isValid})
          .then((response: any) => {
            resolve(true);
          })
          .then(null, (error) => {
            let errorName = '';
            switch (error.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = data.userId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          });
      });
    },
    importCSV(data: any): void {
      const vm = this;
      const files = data.files;
      if (files !== undefined && files !== null && files.length !== 0) {
        this.progress = 0;
        this.exeCountCSV = 0;
        this.successItemCSV = 0;
        this.progress = 0;
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const lineArr = read.split('\n');
          const itemArr = [];
          for (let i = 0; i < lineArr.length; i++) {
            itemArr[i] = lineArr[i].split(',');
          }
          if (itemArr.length > 1) {
            vm.importDataCSV = [];
            for (let k = 1; k < itemArr.length; k++) {
              console.log('itemArr[k].length', itemArr[k].length);
              if (itemArr[k].length === 18) {
                countChange = 0;
              }
              if (itemArr[k].length === (18 + countChange) && itemArr[k][0].length > 0) {
                vm.importDataCSV.push(itemArr[k]);
              }
            }
            if (vm.importDataCSV.length > 0) {
              vm.totalDataRowCSV = vm.importDataCSV.length;
              vm.message = '';
              vm.percent = (100 / (vm.totalDataRowCSV));
              const dataItem = {
                userId: vm.importDataCSV[0][0],
                password: vm.importDataCSV[0][1],
                name: vm.importDataCSV[0][2],
                furigana: vm.importDataCSV[0][3],
                email: vm.importDataCSV[0][4],
                isPasswordLoginValid: vm.importDataCSV[0][5],
                isMfaSms: vm.importDataCSV[0][6],
                phoneNumber: vm.importDataCSV[0][7],
                isAdmin: vm.importDataCSV[0][8],
                derpartmentId: vm.importDataCSV[0][9],
                isPhoneAdmin: vm.importDataCSV[0][9 + countChange],
                shareKeyBeValid: vm.importDataCSV[0][10 + countChange],
                passCode: vm.importDataCSV[0][11 + countChange],
                thumbnail: vm.importDataCSV[0][12 + countChange],
                option1: vm.importDataCSV[0][13 + countChange],
                option2: vm.importDataCSV[0][14 + countChange],
                isValid: vm.importDataCSV[0][15 + countChange],
              };
              vm.editExe(dataItem, 2, vm.percent);
            } else {
              vm.success = true;
              vm.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
            }
          } else {
            vm.success = true;
            vm.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
          }
        },
        reader.onerror = (): void => {
          vm.errorMessageLogs = '選択されているファイルの読み込みに失敗しました。<br>ブラウザの更新、もしくは別ファイルを選択してから<br>再度アップロードしたいファイルを選択してください。';
          this.success = true;
        };
        reader.readAsText(files, 'Shift_JIS');
      } else {
        vm.errorMessageLogs = 'CSVのファイルを選択してください。';
        this.success = true;
      }
    },
    async editExe(data: any, z: any, percent: any) {
      const vm = this;
      let tmpIsSendEmail = false;
      if (data.email !== null && data.email !== '') {
        tmpIsSendEmail = vm.isSendEmail;
      } else {
        tmpIsSendEmail = false;
      }
      data.phoneNumber = (data.phoneNumber.trim()).replace(/\u200B/g, '');
      const phone = data.phoneNumber.substring(1);
      const checkMfaSms = vm.convertStringToBoolean(data.isMfaSms, false);
      const checkPasswordLoginValid = vm.convertStringToBoolean(data.isPasswordLoginValid, true);
      let phoneNumberEnd = '';
      const Infor: any = {
        userId: data.userId,
        name: data.name,
        furigana: data.furigana,
        email: data.email,
        isMfaSms: checkMfaSms,
        isSendEmail: tmpIsSendEmail,
        isAdmin: vm.convertStringToBoolean(data.isAdmin, false),
        isPhoneAdmin: vm.convertStringToBoolean(data.isPhoneAdmin, false),
        shareKeyBeValid: vm.convertStringToBoolean(data.shareKeyBeValid, false),
        passCode: data.passCode,
        option1: data.option1,
        option2: data.option2,
        isValid: vm.convertStringToBoolean(data.isValid, true),
      };
      if (checkMfaSms === true) {
        if (data.phoneNumber.match(/^[0][0-9]{10}$/)) {
          phoneNumberEnd = '+81' + phone;
        }
        Infor.phoneNumber = phoneNumberEnd;
      }
      const checkUserInfo: any = await this.getUserInfo(data.userId);
      if (checkUserInfo === null) {
        if (checkPasswordLoginValid === true) {
          Infor.password = data.password;
        }
        Infor.isPasswordLoginValid = checkPasswordLoginValid;
        if (data.derpartmentId !== '' && countChange > 0) {
          Infor.departmentIds = [data.derpartmentId];
        }
        console.log('Infor', Infor);
        vm.addExe(data, Infor, z, percent);
      } else {
        if (checkPasswordLoginValid !== checkUserInfo.isPasswordLoginValid) {
          if (checkPasswordLoginValid === true) {
            Infor.password = data.password;
          }
          Infor.isPasswordLoginValid = checkPasswordLoginValid;
        }
        this.$http.put(Config.USERS_LIST_API + '/' + encodeURIComponent(encodeURIComponent(data.userId)), Infor)
          .then(async (response: any) => {
            await this.updateDepartmentId(data.derpartmentId, data.userId);
            if (this.importDataCSV.length > 0) {
              vm.exeCountCSV++;
              vm.successItemCSV++;
              vm.progress += percent;
            }
          })
          .then( null, (error: any) => {
            let rowstatus = '';
            let errorName = '';
            if (this.importDataCSV.length > 0) {
              switch (error.status) {
                case 401:
                  if (error.body.message === 'The incoming token has expired') {
                    errorName = '';
                  } else {
                    errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                  }
                  vm.exeCountCSV++;
                  vm.progress += percent;
                  break;
                case 400:
                  vm.exeCountCSV++;
                  errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                  rowstatus = z + ' 行目>>' + data.userId + '>><span>' + errorName + '</span>';
                  this.$set(this.errorMessage, z, rowstatus);
                  vm.progress += percent;
                  console.log(error);
                  break;
                case 403:
                  vm.exeCountCSV++;
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                  rowstatus = z + ' 行目>>' + data.userId + '>><span>' + errorName + '</span>';
                  this.$set(this.errorMessage, z, rowstatus);
                  vm.progress += percent;
                  console.log(error);
                  break;
                case 404:
                  break;
                case 500:
                  vm.exeCountCSV++;
                  errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                  rowstatus = z + ' 行目>>' + data.userId + '>><span>' + errorName + '</span>';
                  this.$set(this.errorMessage, z, rowstatus);
                  vm.progress += percent;
                  break;
                case 503:
                  vm.exeCountCSV++;
                  errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                  rowstatus = z + ' 行目>>' + data.userId + '>><span>' + errorName + '</span>';
                  this.$set(this.errorMessage, z, rowstatus);
                  vm.progress += percent;
                  console.log(error);
                  break;
                default:
                  vm.exeCountCSV++;
                  errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                  rowstatus = z + ' 行目>>' + data.userId + '>><span>' + errorName + '</span>';
                  this.$set(this.errorMessage, z, rowstatus);
                  vm.progress += percent;
                  console.log(error);
                  break;
              }
            }
          });
      }
    },
    addExe(data: any, Infor: any, z: any, percent: any) {
      const vm = this;
      this.$http.post(Config.USERS_LIST_API, Infor)
        .then(async (response: any) => {
          if (response.body.message === 'Success') {
            if (this.importDataCSV.length > 0) {
              vm.exeCountCSV++;
              vm.successItemCSV++;
              vm.progress += percent;
            }
          } else {
            let rowstatus = '';
            let errorName = '';
            errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
            rowstatus = z + ' 行目>>' + Infor.userId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessage, z, rowstatus);
            vm.progress += percent;
          }
        })
        .then( null, (error: any) => {
          if (this.importDataCSV.length > 0) {
            vm.exeCountCSV++;
            let rowstatus = '';
            let errorName = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                rowstatus = z + ' 行目>>' + Infor.userId + '>><span>' + errorName + '</span>';
                break;
              case 403:
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                rowstatus = z + ' 行目>>' + Infor.userId + '>><span>' + errorName + '</span>';
                break;
              case 409:
                errorName = '入力されたユーザーIDは既に使用されています。他のユーザーIDを入力してください。';
                rowstatus = z + ' 行目>>' + Infor.userId + '>><span>' + errorName + '</span>';
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + Infor.userId + '>><span>' + errorName + '</span>';
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + Infor.userId + '>><span>' + errorName + '</span>';
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = z + ' 行目>>' + Infor.userId + '>><span>' + errorName + '</span>';
                break;
            }
            this.$set(this.errorMessage, z, rowstatus);
            vm.progress += percent;
            console.log(error);
          }
        });
    },
    importCSVTimeUnlockGate(data: any): void {
      const vm = this;
      const files = data.files;
      if (files !== undefined && files !== null && files.length !== 0) {
        this.progress = 0;
        this.exeCountCSV = 0;
        this.successItemCSV = 0;
        this.progress = 0;
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const lineArr = read.split('\n');
          const itemArr = [];
          for (let i = 0; i < lineArr.length; i++) {
            itemArr[i] = lineArr[i].split(',');
          }
          if (itemArr.length > 1) {
            vm.importDataCSV = [];
            for (let k = 1; k < itemArr.length; k++) {
              if (itemArr[k].length !== 4) {
                continue;
              }
              if (itemArr[k][0].length > 0 && itemArr[k][1].length > 0 &&
                  itemArr[k][2].length !== 12 && itemArr[k][3].length !== 12) {
                continue;
              }
              // yyyyMMddhhmm -> yyyy-MM-dd hh:mm
              itemArr[k][2] = vm.formatDateTime(itemArr[k][2]);
              itemArr[k][3] = vm.formatDateTime(itemArr[k][3]);
              vm.importDataCSV.push(itemArr[k]);
            }
            console.log('importDataCSV', vm.importDataCSV);
            if (vm.importDataCSV.length > 0) {
              let countCallApi = 0;
              vm.totalDataRowCSV = vm.importDataCSV.length;
              vm.message = '';
              vm.percent = (100 / (vm.totalDataRowCSV));
              // call import time-limited unlockable gate every 100ms
              const importGateLoop = window.setInterval(() => {
                const dataItem = {
                  userId: this.importDataCSV[countCallApi][0],
                  gateId: this.importDataCSV[countCallApi][1],
                  notBefore: this.formatTimestamp(this.importDataCSV[countCallApi][2]),
                  notAfter: this.formatTimestamp(this.importDataCSV[countCallApi][3]),
                };
                this.startAddGatePassageItem(dataItem, (countCallApi + 2), this.percent);
                countCallApi = countCallApi + 1;
                // check number of calling Api
                if (countCallApi > 0 && countCallApi === this.totalDataRowCSV) {
                  clearInterval(importGateLoop);
                }
              }, 100);
            } else {
              vm.success = true;
              vm.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
            }
          } else {
            vm.success = true;
            vm.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
          }
        },
        reader.onerror = (): void => {
          vm.errorMessageLogs = '選択されているファイルの読み込みに失敗しました。<br>ブラウザの更新、もしくは別ファイルを選択してから<br>再度アップロードしたいファイルを選択してください。';
          this.success = true;
        };
        reader.readAsText(files, 'Shift_JIS');
      } else {
        vm.errorMessageLogs = 'CSVのファイルを選択してください。';
        this.success = true;
      }
    },
    startAddGatePassageItem(data: any, z: any, percent: any) {
      const vm = this;
      this.$http.post(Config.GATES_PASSAGE_API, data)
        .then((response: any) => {
          if (this.importDataCSV.length > 0) {
            vm.exeCountCSV++;
            vm.successItemCSV++;
            vm.progress += percent;
          }
        })
        .then( null, (error: any) => {
          let rowstatus = '';
          let errorName = '';
          if (this.importDataCSV.length > 0) {
            switch (error.status) {
              case 400:
                vm.exeCountCSV++;
                errorName = Config.ERROR_400;
                rowstatus = z + ' 行目>>' + data.userId + ', ' + data.gateId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessage, z, rowstatus);
                vm.progress += percent;
                console.log(error);
                break;
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = Config.ERROR_401;
                }
                vm.exeCountCSV++;
                vm.progress += percent;
                break;
              case 403:
                vm.exeCountCSV++;
                errorName = Config.ERROR_403;
                rowstatus = z + ' 行目>>' + data.userId + ', ' + data.gateId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessage, z, rowstatus);
                vm.progress += percent;
                console.log(error);
                break;
              case 404:
                vm.exeCountCSV++;
                errorName = Config.ERROR_404_GATE_PASSAGE;
                rowstatus = z + ' 行目>>' + data.userId + ', ' + data.gateId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessage, z, rowstatus);
                vm.progress += percent;
                break;
              case 409:
                vm.exeCountCSV++;
                errorName = Config.ERROR_409_GATE_PASSAGE;
                rowstatus = z + ' 行目>>' + data.userId + ', ' + data.gateId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessage, z, rowstatus);
                vm.progress += percent;
                break;
              case 500:
                vm.exeCountCSV++;
                errorName = Config.ERROR_500;
                rowstatus = z + ' 行目>>' + data.userId + ', ' + data.gateId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessage, z, rowstatus);
                vm.progress += percent;
                break;
              case 503:
                vm.exeCountCSV++;
                errorName = Config.ERROR_503;
                rowstatus = z + ' 行目>>' + data.userId + ', ' + data.gateId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessage, z, rowstatus);
                vm.progress += percent;
                console.log(error);
                break;
              default:
                vm.exeCountCSV++;
                errorName = Config.ERROR_DEFAULT;
                rowstatus = z + ' 行目>>' + data.userId + ', ' + data.gateId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessage, z, rowstatus);
                vm.progress += percent;
                console.log(error);
                break;
            }
          }
        });
    },
    formatDateTime(input: string): string {
      const year = input.substring(0, 4);
      const month = input.substring(4, 6);
      const day = input.substring(6, 8);
      const hour = input.substring(8, 10);
      const minute = input.substring(10, 12);
      return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    convertStringToBoolean(text: any, value: any) {
      const str = text.trim().toLowerCase();
      switch (str) {
        case '1':
          return true;
        case '0':
          return false;
        default:
          return value;
      }
    },
    // Export
    getDataForUser(page: any) {
      const vm = this;
      this.$http.get(Config.USERS_LIST_API + '?limit=1000&page=' + page +
      '&isExceptOptionalUser=true&exclude=thumbnail&sortKey=desc:userId')
        .then(async (response: any) => {
          if (page === 1) {
            this.pagesUser = Math.ceil((response.body.maxRecords) / 1000);
            this.percentUser = (100 / (response.body.maxRecords));
          }
          for (let i = 0; i < response.body.users.length; i += 10) {
              const chunk = response.body.users.slice(i, i + 10);
              const arrayPromise: any = [];
              for (const [index, item] of  chunk.entries()) {
                const promise = AlligateUtils.user.getDepartmentUser(this, item.userId);
                const result = Promise.all([promise]).then((values) => {
                  const relationsDepartmentUser: any = values[0];
                  vm.exportUser.push({
                    'ユーザーID': item.userId,
                    'ログインパスワード（読み取り、変更不可）': '',
                    'ユーザー名': item.name,
                    'ふりがな': item.furigana,
                    'メールアドレス': item.email,
                    'アプリへのログイン(1:有効.0:無効)': vm.converToBit(item.isPasswordLoginValid),
                    'SMS認証(1:有効.0:無効)': vm.converToBit(item.isMfaSms),
                    '電話番号': String.fromCharCode(8203) + item.phoneNumber.replace('+81', '0'),
                    '組織管理権限(1:有効.0:無効)': vm.converToBit(item.isAdmin),
                    '所属ID': relationsDepartmentUser.length > 0 && relationsDepartmentUser[0].department !== undefined ?
                    relationsDepartmentUser[0].department.departmentId : '',
                    '所属名': relationsDepartmentUser.length > 0 && relationsDepartmentUser[0].department !== undefined ?
                    relationsDepartmentUser[0].department.name : '',
                    'ゲート設定権限ステータス(1:有効.0:無効)': vm.converToBit(item.isPhoneAdmin),
                    '鍵共有権限ステータス(1:有効.0:無効)': vm.converToBit(item.shareKeyBeValid),
                    '二段階認証パスワード': item.passCode,
                    'サムネイル': '',
                    '備考１': item.option1,
                    '備考２': item.option2,
                    'ステータス(1:有効.0:無効)': vm.converToBit(item.isValid),
                    '登録日（読み取り専用）': vm.converToDate(item.registered),
                    '更新日（読み取り専用)': vm.converToDate(item.updated),
                  });
                  vm.progress += vm.percentUser;
                });
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
          }
          const key = 'ユーザーID';
          vm.exportUser.sort((a: any, b: any) => (a[key].toLowerCase() > b[key].toLowerCase() ? -1 : 1));
          console.log('vm.userDataLoad', vm.userDataLoad);
          vm.userDataLoad++;
        })
        .then(null, (err: any) => {
          vm.progress += vm.percentUser;
          vm.userDataLoad++;
          console.log('vm.userDataLoad', vm.userDataLoad);
          console.log(err);
        });
    },
    getDataForHeaderCSV() {
      const vm = this;
      vm.progress = 100;
      this.downloadCSV();
      this.reset();
      this.success = true;
    },
    downloadCSV() {
      const saveAs = require('file-saver');
      let filename;
      if (this.exportUser.length === 0) {
        this.exportUser.push({
            'ユーザーID': '',
            'ログインパスワード（読み取り、変更不可）': '',
            'ユーザー名': '',
            'ふりがな': '',
            'メールアドレス': '',
            'アプリへのログイン(1:有効.0:無効)': '',
            'SMS認証(1:有効.0:無効)': '',
            '電話番号': '',
            '組織管理権限(1:有効.0:無効)': '',
            '所属ID': '',
            '所属名': '',
            'ゲート設定権限ステータス(1:有効.0:無効)': '',
            '鍵共有権限ステータス(1:有効.0:無効)': '',
            '二段階認証パスワード': '',
            'サムネイル': '',
            '備考１': '',
            '備考２': '',
            'ステータス(1:有効.0:無効)': '',
            '登録日（読み取り専用）': '',
            '更新日（読み取り専用)': '',
          });
      }
      const csv = this.convertArrayOfObjectsToCSV({
        data: this.exportUser,
      });
      filename = 'user-data.csv';
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([ bom, csv ], {type: 'text/csv;charset=utf-8;'});
      // ダウンロード実行
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        saveAs(blob, filename);
      } else {
        console.log('window.navigator.msSaveOrOpenBlob', window.navigator.msSaveOrOpenBlob);
        if (window.navigator.msSaveOrOpenBlob !== undefined) {
          // IEの場合
          navigator.msSaveBlob(blob, filename);
        } else {
          // IE以外(Chrome, Firefox)
          // const downloadLink = $('<a></a>');
          const downloadLink =  document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          // $('body').append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      }
    },
    downloadCSVTimeUnlockGate() {
      const saveAs = require('file-saver');
      let filename;
      const timeUnlockGate: any = [];
      timeUnlockGate.push({
          ユーザーID: '',
          ゲートID: '',
          開始時間: '',
          終了時間: '',
        });
      const csv = this.convertArrayOfObjectsToCSV({
        data: timeUnlockGate,
      });
      filename = 'time-gate-data.csv';
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([ bom, csv ], {type: 'text/csv;charset=utf-8;'});
      // ダウンロード実行
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        saveAs(blob, filename);
      } else {
        console.log('window.navigator.msSaveOrOpenBlob', window.navigator.msSaveOrOpenBlob);
        if (window.navigator.msSaveOrOpenBlob !== undefined) {
          // IEの場合
          navigator.msSaveBlob(blob, filename);
        } else {
          // IE以外(Chrome, Firefox)
          // const downloadLink = $('<a></a>');
          const downloadLink =  document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          // $('body').append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      }
    },
    convertArrayOfObjectsToCSV(args: any) {
      let result: any;
      let ctr: number;
      let keys: any[];
      let columnDelimiter: string|undefined;
      let lineDelimiter: any;
      let data: any;
      data = args.data || null;
      if (data == null || !data.length) {
        return null;
      }
      columnDelimiter = args.columnDelimiter || ',';
      lineDelimiter = args.lineDelimiter || '\n';
      keys = Object.keys(data[0]);
      result = '';
      result += keys.join(columnDelimiter);
      result += lineDelimiter;
      data.forEach((item: { [x: string]: any; }) => {
        ctr = 0;
        keys.forEach((key: any) => {
          if (ctr > 0) {
            result += columnDelimiter;
          }
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      });
      return result;
    },
    expandUser(data: any) {
      const userId =  data.item.userId;
      this.cardItems = [];
      this.phoneItems = [];
      this.listGatePassages = [];
      if (userId !== undefined && data.value === true) {
        this.getInforGroup(1, userId);
        this.getPcode(1, userId);
        this.getPhone(1, userId);
        this.getInforGatePassage(1, userId);
      }
    },
    searchUser(data: any) {
      // this.loading = true;
      console.log(data);
      let keyword = data.text;
      keyword = keyword.replace(/\s/g, ',');
      this.keyword = encodeURIComponent(keyword);
      this.scorp = data.targets;
      this.currPage = 1;
      this.typeOfSearch = 0;
      this.getList();
    },
    clickAddUser() {
      this.$router.push({ path: Config.USER_ADD});
    },
    getList() {
      this.users = [];
      if (this.typeOfSearch === 0) {
        // get Data normal
        this.isGetDataList = true;
        let apiLink = '';
        this.errorMessageLogs = '';
        console.log(this.keyword);
        if (localStorage.isVaildDataUser === 'true') {
          apiLink = Config.USERS_LIST_API + '?limit=' + localStorage.pageSizeUserList +
            '&page=' + this.currPage + '&keyword=' + this.keyword + '&scorp=' + this.scorp +
            '&sortKey=' + localStorage.listUserAddSortName + ':' + localStorage.listUserAddSortKey + '&isValid=true';
        } else {
          apiLink = Config.USERS_LIST_API + '?limit=' + localStorage.pageSizeUserList +
            '&page=' + this.currPage + '&keyword=' + this.keyword + '&scorp=' + this.scorp +
            '&sortKey=' + localStorage.listUserAddSortName + ':' + localStorage.listUserAddSortKey;
        }
        apiLink = apiLink + '&isExceptOptionalUser=true&exclude=thumbnail';
        this.$http.get(apiLink)
          .then(async (response: any) => {
            if (response.body.maxRecords > 0) {
              this.maxRecord = response.body.maxRecords;
              const userPromises = response.body.users.map(async (user: any) => {
                user.company = '';
                const department: any = await this.getDepartmentUser(user.userId);
                if (department) {
                  const relationsDepartmentUser = department.relationsDepartmentUser[0];
                  user.company = relationsDepartmentUser.department.name;
                  user.departmentId = relationsDepartmentUser.department.departmentId;
                }
                return user;
              });

              this.users = await Promise.all(userPromises);
              this.currPage = Math.ceil(response.body.startRecord / this.perPage);
              this.isGetDataList = false;
            } else {
              this.users = [];
              this.maxRecord = 0;
              this.currPage = 1;
              this.isGetDataList = false;
            }
          })
          .then(null, (err: any) => {
            this.isGetDataList = false;
            this.users = [];
            this.message = '該当するデーターがありません。';
            switch (err.status) {
                case 400:
                  this.errorMessageLogs = Config.ERROR_400;
                  break;
                case 403:
                  if (err.message === 'Unauthorized') {
                    this.errorMessageLogs = Config.ERROR_403;
                  } else {
                    this.errorMessageLogs = Config.ERROR_403_DENIED;
                  }
                  break;
                case 404:
                  this.errorMessageLogs = Config.ERROR_404;
                  break;
                case 500:
                  this.errorMessageLogs = Config.ERROR_500;
                  break;
                case 503:
                  this.errorMessageLogs = Config.ERROR_503;
                  break;
                default:
                  this.errorMessageLogs = Config.ERROR_DEFAULT;
                  break;
              }
            }); // catch
      } else {
        // search by groupId
        this.getUserByDeparmentId(this.groupIdToSearch);
      }
    },
    // Expanded
    getGates(groupId: string) {
      this.loadingGate = true;
      const apiLinkGetGate = Config.RELATION_GATE_GROUP + '?groupId=' + encodeURIComponent(groupId) +
      '&limit=25&page=' + this.pageGate +
      '&embed=gate&isExceptOptionalGate=true';
      this.$http.get(apiLinkGetGate)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.gateList[groupId] = {
              maxRecord: response.body.maxRecords,
              gates: [],
            };
            response.body.relationsGateGroup.forEach((element: any) => {
              this.gateList[groupId].gates.push({
                relationId: element.relationId,
                gateId: element.gate ? element.gate.gateId : '',
                gateName: element.gate ? element.gate.name : '',
                });
            });
          } else {
            this.gateList = {};
          }
          this.loadingGate = false;
        })
        .then( null, (err: any) => {
          this.loadingGate = false;
          console.log('err: ', err);
        }); // catch
    },
    getExpandedGroup(userId: any) {
      // Edit pagination
      // Only < 1000 item group, get 1 page with limit 1000
      this.loadingGroup = true;
      this.$http.get(Config.GROUPS_PASSAGE_API + '?userId=' +
      encodeURIComponent(userId) + '&limit=1000&page=1&embed=group')
        .then(async (response: any) => {
          console.log(response);
          this.maxGroup = response.body.groupPassages.length;
          if (response.body.maxRecords > 0) {
            const res = response.body;
            this.ExpandedGroup[userId] = {
              maxRecords: res.maxRecords,
              groups: [
                {
                  gateName: 'ゲート1',
                  groupName: '通行権限グループ1',
                  passableTimeSettingName: '時間帯設定1',

                },
                {
                  gateName: 'ゲート2',
                  groupName: '通行権限グループ2',
                  passableTimeSettingName: '時間帯設定2',

                },
                {
                  gateName: 'ゲート3',
                  groupName: '通行権限グループ3',
                  passableTimeSettingName: '時間帯設定3',

                },
              ],
            };
            this.ExpandedGroupDefault[userId] = {
              maxRecords: res.maxRecords,
              groups: [],
            };
            this.loadingGroup = false;
            // response.body.groupPassages.forEach((element: any) => {
            //   this.getGateCount(this.ExpandedGroup[userId], element,
            // this.ExpandedGroupDefault[userId]);
            // });
            // const data = response.body.groupPassages;
            // for (let i = 0; i < data.length; i += 10) {
            //   const chunk = data.slice(i, i + 10);
            //   const arrayPromise: any = [];
            //   for (const [index, item] of  chunk.entries()) {
            //     await this.actionWait(20);
            //     const result = this.getGateCount(this.ExpandedGroup[userId],
            // item, this.ExpandedGroupDefault[userId]);
            //     arrayPromise.push(result);
            //   }
            //   await Promise.all(arrayPromise);
            // }
          } else {
            this.ExpandedGroup = {};
            this.ExpandedGroupDefault = {};
          }
        })
        .then( null, (err: any) => {
          this.loadingGroup = false;
          console.log(err);
        });
    },
    getInforGroup(page: any, userId: string) {
      this.loading = true;
      this.$http.get(Config.GROUPS_PASSAGE_API + '?userId=' + encodeURIComponent(userId) + '&embed = group')
        .then((response: any) => {
          this.loading = false;
          if (response.body.maxRecords > 0) {
            this.tempGroupPassages.push(response.body.groupPassages);
            if (response.body.maxRecords > response.body.endRecord) {
              this.getInforGroup(page + 1, userId);
            } else {
              this.loading = false;
            }
          } else {
            this.tempGroupPassages = [];
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.message = '事業者IDが間違っています。';
        });
    },
    getPcode(page: any, userId: any) {
      this.$http.get(Config.CARD_LIST_API + '?limit=1000&page=' + page +
      '&sortKey=desc:registered' + '&userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          if (response.body.maxRecords > 0 && response.body.pCodes !== undefined) {
            response.body.pCodes.forEach((element: any) => {
              this.cardItems.push({text: element.pCodeId, value: element.pCodeId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getPcode(page + 1, userId);
            } else {
              this.loading = false;
            }
          } else {
            this.cardItems = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
          }); // catch
    },
    getPhone(page: any, userId: any) {
      this.$http.get(Config.PHONES_LIST_API + '?limit=1000&page=' + page +
      '&sortKey=desc:registered' + '&userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.phones.forEach((element: any) => {
              this.phoneItems.push({text: element.model, value: element.phoneId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getPhone(page + 1, userId);
            } else {
              this.loading = false;
            }
          } else {
            this.phoneItems = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
          }); // catch
    },
    getInforGatePassage(page: any, userId: string) {
      this.$http.get(Config.GATES_PASSAGE_API + '?userId=' + encodeURIComponent(userId) + '&embed=gate' + '?limit=1000&page=' + page)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.passages.forEach((element: any) => {
              this.listGatePassages.push({gateId: element.gateId,
              notAfter: element.notAfter, notBefore: element.notBefore});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getInforGatePassage(page + 1, userId);
            } else {
              this.loading = false;
            }
          } else {
            this.listGatePassages = [];
            this.loading = false;
          }
        })
        .then(null, (error) => {
          console.log(error);
        });
    },
    getInforGate(gateId: string, index: number) {
      const vm = this;
      this.$http.get(Config.GATES_LIST_API + '?gateId=' + encodeURIComponent(gateId) + '&isValid=true')
        .then((response: any) => {
          vm.listGatePassages[index].gateId = response.body.gates[0];
        })
        .then(null, (error) => {
          console.log(error);
        });
    },
    getGateGroup(groupId: string) {
      const vm = this;
      this.$http.get(Config.RELATION_GATE_GROUP_API + '?groupId=' + encodeURIComponent(groupId) + '&isExceptOptionalGate=true')
        .then((response: any) => {
          for (const gate of response.body.relationsGateGroup) {
            vm.listGroupPassages.push(gate);
          }
          vm.exeGateGroup++;
        })
        .then(null, (error) => {
          vm.exeGateGroup++;
          console.log(error);
        });
    },
    clickEditUser(userId: string) {
      this.$router.push({ path: '/user/edit', query: {userId}});
    },
    async clickLeaveAction(data: any) {
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = true;
      this.showConfirm = false;
      this.action = data.action;
      this.isFinishProcess = false;
      this.errorMessageProcess = [];
      if (data.leave === 'group') {
        const groupIds = this.groupIds;
        this.totalDataRowProcess = groupIds.length;
        const percent = (100 / this.totalDataRowProcess);
        for (const item of groupIds) {
          let result;
          result  = await this.deleteGroup(item);
          if (result) {
            this.successItemProcess++;
          }
          this.progress += percent;
        }
        this.isFinishProcess = true;
      }
      if (data.leave === 'gate') {
        const gateIds = this.gateIds;
        this.totalDataRowProcess = gateIds.length;
        const percent = (100 / this.totalDataRowProcess);
        for (const item of gateIds) {
          let result;
          result  = await this.deleteGate(item.relationId);
          if (result) {
            this.successItemProcess++;
          }
          this.progress += percent;
        }
        this.isFinishProcess = true;
      }
      if (data.leave === 'gate-passage') {
        const dataIds: any = this.gatePassageIds;
        this.totalDataRowProcess = dataIds.length;
        const percent = (100 / this.totalDataRowProcess);
        for (const item of dataIds) {
          let result;
          result  = await this.deleteGatePass(item);
          if (result) {
            this.successItemProcess++;
          }
          this.progress += percent;
        }
        this.isFinishProcess = true;
      }
    },
    deleteGate(relationId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_GATE_GROUP + '/' + encodeURIComponent(relationId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = relationId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    deleteGroup(groupPassageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GROUP_PASSAGE + '/' + encodeURIComponent(groupPassageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupPassageId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    converTimestamp(t: any) {
      return moment(t).unix();
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeUserList', value);
      this.getList();
    },
    changePageGate(data: any): any {
      console.log(data);
      this.pageGate = data.page;
      this.gateList = {};
      this.$set(this.gateList, data.groupId, []);
      this.getGates(data.groupId);
    },
    changePageGroup(data: any): any {
      console.log(data);
      this.pageGroup = data.page;
      this.loadingGroup = true;
      this.countGetDetailGroup = 0;
      this.getExpandedGroup(data.userId);
    },
    changePage(payload: any) {
      const type = payload.change;
      if ( type === 'user') {
        this.currPage = payload.page;
        this.getList();
      } else if ( type === 'group') {
        this.pageGroup = payload.page;
        this.loadingGroup = true;
        this.countGetDetailGroup = 0;
        const data  = Array.of(...this.ExpandedGroupDefault[payload.userId].groups);
        this.ExpandedGroup[payload.userId].groups = data.slice((payload.page - 1) * 25, payload.page * 25);
        setTimeout(() => {
          this.loadingGroup = false;
        }, 100);
        // this.getExpandedGroup(payload.userId);
      } else if ( type === 'gate') {
        this.pageGate = payload.page;
        this.gateList = {};
        this.$set(this.gateList, payload.groupId, []);
        this.getGates(payload.groupId);
      }
    },
    converToBit(text: any) {
      let str = text !== undefined ? text.toString() : '';
      if (str !== null && str !== '') {
        str = str.trim().toLowerCase();
      } else {
        str = 'false';
      }
      switch (str) {
        case 'true':
          return 1;
        case 'false':
          return 0;
        default:
          return 1;
      }
    },
    converToDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },

    // Excel
    clickExportExcel(check: any) {
      this.loading = true;
      this.exportUserData = [];
      this.exportGroupData = [];
      this.exportGroupPassages = [];
      if (check) {
        this.getDataForUserExcel().then(() => this.getDataForGroup(check)).then(() => this.downloadExcel()).then(() => {
          this.loading = false;
        });
      } else {
        this.getDataForGroup(check).then(() => this.downloadExcel()).then(() => {
          this.loading = false;
        });
      }
    },

    async downloadExcel() {
      const setPassword = '!@#' + Math.random().toString(36).substring(7) + Date.now();
      const dataGroup = this.exportGroupData;
      const dataUser = this.exportUserData;
      const dataGroupPassages = this.exportGroupPassages;
      const ExcelJS = require('excel4node');

      const wb = new ExcelJS.Workbook();
      const totalLine = dataUser.length > 0 ? dataUser.length + 2 : 1002;
      const options1 = {
        sheetFormat: {
          defaultColWidth: 13,
        },
      };
      const options2 = {
        sheetFormat: {
          defaultColWidth: 3,
        },
      };
      const options3 = {
        sheetProtection: {
          password: setPassword,
        },
        hidden: true,
      };
      const sheetNameExport = ['ユーザーとカードの設定', '通行権限グループとユーザーの紐づけ', '参照'];
      const ws1 = wb.addWorksheet(sheetNameExport[0], options1);
      const ws2 = wb.addWorksheet(sheetNameExport[1], options2);
      const ws3 = wb.addWorksheet(sheetNameExport[2], options3);
      const styleFormat = wb.createStyle({
        fill: {
          type: 'pattern',
          patternType: 'solid',
          bgColor: 'FFC008',
        },
        font: {
          color: '000000',
          name: 'MS PGothic',
        },
      });
      const style1 = wb.createStyle({
        alignment: {
          vertical: 'center',
          horizontal: 'left',
          wrapText: true,
        },
      });

      const styleBorder = wb.createStyle({
        font: {
          size: 11,
          name: 'MS PGothic',
        },
        border: {
          left: {
            style: 'thin',
            color: '000000',
          },
          right: {
            style: 'thin',
            color: '000000',
          },
          top: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          },
        },
      });
      const styleSheet3 = wb.createStyle({
        alignment: {
          vertical: 'center',
          horizontal: 'center',
        },
        font: {
          size: 11,
          name: 'MS PGothic',
        },
        border: {
          left: {
            style: 'thin',
            color: '000000',
          },
          right: {
            style: 'thin',
            color: '000000',
          },
          top: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          },
        },
      });
      const B2 = [
        {
          color: '000000',
          size: 11,
          value: 'ユーザーID \n',
          name: 'MS PGothic',
        },
        {
          value: '（重複不可）\n',
          bold: true,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: '（半角英数字）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
          bold: false,
        },
      ];
      const C2 = [
        'ログインパスワード',
        {
          value: '（新規ユーザー登録のみ有効）\n',
          bold: true,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: '（必須：半角英大文字+半角英小文字+半角数字の組み合わせ）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
          bold: false,
        },
      ];
      const M2 = [
        '個人コード1 \n',
        {
          value: '（テンキー:半角数字）（NFC:半角英数字）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
        },
      ];
      const O2 = [
        '個人コード2 \n',
        {
          value: '（テンキー:半角数字）（NFC:半角英数字）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
        },
      ];
      const Q2 = [
        '個人コード3 \n',
        {
          value: '（テンキー:半角数字）（NFC:半角英数字）',
          size: 9,
          color: 'ff0000',
          name: 'MS PGothic',
        },
      ];
      const C1 = [
        '通行権限グループ一覧 ',
        {
          value: '※紐づけ設定　1：設定、0：解除',
          color: 'ff0000',
          name: 'MS PGothic',
        },
      ];
      const A1 = [
        '※赤セルの箇所は、',
        {
          value: '入力必須項目',
          bold: true,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: 'です \n',
          bold: false,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: '※青セルの箇所は、',
          bold: false,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: 'サービス加入者のみ入力可能',
          bold: true,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
        {
          value: 'です',
          bold: false,
          size: 11,
          color: '000000',
          name: 'MS PGothic',
        },
      ];
      // Sheet 3
      ws3.cell(2, 2)
        .string('通行権限グループの値')
        .style(styleSheet3);
      ws3.cell(3, 2)
        .string(' ')
        .style(styleSheet3);
      ws3.cell(4, 2)
        .number(0)
        .style(styleSheet3);
      ws3.cell(5, 2)
        .number(1)
        .style(styleSheet3);
      ws3.column(2).setWidth(22);
      // Sheet 1
      ws1.cell(1, 2, 1, 5, true)
        .string(A1)
        .style({
          alignment: {
            vertical: 'center',
            horizontal: 'left',
            wrapText: true,
          },
          font: {
            size: 11,
            name: 'MS PGothic',
          },
        });
      ws1.row(1).setHeight(31);
      ws1.cell(2, 2)
        .string(B2)
        .style(style1);
      ws1.cell(2, 3)
        .string(C2)
        .style(style1);
      ws1.cell(2, 4)
        .string('ユーザー名')
        .style(style1);
      ws1.cell(2, 5)
        .string('ふりがな')
        .style(style1);
      ws1.cell(2, 6)
        .string('メールアドレス')
        .style(style1);
      ws1.cell(2, 7)
        .string('組織管理権限(1:有効.0:無効)')
        .style(style1);
      // New
      ws1.cell(2, 8)
        .string('所属ID')
        .style(style1);
      // ws1.cell(2, 9)
      //   .string('通行権限グループID')
      //   .style(style1);
      ws1.cell(2, 9)
        .string('所属名')
        .style(style1);
      ws1.cell(2, 8 + countChangeExport)
        .string('ゲート設定権限ステータス(1:有効.0:無効)')
        .style(style1);
      ws1.cell(2, 9 + countChangeExport)
        .string('鍵共有権限ステータス(1:有効.0:無効)')
        .style(style1);
      ws1.cell(2, 10 + countChangeExport)
        .string('アプリへのログイン(1:有効.0:無効)')
        .style(style1);
      ws1.cell(2, 11 + countChangeExport)
        .string('SMS認証(1:有効.0:無効)')
        .style(style1);
      ws1.cell(2, 12 + countChangeExport)
        .string(
          [
            '電話番号 \n',
            {
              value: '（-(ハイフン)無しの携帯電話番号）',
              size: 11,
              color: 'ff0000',
              name: 'MS PGothic',
            },
          ])
        .style(style1);
      ws1.cell(2, 13 + countChangeExport)
        .string('備考1')
        .style(style1);
      ws1.cell(2, 14 + countChangeExport)
        .string('備考2')
        .style(style1);
      ws1.cell(2, 15 + countChangeExport)
        .string('個人コード1-種別（1:テンキー.0：NFCカード）')
        .style(style1);
      ws1.cell(2, 16 + countChangeExport)
        .string(M2)
        .style(style1);
      ws1.cell(2, 17 + countChangeExport)
        .string('個人コード2-種別（1:テンキー.0：NFCカード）')
        .style(style1);
      ws1.cell(2, 18 + countChangeExport)
        .string(O2)
        .style(style1);
      ws1.cell(2, 19 + countChangeExport)
        .string('個人コード3-種別（1:テンキー.0：NFCカード）')
        .style(style1);
      ws1.cell(2, 20 + countChangeExport)
        .string(Q2)
        .style(style1);
      ws1.cell(2, 2, totalLine, 4, false)
        .style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            bgColor: 'fcd5b4',
            fgColor: 'fcd5b4',
          },
        });
      ws1.cell(3, 2, totalLine, 20 + countChangeExport, false)
        .style({
          alignment: {
            vertical: 'center',
            horizontal: 'left',
          },
        });
      ws1.cell(2, 7, totalLine, 9 + countChangeExport, false)
        .style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            bgColor: 'fcd5b4',
            fgColor: 'fcd5b4',
          },
        });
      ws1.cell(2, 11 + countChangeExport, totalLine, 12 + countChangeExport, false)
        .style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            bgColor: 'daeef3',
            fgColor: 'daeef3',
          },
        });
      ws1.cell(3, 12 + countChangeExport, totalLine, 12 + countChangeExport, false)
        .style({
          numberFormat: '@',
        });
      // Set border
      ws1.cell(2, 2, totalLine, 20 + countChangeExport, false)
        .style(styleBorder);
      // set width for column
      ws1.column(13 + countChangeExport).setWidth(34);
      ws1.column(15 + countChangeExport).setWidth(34);
      ws1.column(17 + countChangeExport).setWidth(34);
      ws1.column(1).setWidth(3);
      const countUser = 3;
      if (dataUser.length > 0) {
      await dataUser.forEach((item: any, index: any) => {
        ws1.cell(countUser + index, 2)
          .string(item.userId);
        ws1.cell(countUser + index, 4)
          .string(item.name);
        ws1.cell(countUser + index, 5)
          .string(item.furigana);
        ws1.cell(countUser + index, 6)
          .string(item.email);
        ws1.cell(countUser + index, 7)
          .number(item.isAdmin);
        ws1.cell(countUser + index, 8)
          .string(item.departmentId);
        ws1.cell(countUser + index, 9)
          .string(item.departmentName);
        ws1.cell(countUser + index, 8 + countChangeExport)
          .number(item.isPhoneAdmin);
        ws1.cell(countUser + index, 9 + countChangeExport)
          .number(item.shareKeyBeValid);
        ws1.cell(countUser + index, 10 + countChangeExport)
          .number(item.isPasswordLoginValid);
        ws1.cell(countUser + index, 11 + countChangeExport)
          .number(item.isMfaSms);
        ws1.cell(countUser + index, 12 + countChangeExport)
          .string(item.phoneNumber);
        ws1.cell(countUser + index, 13 + countChangeExport)
          .string(item.option1);
        ws1.cell(countUser + index, 14 + countChangeExport)
          .string(item.option2);
        if (item.pCodes[0]) {
          ws1.cell(countUser + index, 15 + countChangeExport)
            .number(item.pCodes[0].type);
          ws1.cell(countUser + index, 16 + countChangeExport)
            .string(item.pCodes[0].pCodeId);
        }
        if (item.pCodes[1]) {
          ws1.cell(countUser + index, 17 + countChangeExport)
            .number(item.pCodes[1].type);
          ws1.cell(countUser + index, 18 + countChangeExport)
            .string(item.pCodes[1].pCodeId);
        }
        if (item.pCodes[2]) {
          ws1.cell(countUser + index, 19 + countChangeExport)
            .number(item.pCodes[2].type);
          ws1.cell(countUser + index, 20 + countChangeExport)
            .string(item.pCodes[2].pCodeId);
        }
        });
      }
      // sheet 2
      ws2.cell(2, 2)
        .string('ユーザーID');
      ws2.cell(3, 2)
        .string('編集禁止')
        .style({
          alignment: {
            vertical: 'center',
            horizontal: 'right',
          },
        });
      await dataGroup.forEach((value: any, index: any) => {
        const cell = index + 3;
        ws2.cell(2, cell)
          .string(value.name)
          .style({
            alignment: {
              textRotation: 90,
              vertical: 'center',
              horizontal: 'center',
            },
          });
        ws2.cell(3, cell)
          .string(value.groupId)
          .style({
            alignment: {
              textRotation: 90,
              vertical: 'center',
              horizontal: 'center',
            },
            fill: {
              type: 'pattern',
              patternType: 'solid',
              bgColor: '808080',
              fgColor: '808080',
            },
          });
        for (let i = 3; i <= totalLine; i++) {
          const cellValue = sheetNameExport[0] + '!B' + i;
          ws2.cell(i + 1, cell)
            .string('');
          ws2.cell(i + 1, 2)
            .formula('IF( ' + cellValue + '= "", "", ' + cellValue + ')')
            .style({
              alignment: {
                vertical: 'center',
                horizontal: 'left',
              },
            });
        }
        this.countColumn++;
      });

      await dataUser.forEach((userItem: any, userIndex: any) => {
        dataGroup.forEach((groupItem: any, groupIndex: any) => {
          const checkGroupPassages = dataGroupPassages.filter((item: any) => {
            return (item.userId === userItem.userId && item.groupId === groupItem.groupId);
          });
          if (checkGroupPassages.length > 0) {
            ws2.cell(userIndex + 4, groupIndex + 3).number(1);
          }
        });
      });

      const getColumn = ExcelJS.getExcelCellRef(totalLine + 1, this.countColumn + 1);
      // Set border
      ws2.cell(2, 2, totalLine + 1, this.countColumn + 1, false)
        .style(styleBorder);
      ws2.cell(1, 3)
        .string(C1)
        .style({
          alignment: {
            vertical: 'center',
            horizontal: 'left',
          },
          font: {
            name: 'MS PGothic',
          },
        });
      ws2.addDataValidation({
        type: 'list',
        allowBlank: true,
        showDropDown: true,
        sqref: 'C4:' + getColumn,
        formulas: ['=' + sheetNameExport[2] + '!$B$3:$B$5'],
      });
      ws2.cell(2, 2).style({
        alignment: {
          vertical: 'center',
          horizontal: 'left',
        },
      });
      // set default data
      ws2.cell(4, 3, totalLine + 1, this.countColumn + 1)
        .style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            bgColor: 'bfbfbf',
            fgColor: 'bfbfbf',
          },
        });
      ws2.column(2).setWidth(24);
      ws2.row(3).hide();
      ws2.addConditionalFormattingRule('C4:' + getColumn, {
        type: 'expression',
        priority: 1,
        formula: 'NOT(ISERROR(SEARCH("1",C4:' + getColumn + ')))',
        style: styleFormat,
      });
      const saveAs = require('file-saver');
      wb.writeToBuffer().then((buffer: any) => {
        const blob = new Blob([buffer]);
        saveAs(blob, 'alluser-data.xlsx');
      });
    },

    clickImportExcel(data: any) {
      console.log(data);
      this.isCountCSV = false;
      this.isCountExcel =  false;
      this.resetAllValue();
      this.isLoading = true;
      this.isSendEmail = data.isSendEmail;
      this.errorMessageExcel = [];

      setTimeout(() => {
        this.importExelAllUser(data.files);
      }, 200);
    },
    getGateCount(array: any, data: any, arrayD: any) {
      return new Promise((resolve, reject) => {
        this.loading = false;
        const apiLink = Config.RELATION_GATE_GROUP + '?limit=25&groupId=' + encodeURIComponent(data.groupId) + '&isExceptOptionalGate=true';
        this.$http.get(apiLink)
          .then((response: any) => {
            array.groups.push({
              groupPassageId: data.groupPassageId,
              groupId: data.groupId,
              groupName: data.group ? data.group.groupName : '',
              gateCount: response.body.maxRecords,
            });
            arrayD.groups.push({
              groupPassageId: data.groupPassageId,
              groupId: data.groupId,
              groupName: data.group ? data.group.groupName : '',
              gateCount: response.body.maxRecords,
            });
            this.countGetDetailGroup++;
            resolve(true);
          })
          .then( null, (err: any) => {
            array.groups.push({
              groupPassageId: data.groupPassageId,
              groupId: data.groupId,
              groupName: data.group ? data.group.groupName : '',
              gateCount: 0,
            });
            arrayD.groups.push({
              groupPassageId: data.groupPassageId,
              groupId: data.groupId,
              groupName: data.group ? data.group.groupName : '',
              gateCount: 0,
            });
            console.log('err: ', err);
            this.countGetDetailGroup++;
            resolve(false);
          }); // catch
      });
    },
    importExelAllUser(files: any): void {
      if (files !== null && files !== undefined && files.length !== 0) {
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const XLSX = require('xlsx');
          const workbook = XLSX.read(read, {type: 'array'});
          this.sheetUserName = workbook.SheetNames[0];
          this.sheetGroupName = workbook.SheetNames[1];
          const usersheetdata = workbook.Sheets[this.sheetUserName];
          const gatesheetdata = workbook.Sheets[this.sheetGroupName];
          this.isLoading = false;
          this.showProcessImportExcel = true;
          if (usersheetdata !== undefined && gatesheetdata !== undefined) {
            const usersheetarray = XLSX.utils.sheet_to_json(usersheetdata, {header: 1});
            const gatesheetarray = XLSX.utils.sheet_to_json(gatesheetdata, {header: 1});
            const headerarray = usersheetarray[1];
            const gateheaderarray = gatesheetarray[1];
            const isEmptyFirstColumnUser = (headerarray[0] === undefined);
            usersheetarray.forEach((value: any) => {
              if (isEmptyFirstColumnUser) {
                if (value[0] === undefined) {
                  value.splice(0, 1);
                }
              }
              for (let i = 0; i < value.length; i++) {
                if (value[i] === undefined || value[i].toString().trim().length === 0) {
                  value[i] = '';
                }
              }
            });
            const isEmptyFirstColumnGate = (gateheaderarray[0] === undefined);
            gatesheetarray.forEach((value: any) => {
              if (isEmptyFirstColumnGate) {
                if (value[0] === undefined) {
                  value.splice(0, 1);
                }
              }
              for (let i = 0; i < value.length; i++) {
                if (value[i] === undefined || value[i].toString().trim().length === 0) {
                  value[i] = '';
                }
              }
            });
            console.log('usersheetarray', usersheetarray);
            console.log('gatesheetarray', gatesheetarray);
            console.log('headerarray.length', headerarray.length);
            if (headerarray.length === 19) {
              countChange = 0;
            }
            const CloumnData = 19 + countChange; // Sheet must have 19 cloumn
            let isHasLowerCase = false;
            if (headerarray.length === CloumnData && gatesheetarray.length !== 0) {
              // Sheet User Data
              for (let index = 2; index < usersheetarray.length; index++) {
                const itemArr = [];
                if (usersheetarray[1].length === CloumnData) {
                  let rowIsblank = true;
                  for (let headeridx = 0; headeridx < CloumnData; headeridx++) {
                    itemArr[headeridx] = (usersheetarray[index][headeridx] === undefined)
                      ? '' : usersheetarray[index][headeridx] + '';
                    if (itemArr[headeridx] !== '') {
                      rowIsblank = false;
                    }
                  }
                  if (rowIsblank === false) {
                    if (itemArr[14 + countChange].toUpperCase() !== itemArr[14 + countChange]
                          || itemArr[16 + countChange].toUpperCase() !== itemArr[16 + countChange]
                            || itemArr[18 + countChange].toUpperCase() !== itemArr[18 + countChange]) {
                      isHasLowerCase = true;
                    }
                    this.UserSheetImportData.push([itemArr, index + 1]);
                  }
                }
              }
              // Sheet2
              if (gatesheetarray[2][1] !== undefined && gatesheetarray[2][1].match(/^[0-9a-fA-F-]{36}$/)) {
                this.groupGateArray = gatesheetarray[2]; // Array key start from 1.
              }
              if (this.groupGateArray.length > 0) {
                for (let index = 0; index < gatesheetarray.length - 3; index++) {
                  if (gatesheetarray[index + 3][0] !== undefined && (gatesheetarray[index + 3][0].toString() !== '')) {
                    let noStatusData = true;
                    for (let gridx = 1; gridx < this.groupGateArray.length; gridx++) {
                      if (gatesheetarray[(index + 4) - 1][gridx] !== undefined
                      && gatesheetarray[(index + 4) - 1][gridx] !== '') {
                        noStatusData = false;
                      }
                    }
                    if (noStatusData === false) {
                      this.userIdArray.push([gatesheetarray[index + 3][0], index + 4]);
                    }
                  }
                }
              }
              if (this.userIdArray.length > 0 && this.groupGateArray.length > 0) {
                for (let gridx = 1; gridx < this.groupGateArray.length; gridx++) {
                  const tempArray = [];
                  for (const item of this.userIdArray) {
                    tempArray[item[0]] = gatesheetarray[item[1] - 1][gridx];
                  }
                  // for (let uidx = 0; uidx < this.userIdArray.length; uidx++) {
                  //   console.log(this.userIdArray[uidx]);
                  //   tempArray[this.userIdArray[uidx][0]] = gatesheetarray[this.userIdArray[uidx][1] - 1][gridx];
                  // }
                  this.userInGroupMx[this.groupGateArray[gridx]] = tempArray;
                }
              }
              console.log(this.UserSheetImportData);
              console.log(this.userInGroupMx);
              this.totalDataRow = this.UserSheetImportData.length;
              this.totalDataRowSheet2 = (this.groupGateArray.length - 1) * this.userIdArray.length;
              this.isHasLowerCaseCardNumberExcel = isHasLowerCase;
              if ((this.UserSheetImportData.length + this.userIdArray.length) > 0) {
                if (!this.isHasLowerCaseCardNumberExcel) {
                  // Send data
                  if (this.UserSheetImportData.length > 0) {
                  this.percentProcess = (100 / (this.totalDataRow));
                  // Send data
                  this.excuteRegisterUser()
                    .then(() => {
                      if (this.groupGateArray.length > 1) {
                        // Send data
                        this.percentProcessSheet2 = (100 / (this.totalDataRowSheet2));
                        this.gateGrpImportExe();
                      } else {
                        this.isFinishImportExcel = true;
                      }
                    });
                  } else {
                    if (this.groupGateArray.length > 0) {
                      // Send data
                      this.gateGrpImportExe();
                    } else {
                      this.isFinishImportExcel = true;
                    }
                  }
                } else {
                  this.showProcessImportExcel = false;
                }
              } else {
                this.isFinishImportExcel = true;
                this.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
              }
            } else {
              this.isFinishImportExcel = true;
              this.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
            }
          } else {
            this.isFinishImportExcel = true;
            this.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
          }
        };
        reader.onerror = (): void => {
          this.isLoading = false;
          this.errorMessageLogs = '選択されているファイルの読み込みに失敗しました。<br>ブラウザの更新、もしくは別ファイルを選択してから<br>再度アップロードしたいファイルを選択してください。';
          this.isFinishImportExcel = true;
          this.showProcessImportExcel = true;
        };
        reader.readAsArrayBuffer(files);
      } else {
        this.isLoading = false;
        this.showProcessImportExcel = true;
        this.errorMessageLogs = 'XLSXのファイルを選択してください。';
        this.isFinishImportExcel = true;
      }
    },
    importExel(files: any): void {
      if (files !== null && files !== undefined && files.length !== 0) {
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const XLSX = require('xlsx');
          const workbook = XLSX.read(read, {type: 'array'});
          this.sheetUserName = workbook.SheetNames[0];
          const usersheetdata = workbook.Sheets[this.sheetUserName];
          this.isLoading = false;
          this.showProcessImportExcel = true;
          if (usersheetdata !== undefined) {
            const usersheetarray = XLSX.utils.sheet_to_json(usersheetdata, {header: 1});
            const headerarray = usersheetarray[1];
            const CloumnData = 18; // Sheet must have 18 cloumn
            let isHasLowerCase = false;
            if (headerarray.length === CloumnData) {
              // Sheet User Data
              for (let index = 2; index < usersheetarray.length; index++) {
                const itemArr = [];
                if (usersheetarray[1].length === CloumnData) {
                  let rowIsblank = true;
                  for (let headeridx = 0; headeridx < CloumnData; headeridx++) {
                    itemArr[headeridx] = (usersheetarray[index][headeridx] === undefined)
                      ? '' : usersheetarray[index][headeridx] + '';
                    if (itemArr[headeridx] !== '') {
                      rowIsblank = false;
                    }
                  }
                  if (rowIsblank === false) {
                    if (itemArr[13].toUpperCase() !== itemArr[13]
                          || itemArr[15].toUpperCase() !== itemArr[15]
                            || itemArr[17].toUpperCase() !== itemArr[17]) {
                      isHasLowerCase = true;
                    }
                    this.UserSheetImportData.push([itemArr, index + 1]);
                  }
                }
              }
              this.totalDataRow = this.UserSheetImportData.length;
              if (this.totalDataRow > 0) {
                this.percentProcess = (100 / (this.totalDataRow));
                // Send data
                this.isHasLowerCaseCardNumberExcel = isHasLowerCase;
                if (!this.isHasLowerCaseCardNumberExcel) {
                  // Send data
                  this.excuteRegisterUser();
                } else {
                  this.showProcessImportExcel = false;
                }
              } else {
                this.isFinishImportExcel = true;
              }
            } else {
              this.isFinishImportExcel = true;
              this.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
            }
          } else {
            this.isFinishImportExcel = true;
            this.errorMessageLogs = '不正なファイルです。正しいファイルを選択しなおしてください。';
          }
        };
        reader.onerror = (): void => {
          this.isLoading = false;
          this.showProcessImportExcel = true;
          this.errorMessageLogs = '選択されているファイルの読み込みに失敗しました。<br>ブラウザの更新、もしくは別ファイルを選択してから<br>再度アップロードしたいファイルを選択してください。';
          this.isFinishImportExcel = true;
        };
        reader.readAsArrayBuffer(files);
      } else {
        this.isLoading = false;
        this.showProcessImportExcel = true;
        this.errorMessageLogs = 'XLSXのファイルを選択してください。';
        this.isFinishImportExcel = true;
      }
    },

    async excuteRegisterUser() {
      this.closeDialogCheckCardExcel();
      this.showProcessImportExcel = true;
      console.log(' this.UserSheetImportData',  this.UserSheetImportData);
      for (const item of this.UserSheetImportData) {
        if ( this.UserSheetImportData.length === 0) {
          break;
        }
        console.log('item', item);
        let rowstatus = '';
        if (item[0] === undefined || item[0][0].length < 4
              || item[0][0].length > 96) {
            rowstatus = this.sheetUserName + 'シート>>' + item[1] + ' 行目>>' + '<span>入力したパラメーターが誤っています。入力内容をご確認ください。</span>';
            this.$set(this.errorMessageExcel, this.rowinErrDialog++, rowstatus);
        } else {
          const phoneNumber = item[0][10 + countChange].replace(/\u200B/g, '');
          const phone = phoneNumber.substring(1);
          const isMfaSms = item[0][9 + countChange];
          let phoneNumberEnd = '';
          const checkMfaSms = this.convertStringToBoolean(isMfaSms, false);
          const admin = this.convertStringToBoolean(item[0][5], false);
          const phoneAdmin = this.convertStringToBoolean(item[0][6 + countChange], false);
          const isShareKeyBeValid = this.convertStringToBoolean(item[0][7 + countChange], false);
          const checkPasswordLoginValid = this.convertStringToBoolean(item[0][8 + countChange], true);
          let tmpIsSendEmail = false;
          if (item[0][4] !== null && item[0][4] !== '') {
            tmpIsSendEmail = this.isSendEmail;
          } else {
            tmpIsSendEmail = false;
          }
          console.log('excuteRegisterUser', item[0][0]);
          const user: UserRegister = {
            userId: item[0][0],
            name: item[0][2],
            furigana: item[0][3],
            email: item[0][4],
            isAdmin: admin,
            isPhoneAdmin: phoneAdmin,
            shareKeyBeValid: isShareKeyBeValid,
            isMfaSms: checkMfaSms,
            option1: item[0][11 + countChange],
            option2: item[0][12 + countChange],
            isSendEmail: tmpIsSendEmail,
            isPasswordLoginValid: checkPasswordLoginValid,
          };
          if (checkMfaSms) {
            if (phoneNumber.match(/^[0][0-9]{10}$/)) {
              phoneNumberEnd = '+81' + phone;
            }
            user.phoneNumber = phoneNumberEnd;
          }
          if (item[0][1]) {
            user.password = item[0][1];
          }
          // result = 0 is fail
          // result = 1 is sucess
          // result = 2 is change request
          let resultUser = await this.userEditExel(user, item[1], item[0][6]);
          if (resultUser === 2) {
            // send Add User
            if (item[0][6] !== '' && countChange > 0) {
              user.departmentIds = [item[0][6]];
            }
            resultUser = await this.userAddExel(user, item[1]);
          }
          // excute pCode
          // pCode 1
          let resPcode1 = await this.pCodeAddExel(item[0][0], item[0][13 + countChange],
          item[0][14 + countChange], item[1]);
          if (resPcode1 === 2) {
            // send Edit Pcode
            resPcode1 = await this.pCodeEditExel(item[0][0], item[0][14 + countChange], item[1]);
          }

          // pcode 2
          let resPcode2 =  await this.pCodeAddExel(item[0][0], item[0][15 + countChange],
          item[0][16 + countChange], item[1]);
          if (resPcode2 === 2) {
            // send Edit Pcode
            resPcode2 = await this.pCodeEditExel(item[0][0], item[0][16 + countChange], item[1]);
          }
          // pcode 3
          let resPcode3 = await this.pCodeAddExel(item[0][0], item[0][17 + countChange],
          item[0][18 + countChange], item[1]);
          if (resPcode3 === 2) {
            // send Edit Pcode
            resPcode3 = await this.pCodeEditExel(item[0][0], item[0][18 + countChange], item[1]);
          }
          if (resultUser === 1 && resPcode1 === 1
                && resPcode2 === 1 && resPcode3 === 1 && this.UserSheetImportData.length > 0) {
              this.successItem++;
          }
        }
        if ( this.UserSheetImportData.length > 0) {
          this.progress += this.percentProcess;
        }
      }
    },

    async gateGrpImportExe() {
      const vm = this;
      const userCheckListSheet2 = new Map();
      for (let index = 1; index < this.groupGateArray.length; index++) {
        if ( this.groupGateArray.length === 0) {
          break;
        }
        const groupId = this.groupGateArray[index];
        const data = this.userInGroupMx[groupId];
        for (const item of this.userIdArray) {
          if ( this.groupGateArray.length === 0) {
            break;
          }
          const userId = item[0];
          const rownumber = item[1];
          const status = data[userId];
          if (userId === undefined || userId.toString().length < 4 || userId.toString().length > 96) {
            const rowstatus = vm.sheetGroupName + 'シート>>' + '>>' + rownumber + ' 行目>><span>入力したパラメーターが誤っています。入力内容をご確認ください。</span>';
            this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
          } else {
            if (userCheckListSheet2.has(userId) !== true) {
              // need to call api to check user
              console.log('call api to check user', userId);
              const isValid = await this.checkUserValid(userId);
              console.log(userId, 'is valid', isValid);
              userCheckListSheet2.set(userId, isValid);
            }
            const isUserValid = userCheckListSheet2.get(userId);
            if (isUserValid) {
              if (status === undefined || status === '') {
                // skip data
                vm.successItemSheet2++;
              } else if (parseInt(status) === 1 || parseInt(status) === 0) {
                const groupPassageId = await this.getGroupPassageId(groupId, userId);
                if (groupPassageId === false) {
                  // error
                  if (parseInt(status) === 1) {
                    console.log('Add ' + userId + ' to ' + groupId);
                    const result = await this.addGroupPassage(groupId, userId);
                    if (result === true) {
                      vm.successItemSheet2++;
                    } else {
                      console.log(userId, vm.rowinErrDialog);
                      const rowstatus = vm.sheetGroupName + 'シート>>' + rownumber + ' 行目>>' + groupId + '>>' + userId + '>><span>' + result + '</span>';
                      this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                    }
                  } else if (parseInt(status) === 0) {
                    vm.successItemSheet2++;
                  }
                } else {
                  if (parseInt(status) === 0) {
                    console.log('Delelte ' + userId + ' from ' + groupId);
                    const result = await vm.deleteGroupPassage(groupPassageId as string);
                    if (result === true) {
                      vm.successItemSheet2++;
                    } else {
                      const rowstatus = vm.sheetGroupName + 'シート>>' + rownumber + ' 行目>>' + groupId + '>>' + userId + '>><span>' + result + '</span>';
                      this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                    }
                  } else if (parseInt(status) === 1) {
                    vm.successItemSheet2++;
                  }
                }
              } else {
                // status is invalid
                console.log('status is invalid');
                const rowstatus = vm.sheetGroupName + 'シート>>' + rownumber + ' 行目>>' + groupId + '>>' + userId + '>><span>ステータス値が無効です</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
              }
            } else {
              // user not valid
              console.log('user is invalid', userId);
              const rowstatus = vm.sheetGroupName + 'シート>>' + rownumber + ' 行目>>' + userId + '>><span>ユーザーは存在しません。入力内容をご確認ください。</span>';
              this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
            }
          }
          if ( this.groupGateArray.length > 0) {
            this.progressSheet2 += this.percentProcessSheet2;
          }
        }
      }
      this.isFinishImportExcel = true;
    },
    addGroupPassage(groupID: string, userID: string) {
      return new Promise((resolve) => {
        this.$http.post(Config.GROUPS_PASSAGE_API, { groupId: groupID, userId: userID})
          .then((res: any) => {
            resolve(true);
          }, (error) => {
            console.log(error);
            let errorName = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                break;
              case 404:
                errorName = '該当するデーターがありません。';
                break;
              case 409:
                console.log(Config.GROUPS_PASSAGE_API + ':' + groupID + ' ' + userID + ' ' + 'failed');
                errorName = '通行権限が重複しています。';
                break;
              case 420:
                errorName = 'ユーザー登録数が上限に達しています。';
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
            }
            resolve(errorName);
          });
      });
    },
    deleteGroupPassage(groupPassageId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUPS_PASSAGE_API + '/' + encodeURIComponent(groupPassageId))
          .then((res: any) => {
            resolve(true);
          }, (error) => {
            console.log(error);
            let errorName = '';
            switch (error.status) {
            case 403:
              errorName = '通行権限グループ通行権限の削除権限がありません。';
              break;
            case 404:
              errorName = '該当するデーターがありません。';
              break;
            case 500:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            case 503:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            default:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
          }
            resolve(errorName);
          });
      });
    },
    checkUserValid(userId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
          .then((res: any) => {
            resolve(true);
          }, (err) => {
            console.log(err);
            resolve(false);
          });
      });
    },
    getUserInfo(userId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
          .then((res: any) => {
            if (res.data.users !== undefined && res.data.users !== null && res.data.users.length > 0) {
              resolve(res.data.users[0]);
            } else {
              resolve(null);
            }
          }, (err) => {
            console.log(err);
            resolve(null);
          });
      });
    },
    getGroupPassageId(groupId: string, userId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.GROUPS_PASSAGE_API + '?groupId=' + encodeURIComponent(groupId)
          + '&userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            if (response.data.groupPassages.length > 0) {
              resolve(response.data.groupPassages[0].groupPassageId);
            }
            resolve('');
          }, (err) => {
            console.log(err);
            resolve(false);
          });
      });
    },
    userEditExel(user: UserRegister, z: number, derpartmentId: any) {
      const vm = this;
      console.log('useruseruseruser', user);
      return new Promise((resolve) => {
        this.$http.put(Config.USERS_LIST_API + '/' + encodeURIComponent(encodeURIComponent(user.userId)), user)
          .then(async (response: any) => {
            console.log(response);
            await this.updateDepartmentId(derpartmentId, user.userId);
            resolve(1);
          })
          .then( null, (err: any) => {
            // error
            console.log('err: ', err);
            let rowstatus = '';
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              case 403:
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              case 404:
              case 401:
                // const result = await vm.userAddExe(user, z);
                resolve(2);
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              }
            resolve(0);
            });
      });
      // let vm = this;
    },

    userAddExel(user: UserRegister, z: number) {
      const vm = this;
      return new Promise((resolve) => {
        this.$http.post(Config.USERS_LIST_API, user)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              console.log(response);
              resolve(1);
            } else {
              let rowstatus = '';
              let errorName = '';
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
              resolve(0);
            }
          })
          .then( null, (error: any) => {
            console.log('err: ', error);
            let rowstatus = '';
            let errorName = '';
            switch (error.status) {
              case 401:
              if (error.body.message === 'The incoming token has expired') {
                errorName = '';
              } else {
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
              }
              break;
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            case 403:
              errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            case 409:
              errorName = '入力されたユーザーIDは既に使用されています。他のユーザーIDを入力してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            case 500:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            case 503:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
            default:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + user.userId + '>><span>' + errorName + '</span>';
              break;
              }
            this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
            resolve(0);
            }); // catch
      });
    },

    pCodeAddExel(id: any, typePcode: any, pCode: any, z: any) {
      const vm = this;
      return new Promise((resolve) => {
        if (typePcode !== '' && pCode !== '') {
          const Infor = {
            userId: id,
            pCodeId: pCode,
            type: parseInt(typePcode),
            isValid: true,
          };
          this.$http.post(Config.PCODE_LIST_API, Infor)
            .then((response: any) => {
            console.log(response);
            resolve(1);
          })
          .then( null, (error: any) => {
            // catch
            console.log('err: ', error);
            let rowstatus = '';
            let errorName = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              case 403:
                errorName = '個人コードの追加権限がありません。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              case 404:
                errorName = Config.ERROR_404;
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              case 409:
                // duplicate
                resolve(2);
                break;
              case 420:
                this.errorMessageLogs = '個人コード登録数が上限に達しています。';
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>>' + pCode + '>><span>' + errorName + '</span>';
                this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
                break;
              }
            resolve(0);
            });
        } else {
          resolve(1);
        }
      });
    },

    pCodeEditExel(id: any, pCode: any, z: any) {
      const vm = this;
      return new Promise((resolve) => {
        const Infor = {
          userId: id,
        };
        this.$http.put(Config.PCODE_LIST_API + '/' + encodeURIComponent(pCode), Infor)
            .then((response: any) => {
            console.log(response);
            resolve(1);
          }).then( null, (error: any) => {
            // catch
            console.log('err: ', error);
            let rowstatus = '';
            let errorName = '';
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  errorName = '';
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                errorName = '個人コードの編集権限がありません。';
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              case 503:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              default:
                errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
            }
            rowstatus = vm.sheetUserName + 'シート>>' + z + ' 行目>> 個人コード: ' + pCode + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageExcel, vm.rowinErrDialog++, rowstatus);
            resolve(0);
            });
      });
    },

    CancelProcessImportExcel() {
      this.resetAllValue();
    },
    closeModalProcessImportExcel() {
      this.resetAllValue();
    },

    resetAllValue() {
      if (this.successItem > 0 || this.successItemSheet2 > 0) {
        this.isCountExcel = true;
      }
      // value of import excel
      this.isLoading = false;
      this.progress = 0;
      this.successItem = 0;
      this.isFinishImportExcel = false;
      this.totalDataRow = 0;
      this.errorMessageExcel = [];
      this.rowinErrDialog = 0;
      this.showProcessImportExcel = false;
      this.errorMessageLogs = '';
      this.UserSheetImportData = [];
      this.showModalConfirmEmail = false;
      this.userInGroupMx = [];
      this.userIdArray = [];
      this.groupGateArray = [];
      this.successItemSheet2 = 0,
      this.totalDataRowSheet2 = 0;
      this.progressSheet2 = 0;
      countChange = 2;
    },
    showDialogImport(data: any) {
      this.isType = '';
      // showImport(isShowCSV: boolean) {
      this.showModalImport = true;
      this.exportType = data.exportType;
      this.isType = data.type;
    // },
    },
    async closeImportCSV() {
      this.showModalImport = false;
      this.totalDataRowCSV = 0;
      this.successItemCSV = 0;
      this.importDataCSV = [];
      if (this.isCountCSV === true || this.isCountExcel === true) {
        this.getList();
        this.isCountCSV = false;
        this.isCountExcel = false;
      }
    },

    getAllGroups(page: number) {
      this.$http.get(Config.GROUP_LIST_API + '?limit=1000&page=' + page + '&sortKey=asc:name&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groups.forEach((element: any) => {
              this.groupToSearch.push({text: element.name, value: element.groupId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getAllGroups(page + 1);
            }
          } else {
            this.groupToSearch = [];
          }
        })
        .then( null, (err: any) => {
          console.log(err);
        }); // catch
    },
    getDepartment(page: any) {
      this.$http.get(Config.DEPARTMENT_API + '?limit=1000&page=' + page)
      .then((response: any) => {
        response.body.departments.forEach((element: any) => {
          this.departments.push({text: element.name, value: element.departmentId});
          this.departments.sort((a: any, b: any) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
        });
        if (response.body.maxRecords > response.body.endRecord) {
          page++;
          this.getDepartment(page);
        }
      }).
      then( null, (err: any) => {
        //
      });
    },
    searchUserGroup(data: any) {
      this.currPage = 1;
      if (data !== null) {
        this.groupIdToSearch = data.toString();
        this.typeOfSearch = 1;
        this.getUserByGroupId(data);
      } else {
        this.typeOfSearch = 0;
        this.getList();
      }
    },
    searchDeparmentGroup(data: any) {
      this.currPage = 1;
      if (data !== null) {
        this.groupIdToSearch = data.toString();
        this.typeOfSearch = 1;
        this.getUserByDeparmentId(data);
      } else {
        this.typeOfSearch = 0;
        this.getList();
      }
    },

    getUserByGroupId(groupId: string) {
      this.isGetDataList = true;
      this.$http.get(Config.GROUP_PASSAGE + '?groupId=' + encodeURIComponent(groupId) +
                      '&limit=' + localStorage.pageSizeUserList +
                      '&page=' + this.currPage + '&sortKey=desc:userId&embed=user')
        .then((response: any) => {
          this.users = [];
          if (response.body.maxRecords > 0) {
            this.getListUserFull(response);
            console.log(this.users);
            this.maxRecord = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.isGetDataList = false;
            this.maxRecord = 0;
            this.currPage = 1;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.isGetDataList = false;
          this.users = [];
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    async getListUserFull(data: any) {
      console.log('data', data);
      for (const element of data.body.groupPassages) {
        console.log('element', element);
        if (element.user !== undefined && element.user !== null) {
          const user = await this.getUser(element.user);
          this.users.push(user);
        }
      }
      this.isGetDataList = false;
    },
    getUser(data: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(data.userId))
          .then((res: any) => {
            data.groupCount =  res.body.users[0].groupCount;
            data.gateCount =  res.body.users[0].gateCount;
            resolve(data);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(data);
          });
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    closeDialogCheckCardExcel() {
      this.isHasLowerCaseCardNumberExcel = false;
    },
    async getDataForGroup(check: any) {
      const vm = this;
      let page = 1;
      let isContinueGetData: boolean = true;
      while (isContinueGetData) {
        await this.getGroupExport(page, check);
        page++;
        if (page <= this.maxPageGroup) {
          isContinueGetData = true;
        } else {
          isContinueGetData = false;
        }
      }
    },
    getGroupExport(page: any, check: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GROUP_LIST_API + '?limit=1000&page=' + page)
          .then(async (res: any) => {
            for (const item of  res.body.groups) {
              this.exportGroupData.push({
                groupId: item.groupId,
                name: item.name,
              });
            }
            if (check) {
              await Promise.all(res.body.groups.map((item: any) => this.getUserGroup(item.groupId)));
            }
            console.log(this.exportGroupData);
            if (res.body.maxRecords > 0) {
              this.maxPageGroup = Math.ceil((res.body.maxRecords) / 1000);
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    async getDataForUserExcel() {
      const vm = this;
      let page = 1;
      let isContinueGetData: boolean = true;
      while (isContinueGetData) {
        await this.getUserExportExcel(page);
        page++;
        if (page <= this.maxPageUser) {
          isContinueGetData = true;
        } else {
          isContinueGetData = false;
        }
      }
    },
    actionWait(t: any) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, t);
      });
    },
    getUserExportExcel(page: any) {
      const limit = 1000;
      return new Promise((resolve, reject) => {
        this.$http.get(Config.USERS_LIST_API + '?limit=' + limit + '&page=' + page + '&isExceptOptionalUser=true&exclude=thumbnail')
          .then(async (res: any) => {
            for (let i = 0; i < res.body.users.length; i += 20) {
              const chunk = res.body.users.slice(i, i + 20);
              const arrayPromise: any = [];
              for (const [index, item] of  chunk.entries()) {
                this.pcodesTmp = [];
                const itemData = {
                  userId: item.userId,
                  name: item.name,
                  furigana: item.furigana,
                  email: item.email,
                  isMfaSms: this.converToBit(item.isMfaSms),
                  phoneNumber: String.fromCharCode(8203) + item.phoneNumber.replace('+81', '0'),
                  isAdmin: this.converToBit(item.isAdmin),
                  departmentId: '',
                  departmentName: '',
                  isPhoneAdmin: this.converToBit(item.isPhoneAdmin),
                  shareKeyBeValid: this.converToBit(item.shareKeyBeValid),
                  passCode: item.passCode,
                  option1: item.option1,
                  option2: item.option2,
                  isValid: this.converToBit(item.isValid),
                  registered:  this.converToDate(item.registered),
                  updated:  this.converToDate(item.updated),
                  isPasswordLoginValid: this.converToBit(item.isPasswordLoginValid),
                  pCodes: [],
                };
                this.exportUserData.push(itemData);
                await this.actionWait(100);
                const promise3  =  this.getPcodeOneUser(item.userId, limit * (page - 1) + index);
                const promise4 = AlligateUtils.user.getDepartmentUser(this, item.userId);
                const result = Promise.all([promise3, promise4]).then((values) => {
                  const relationsDepartmentUser: any = values[1];
                  const check = this.exportUserData.findIndex((x: any) => x.userId === item.userId);
                  if (check >= 0) {
                    this.exportUserData[check].departmentId = (relationsDepartmentUser.length > 0
                    && relationsDepartmentUser[0].departmentId !== '') ?
                    relationsDepartmentUser[0].departmentId : '';
                    this.exportUserData[check].departmentName = (relationsDepartmentUser.length > 0
                    && relationsDepartmentUser[0].departmentId !== '') ?
                    relationsDepartmentUser[0].department.name : '';
                  }
                });
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            if (res.body.maxRecords > 0) {
              this.maxPageUser = Math.ceil((res.body.maxRecords) / limit);
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          });
      });
    },
    getPcodeOneUser(userId: any, index: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.CARD_LIST_API + '?limit=1000&page=1&sortKey=desc:registered' +
        '&userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            if (response.body.maxRecords > 0 && response.body.pCodes) {
              const i = this.exportUserData.findIndex((x: any) => x.userId === userId);
              if (i >= 0) {
                this.exportUserData[i].pCodes = response.body.pCodes.slice(0, 3);
              }
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            // console.log('err: ', err);
            resolve(false);
            }); // catch
        });
    },
    getUserGroup(groupId: any) {
      return new Promise((resolve, reject) => {
      this.$http.get(Config.GROUPS_PASSAGE_API + '?groupId=' + encodeURIComponent(groupId) + '&limit=1000&page=1')
        .then((response: any) => {
          if (response.body.maxRecords > 0 && response.body.groupPassages) {
            for (const item of response.body.groupPassages) {
              const itemData = {
                groupId: item.groupId,
                userId: item.userId,
              };
              this.exportGroupPassages.push(itemData);
            }
            setTimeout(() => {
              resolve(true);
            }, 300);
          } else {
            setTimeout(() => {
              resolve(false);
            }, 300);
          }
        })
        .then( null, (err: any) => {
          setTimeout(() => {
            resolve(false);
          }, 300);
        }); // catch
      });
    },
    clickCheckBoxExportUserInfoCSV() {
      this.isExportUserInfoCSV = !this.isExportUserInfoCSV;
    },
    acceptDialogCheckCardExcel() {
      if (this.UserSheetImportData.length > 0) {
        this.percentProcess = (100 / (this.totalDataRow));
        // Send data
        this.excuteRegisterUser()
        .then(() => {
        if (this.groupGateArray.length > 1) {
          // Send data
          this.percentProcessSheet2 = (100 / (this.totalDataRowSheet2));
          this.gateGrpImportExe();
        } else {
          this.isFinishImportExcel = true;
        }
        });
      } else {
        if (this.groupGateArray.length > 0) {
          // Send data
          this.gateGrpImportExe();
        } else {
          this.isFinishImportExcel = true;
        }
      }
    },
    // Expand getGatePassage
    getGatePassage(userId: any, pageGate: any) {
      return new Promise((resolve, reject) => {
        this.userId = userId;
        const apiLinkGetGate = Config.GATES_LIST_API +
        '?limit=100&page=' + pageGate +
        '&isExceptOptionalGate=true';
        this.$http.get(apiLinkGetGate)
          .then(async (response: any) => {
            //
            this.ExpandedData[userId].maxRecords = response.body.maxRecords;
            const data = response.body.passages;
            const item: any = [];
            for (let i = 0; i < data.length; i += 10) {
              const chunk = data.slice(i, i + 10);
              const arrayPromise: any = [];
              for (const [index, element] of  chunk.entries()) {
                item.push({
                  gateName: element.gate.name,
                  passageId: element.passageId,
                  groupName: '',
                  groupId: '',
                  passableTimeSetting: {},
                });
                this.ExpandedData[userId].data = item;
                const result: any = this.getGateGroupItem(element.gateId, this.ExpandedData[userId].data[index]);
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            this.ExpandedData[userId].loading = false;
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            this.ExpandedData[userId].loading = false;
            resolve(false);
          }); // catch
        // resolve(true);
      });
    },
    getGateGroupItem(gateId: string, data: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_GATE_GROUP_API + '?gateId=' + encodeURIComponent(gateId) + '&isExceptOptionalGate=true&embed=group')
          .then(async (response: any) => {
            const res =  response.body.relationsGateGroup;
            const result  = await this.getGroupItem(res[0].groupId, data);
            resolve(true);
          })
          .then(null, (error) => {
            console.log(error);
            resolve(false);
          });
      });
    },
    getGroupItem(groupId: any, data: any) {
      return new Promise((resolve, reject) => {
        let item: any = {};
        this.$http.get(Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=1&isExceptOptionalGate=true&embed=passableTimeSetting')
          .then((response: any) => {
            item = response.body.groups[0];
            // item.push(response.body.groups[0]);
            data.groupName = item.name;
            data.groupId = item.groupId;
            data.passableTimeSetting = item.passableTimeSetting;
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    deleteGatePass(passageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GATES_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = passageId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    getDepartmentUser(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_DEPARTMENT_USER_API + `?userId=${encodeURIComponent(userId)}&embed=department`)
          .then((response: any) => {
            resolve(response.body);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    getUserByDeparmentId(ID: any) {
      this.isGetDataList = true;
      this.errorMessageLogs = '';
      this.$http.get(Config.RELATION_DEPARTMENT_USER_API + '?departmentId=' + encodeURIComponent(ID) +
                      '&limit=' + localStorage.pageSizeUserList +
                      '&page=' + this.currPage + '&sortKey=desc:userId&embed=user,department')
        .then(async (response: any) => {
          this.users = [];
          if (response.body.maxRecords > 0) {
            response.body.relationsDepartmentUser.forEach((element: any) => {
              const item: any = element.user;
              item.company = element.department.name;
              item.departmentId = element.department.departmentId;
              this.users.push(item);
            });
            const userPromises = response.body.relationsDepartmentUser.map(async (element: any) => {
                const item: any = element.user;
                item.company = element.department.name;
                item.departmentId = element.department.departmentId;
                const infor: any = await this.getInFoUser(element.userId);
                if (infor) {
                  item.gateCount = infor.users[0].gateCount;
                }
                return item;
            });
            this.users = await Promise.all(userPromises);
            this.isGetDataList = false;
            this.maxRecord = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.users = [];
            this.maxRecord = 0;
            this.currPage = 1;
            this.isGetDataList = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.isGetDataList = false;
          this.users = [];
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    getInFoUser(userId: any) {
      return new Promise((resolve, reject) => {
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          resolve(response.body);
        })
        .then(null, (error: any) => {
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
          resolve(false);
        });
      });
    },
    getDepartmentAuthAll(userId: any, page = 1) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?userId=' + (userId) + '&limit=1000&page=' + page)
        .then(async (response: any) => {
          response.body.departmentAdministrators.forEach((element: any) => {
            if (element.isAdminToEditUser) {
              this.dataAllow.push(element.departmentId);
            }
          });
          if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getDepartmentAuthAll(userId, page);
            } else {
              resolve(true);
            }
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
    },
    // 所属選択
    async updateDepartmentId(ID: any, userID: any) {
      if (countChange > 0) {
        const department: any = await this.getDepartmentUser(userID);
        let departmentId = '';
        if (department) {
          const relationsDepartmentUser = department.relationsDepartmentUser[0];
          departmentId = relationsDepartmentUser.department.departmentId;
          const relationId = relationsDepartmentUser.relationId;
          if (ID !== departmentId) {
            const result = await this.deleteDepartmentId(relationId);
            if (result) {
              await this.addDepartmentId({
                departmentId: ID,
                userId: userID,
              });
            }
          }
        } else {
          await this.addDepartmentId({
            departmentId: ID,
            userId: userID,
          });
        }
      }
    },
    deleteDepartmentId(ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_DEPARTMENT_USER_API + '/' + ID)
        .then((response: any) => {
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
    addDepartmentId(data: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.RELATION_DEPARTMENT_USER_API, data)
        .then((response: any) => {
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
    loadTotal(data: any) {
      const index = this.users.findIndex((x: any) =>
      x.userId === data.ID);
      if (index >= 0) {
        this.users[index].gateCount = data.total;
      }
    },
    getListDepartment(data: any) {
      this.departments = [];
      this.getDepartment(1);
    },
  },
});
